import styles from "./FilterDate.module.css";
import { useQuery } from "hooks";
import { FilterDateType } from "../../types";
import { DatePicker } from "components/utils/datePicker";
import { dateFns } from "utilities";

export const FilterDate = <T extends string>({ name }: FilterDateType<T>) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const value = query[name];

  const handleChange = (date: Date | null) => {
    updateQuery({ [name]: formatDate(date) });
  };

  return (
    <div className="d-flex">
      <DatePicker
        overwrites={{ container: { className: styles.container } }}
        placeholder="Wybierz"
        className={styles.date}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

function formatDate(date?: string | Date | null, displayFormat?: string) {
  const dateFormat = displayFormat ?? "yyyy-MM-dd";
  if (!date) return "";
  if (date instanceof Date) {
    return dateFns.format(date, dateFormat);
  } else {
    return dateFns.format(new Date(date), dateFormat);
  }
}
