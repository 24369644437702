import { confirmOrderCollectionDateByMiloUser } from "api/orders/calls";
import { Delivery } from "api/orders/models";
import { postSingleRouteEmail } from "api/routes/calls";
import { RouteEmails } from "api/routes/models";
import magnifierIcon from "assets/images/g18603.svg";
import eventAvailableIcon from "assets/images/eventAvailable.svg";
import eventBusyIcon from "assets/images/eventBusy.svg";
import cx from "classnames";
import { FilterDropdownManual } from "components/common/toolbar/filterDropdown";
import { CommonError, Spinner } from "components/utils";
import cuid from "cuid";
import { useQuery, useSelector, useSettings, useToggle } from "hooks";
import { useRouteEmailMessages } from "hooks/apiPrimitives";
import { useFilters } from "hooks/useFilters";
import { useMutation } from "hooks/useMutation";
import { useState } from "react";
import { useHistory } from "react-router";
import ReactTooltip from "react-tooltip";
import { dateFns } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import styles from "../PointsSection.module.css";
import { ConfirmedBy } from "./ConfirmedBy";
import { OrderEmailConfirmation, Status } from "./OrderEmailConfirmation";
import { OrderEmailConversation } from "./OrderEmailConversation";
import { RightPanel } from "./RightPanel";
import { EmailConfirmationDateInNotificationsModal } from "../confirmationDateModal/EmailConfirmationDateInNotificationsModal";

interface Filters {
  confirmedBy: string;
  status: string;
}

export interface OrderDetails {
  client: Pick<Delivery, "firstName" | "lastName" | "email">;
  orderId: string;
  signature: string;
}

const initialFilters: Filters = {
  confirmedBy: "",
  status: "",
};

export const ModalContent = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data, error, isLoading, handleMutate } = useRouteEmailMessages(panelId);
  const { filters, setFilter } = useFilters<Filters>(initialFilters);
  const [order, setOrder] = useState<OrderDetails | null>(null);
  const history = useHistory();
  const me = useSelector(state => state.auth.user!);
  const { transport } = useSettings();
  const hasDeadlineConfirmationDate = transport.hasDeadlineConfirmationDate;
  const options = transport.options;
  const sendEmailConfirmationDateModal = useToggle();
  const [orderToNotify, setOrderToNotify] = useState<RouteEmails["orders"][number]>();

  const confirmMutation = useMutation(confirmOrderCollectionDateByMiloUser, {
    onMutate: orderId => {
      return handleMutate(draft => {
        const orderToUpdate = draft.orders.find(draftOrder => draftOrder.id === orderId);
        assertIsDefined(orderToUpdate);
        orderToUpdate.deliveryDateConfirmedBy = me;
        orderToUpdate.isDeliveryDateConfirmed = true;
        orderToUpdate.deliveryDateConfirmTimestamp = String(new Date());
      });
    },
    onError: (error, _, rollback) => {
      // @ts-ignore
      rollback(error);
    },
  });

  const sendEmailMutation = useMutation(postSingleRouteEmail, {
    onMutate: data => {
      const rollback = handleMutate(draft => {
        const orderToUpdate = draft.orders.find(draftOrder => draftOrder.id === data.orderId);
        assertIsDefined(orderToUpdate);

        orderToUpdate.isDeliveryDateConfirmed = false;
        orderToUpdate.email = {
          status: "SENT",
          body: "",
          subject: "",
          failureReason: "",
          id: cuid(),
        };
      });
      return rollback;
    },
    onSuccess: data => {
      handleMutate(draft => {
        const orderToUpdate = draft.orders.find(draftOrder => draftOrder.id === data.content.order);
        assertIsDefined(orderToUpdate);

        orderToUpdate.email = {
          status: data.content.status,
          body: data.content.body,
          subject: data.content.subject,
          failureReason: data.content.failureReason,
          id: data.content.id,
        };

        sendEmailConfirmationDateModal.close();
      });
    },
    onError: (error, _, rollback) => {
      // @ts-ignore
      rollback(error);
    },
  });

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (!data || isLoading) {
    return (
      <div className="h-100 d-flex flex-column">
        <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
      </div>
    );
  }

  const orders = getOrdersBasedOnFilters(data.orders, filters);

  return (
    <div className="h-100 d-flex flex-column">
      <div className={styles.title}>Powiadomienia na trasie</div>
      <div className={styles.emailFiltersContainer}>
        <div className="d-flex">
          <FilterDropdownManual
            value={filters.status}
            label="status"
            onClick={value => {
              setFilter("status", value);
            }}
            options={[
              { label: "Wszystkie", value: "" },
              { label: "Oczekujące", value: "false" },
              { label: "Potwierdzone", value: "true" },
            ]}
          />
          <FilterDropdownManual
            value={filters.confirmedBy}
            label="potwierdzono przez"
            onClick={value => {
              setFilter("confirmedBy", value);
            }}
            options={[
              { label: "Wszystkie", value: "" },
              { label: "Klient", value: "client" },
              { label: "Użytkownik Milo", value: "milo" },
            ]}
          />
        </div>
      </div>
      <div className="position-relative flex-1">
        <div className={cx(styles.row, styles.header, "flex-grow-1")}>
          <div>zamówienie</div>
          <div>ostatnia wiadomość do klienta</div>
          <div>potwierdzenie</div>
          <div>kto i kiedy potwierdził</div>
        </div>

        <div className={styles.ordersContainer}>
          {orders.length ? (
            orders.map(order => {
              return (
                <div className={styles.row} key={order.id}>
                  <div className="d-flex flex-column">
                    <div
                      className={cx(styles.signature, "orderRedirectSignature")}
                      onClick={() => history.push(getOrderRedirectUrl(order))}
                    >
                      {order.signature}
                    </div>
                    <div className={styles.greyText}>
                      <span>
                        {order.client.firstName} {order.client.lastName}
                      </span>
                    </div>
                    <div
                      className={styles.contact}
                      data-for={String(order.id)}
                      data-tip={order.client.email}
                    >
                      <span className={styles.greyText}>email </span> {order.client.email}
                    </div>
                    <ReactTooltip
                      className={styles.tooltip}
                      id={String(order.id)}
                      type="dark"
                      arrowColor="#808080"
                      delayShow={1000}
                      effect="solid"
                      place="bottom"
                    />
                  </div>
                  <div className="d-flex align-items-center mr-3">
                    <OrderEmailConversation
                      openRightPanel={orderDetails => {
                        setOrder(orderDetails);
                      }}
                      order={order}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <OrderEmailConfirmation
                      order={order}
                      status={getEmailStatus(order)}
                      handleConfirm={() => confirmMutation.mutate(order.id)}
                      handleSingleEmailSend={() => {
                        if (!hasDeadlineConfirmationDate) {
                          sendEmailMutation.mutate({
                            orderId: order.id,
                            resend: false,
                            deadline: null,
                          });
                        } else {
                          setOrderToNotify(order);
                          sendEmailConfirmationDateModal.open();
                        }
                      }}
                    />
                  </div>

                  {sendEmailConfirmationDateModal.isOpen && (
                    <EmailConfirmationDateInNotificationsModal
                      close={sendEmailConfirmationDateModal.close}
                      options={options}
                      order={orderToNotify ? orderToNotify : order}
                      sendEmailMutation={sendEmailMutation}
                    />
                  )}

                  <div>
                    {hasDeadlineConfirmationDate && order.deliveryConfirmationDeadline && (
                      <div className="d-flex align-items-center fs-10 text-color-grey mb-1">
                        <span>potwierdzić do: &nbsp;</span>
                        <span className="text-color-black">
                          {dateFns.format(
                            new Date(order.deliveryConfirmationDeadline),
                            "dd.MM.yyyy HH:mm",
                          )}
                        </span>
                      </div>
                    )}
                    {order.isDeliveryDateConfirmed && <ConfirmedBy order={order} />}
                    {hasDeadlineConfirmationDate &&
                      order.deliveryConfirmationDeadline &&
                      order.deliveryDateConfirmTimestamp &&
                      new Date(String(order.deliveryConfirmationDeadline)) <
                        new Date(String(order.deliveryDateConfirmTimestamp)) && (
                        <div className="d-flex align-items-center mt-1">
                          <img alt="Termin przekroczony" className="mr-1" src={eventBusyIcon} />
                          <span className={styles.deadlineLabel}>po terminie</span>
                        </div>
                      )}
                    {hasDeadlineConfirmationDate &&
                      order.deliveryConfirmationDeadline &&
                      order.deliveryDateConfirmTimestamp &&
                      new Date(String(order.deliveryConfirmationDeadline)) >
                        new Date(String(order.deliveryDateConfirmTimestamp)) && (
                        <div className="d-flex align-items-center mt-1">
                          <img
                            alt="Termin przekroczony"
                            className="mr-1"
                            src={eventAvailableIcon}
                          />
                          <span className={styles.inTimeLabel}>w terminie</span>
                        </div>
                      )}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className={cx(
                styles.noContent,
                "d-flex align-items-center h-100 flex-column justify-content-center w-100",
              )}
            >
              <img alt="" src={magnifierIcon} />
              <div>Niczego nie znaleziono</div>
              <span>Zmień kryteria i spróbuj wyszukać ponownie</span>
            </div>
          )}
        </div>

        {order && (
          <RightPanel
            client={order.client}
            close={() => setOrder(null)}
            orderId={order.orderId}
            signature={order.signature}
          />
        )}
      </div>
    </div>
  );
};

const getOrdersBasedOnFilters = (orders: RouteEmails["orders"], filters: Filters) => {
  if (Object.values(filters).every(el => !el)) return orders;

  return orders
    .filter(order => {
      if (!filters.status) return true;

      if (filters.status === String(order.isDeliveryDateConfirmed)) {
        return true;
      }
      return false;
    })
    .filter(order => {
      if (!filters.confirmedBy) return true;
      if (
        filters.confirmedBy === "milo" &&
        order.isDeliveryDateConfirmed &&
        order.deliveryDateConfirmedBy
      ) {
        return true;
      }
      if (
        filters.confirmedBy === "client" &&
        order.isDeliveryDateConfirmed &&
        !order.deliveryDateConfirmedBy
      ) {
        return true;
      }
      return false;
    });
};

const getEmailStatus = (order: RouteEmails["orders"][number]): Status => {
  if (order.email?.failureReason && order.email.status === "DECLINED") {
    return Status.FAILURE;
  }

  if (!order.isDeliveryDateConfirmed && order.email) {
    return Status.AWAITING;
  }

  if (order.isDeliveryDateConfirmed) {
    return Status.CONFIRMED;
  }

  return Status.NOT_SENT;
};
