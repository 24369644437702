import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import processing from "assets/images/moduleNavigationIcons/conveyor_belt.svg";
import { Dashboard } from "routes/reports/Dashboard";

export const manufacturing: ModuleLink = {
  url: "manufacturing",
  label: "Produkcja",
  icon: processing,
  subSection: <></>,
  routing: Dashboard,
};
