import styles from "./FilterDateRange.module.css";
import { useQuery } from "hooks";
import { FilterDateRangeType } from "../../types";
import { DatePicker } from "components/utils/datePicker";
import { dateFns } from "utilities";
import { MonthPicker } from "./MonthPicker";

export const FilterDateRange = ({ additionalKeys, name, showMonths }: FilterDateRangeType) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const [startDateName, endDateName] = name;
  const [additionalStartDate, additionalEndDate] = additionalKeys ? additionalKeys : ["", ""];
  const startDate = query[startDateName];
  const endDate = query[endDateName];

  const handleChangeStartDate = (date: Date | null) => {
    if (additionalKeys) {
      updateQuery({
        [startDateName]: date ? formatDate(date) : "",
        [additionalStartDate]: date ? formatDate(date) : "",
        page: 1,
      });
    } else {
      updateQuery({
        [startDateName]: date ? formatDate(date) : "",
        page: 1,
      });
    }
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (additionalKeys) {
      updateQuery({
        [endDateName]: date ? formatDate(date) : "",
        [additionalEndDate]: date ? formatDate(date) : "",
        page: 1,
      });
    } else {
      updateQuery({ [endDateName]: date ? formatDate(date) : "", page: 1 });
    }
  };

  return (
    <div>
      <div className="d-flex">
        <div className="position-relative mr-1">
          <span className={styles.label}>Od:</span>
          <DatePicker
            overwrites={{ container: { className: styles.container } }}
            placeholder="Wybierz"
            className={styles.date}
            value={startDate}
            selectsStart
            startDate={startDate ? new Date(startDate) : null}
            endDate={startDate ? new Date(startDate) : null}
            maxDate={endDate ? new Date(endDate) : null}
            onChange={handleChangeStartDate}
          />
        </div>
        <div className="position-relative ">
          <span className={styles.label}>Do:</span>
          <DatePicker
            overwrites={{ container: { className: styles.container } }}
            placeholder="Wybierz"
            className={styles.date}
            value={endDate}
            selectsEnd
            startDate={startDate ? new Date(startDate) : null}
            endDate={endDate ? new Date(endDate) : null}
            minDate={startDate ? new Date(startDate) : null}
            onChange={handleChangeEndDate}
          />
        </div>
      </div>
      {showMonths && (
        <MonthPicker
          additionalKeys={additionalKeys}
          months={[new Date(), dateFns.subMonths(new Date(), 1), dateFns.subMonths(new Date(), 2)]}
          name={name}
        />
      )}
    </div>
  );
};

function formatDate(date?: string | Date | null, displayFormat?: string) {
  const dateFormat = displayFormat ?? "yyyy-MM-dd";
  if (!date) return "";
  if (date instanceof Date) {
    return dateFns.format(date, dateFormat);
  } else {
    return dateFns.format(new Date(date), dateFormat);
  }
}
