import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import calendarViewMonth from "assets/images/moduleNavigationIcons/calendar_view_month.svg";
import { ProductionPlans } from "routes/manufacturingNew/ProductionPlans";

export const manufacturingPlans: ModuleLink = {
  url: "manufacturing-plans",
  label: "Plany produkcji",
  icon: calendarViewMonth,
  subSection: <></>,
  routing: ProductionPlans,
};
