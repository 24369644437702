import { Button, FilterType, ViewLabel } from "components/common";
import { StateProxy } from "../StateProxy";
import styles from "./SearchWithHistory.module.css";
import closeIcon from "assets/images/11.svg";
import magnifierIcon from "assets/images/g18603.svg";
import checkMarkSmallIcon from "assets/images/checkSmall.svg";
import { useEffect, useState } from "react";
import { getSearchHistory } from "api/other/calls";
import { useQuery } from "hooks";
import { useParams } from "react-router-dom";
import { queryString } from "utilities";
import { Spinner } from "../spinner";
import cx from "classnames";
import { UseMutationResult } from "react-query";
import { QueryFetchError } from "api/types";
import { useRef } from "react";
import { SearchHistory } from "api/other/models";
import { SearchResultLog, setQueryUsingLog } from "./components/SearchResultLog";
import { useKeyPress } from "./utils/useKeyPress";

type Params = { tab: string; navbarTab: string };

interface Props {
  additionalListParams?: {
    [x: string]: string;
  };
  debounce?: number;
  filters?: [];
  onUpdate: (value: string) => void;
  saveSearchHistoryMutation: UseMutationResult<any, QueryFetchError, unknown, unknown>;
  searchInput?: {
    label?: string;
    variants?: FilterType[];
    tags?: {
      name: string;
      label: string;
      value: string | number | boolean | null;
      valueLabel?: string;
    }[];
  };
  value: string;
  viewLabel: ViewLabel;
}

export const SearchWithHistory = ({
  additionalListParams,
  debounce = 200,
  filters,
  onUpdate,
  saveSearchHistoryMutation,
  searchInput,
  value,
  viewLabel,
}: Props) => {
  const { getParam, query, setQuery, updateQuery } = useQuery();
  const params = useParams<{ tab: string; navbarTab: string }>();
  const [previousSearch, setPreviousSearch] = useState(getParam("search"));
  const [reloadHistory, setReloadHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [history, setHistory] = useState<SearchHistory[]>([]);
  const [hasClickedOutsideOnce, setHasClickedOutsideOnce] = useState(false);
  const [hasClickedInsideOnce, setHasClickedInsideOnce] = useState(false);
  const searchFieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const downPress = useKeyPress("ArrowDown", inputRef);
  const upPress = useKeyPress("ArrowUp", inputRef);
  const enterPress = useKeyPress("Enter", inputRef);

  const [cursor, setCursor] = useState<number>(0);
  const [hovered, setHovered] = useState<SearchHistory | undefined>(undefined);

  const fetchSearchHistory = async () => {
    const searchParam = getSearchParam(params, query, viewLabel, additionalListParams);
    setIsLoading(true);
    const [payload, error] = await getSearchHistory(searchParam);
    if (payload) {
      setHistory(payload.results);
      setIsLoading(false);
    } else if (error) {
      setIsLoading(false);
    }
  };

  const handleActiveSearchField = () => {
    setShowHistory(!showHistory);
    setHasClickedOutsideOnce(false);
    setHasClickedInsideOnce(true);

    if (!showHistory) {
      fetchSearchHistory();
    }
  };

  const isTagInQuery = (name: string): boolean => {
    const param = getParam(name);
    if (param === null || (typeof param === "string" && param.length === 0)) return false;
    return true;
  };

  const updateQueryWithTag = (name: string, value: string | number | boolean): void => {
    if (isTagInQuery(name)) {
      const updatedQuery = { ...query };
      delete updatedQuery[name];
      setQuery({ ...updatedQuery });
      setReloadHistory(prev => !prev);
    } else {
      updateQuery({ [name]: value });
      setReloadHistory(prev => !prev);
    }
  };

  useEffect(() => {
    if (history.length && downPress) {
      setCursor(prevState => (prevState < history.length - 1 ? prevState + 1 : prevState));
    }
  }, [downPress, history.length]);
  useEffect(() => {
    if (history.length && upPress) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress, history.length]);
  useEffect(() => {
    if (history.length && enterPress) {
      setQueryUsingLog(history[cursor], setQuery);
      inputRef.current!.value = history[cursor].phrase;
      setShowHistory(false);
    }
  }, [cursor, enterPress, history, hovered, setQuery]);
  useEffect(() => {
    if (history.length && hovered) {
      setCursor(history.indexOf(hovered));
    }
  }, [hovered, history]);

  useEffect(() => {
    fetchSearchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadHistory]);

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (searchFieldRef.current && !searchFieldRef.current.contains(event.target as Node)) {
        if (!hasClickedOutsideOnce && hasClickedInsideOnce) {
          setShowHistory(false);
          if (
            inputRef.current?.value &&
            inputRef.current?.value.length > 1 &&
            inputRef.current?.value !== previousSearch
          ) {
            saveSearchHistoryMutation.mutate({});
            setPreviousSearch(inputRef.current?.value);
          }
        }
        setHasClickedOutsideOnce(true);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [hasClickedOutsideOnce, saveSearchHistoryMutation, hasClickedInsideOnce, previousSearch]);

  return (
    <div className={styles.search} ref={searchFieldRef}>
      <StateProxy state={value} onChange={onUpdate} debounce={debounce}>
        {({ state, setState, setBlur, setFocus }) => (
          <>
            <>
              <input
                type="search"
                onClick={handleActiveSearchField}
                className="search-field"
                placeholder={searchInput?.label ? searchInput.label : "Szukaj"}
                value={state}
                onFocus={setFocus}
                onBlur={setBlur}
                onChange={e => setState(e.target.value)}
                ref={inputRef}
              />
              {state && (
                <Button
                  className={styles.resetBtn}
                  kind="transparent-black"
                  onClick={() => setState("")}
                  size="square-s"
                >
                  <img src={closeIcon} alt="reset" />
                </Button>
              )}
            </>
            {showHistory && (
              <div
                className={cx(styles.searchList, {
                  [styles.searchListLoaderWithoutFilters]: isLoading,
                  [styles.searchListLoaderWithFilters]: isLoading && filters && filters.length > 0,
                })}
              >
                {searchInput && searchInput.tags && searchInput.tags.length > 0 && (
                  <div className="d-flex align-items-center gap-1 px-3 py-2 flex-wrap">
                    {searchInput.tags.map(tag => (
                      <label
                        className={cx(styles.tag, styles.tagFill, "d-inline-flex", {
                          [styles.tagFillActive]: isTagInQuery(tag.name),
                        })}
                        key={tag.name}
                        onClick={e => {
                          e.stopPropagation();
                          updateQueryWithTag(tag.name, tag.value!);
                        }}
                      >
                        <img alt="Dodano" className={styles.checkMark} src={checkMarkSmallIcon} />
                        <div
                          className={cx(styles.tagSelected, {
                            [styles.tagChecked]: isTagInQuery(tag.name),
                          })}
                        >
                          <img
                            alt="Dodano"
                            className={cx(styles.checkMark, {
                              [styles.checkMarkActive]: isTagInQuery(tag.name),
                            })}
                            src={checkMarkSmallIcon}
                          />
                          <div className="d-flex align-items-center">
                            {tag.label}: &nbsp;
                            <div>{tag.valueLabel ? tag.valueLabel : tag.value}</div>
                          </div>
                        </div>
                      </label>
                    ))}
                  </div>
                )}
                {isLoading ? (
                  <div
                    className={cx(
                      styles.heightWithoutData,
                      "d-flex align-items-center justify-content-center flex-column w-100 p-4",
                    )}
                  >
                    <Spinner
                      className="mb-2"
                      color="blue"
                      size="small"
                      text="wczytywanie historii wyszukań"
                    />
                  </div>
                ) : (
                  <div className={styles.searchResultList}>
                    {history.length > 0 &&
                      history.map((log, index) => (
                        <SearchResultLog
                          active={index === cursor}
                          additionalListParams={additionalListParams}
                          key={log.id}
                          log={log}
                          setShowHistory={setShowHistory}
                          setHovered={setHovered}
                        />
                      ))}
                  </div>
                )}
                {history.length === 0 && !isLoading && (
                  <div
                    className={cx(
                      styles.heightWithoutData,
                      "d-flex align-items-center flex-column justify-content-center w-100 p-4",
                    )}
                  >
                    <img src={magnifierIcon} alt="Historia" />
                    <div className="fs-14 text-color-grey">Historia wyszukań jest pusta</div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </StateProxy>
    </div>
  );
};

const getSearchParam = (
  params: Params,
  query: {
    [x: string]: string;
  },
  viewLabel: ViewLabel,
  additionalListParams?: {
    [x: string]: string;
  },
): string => {
  const queryToSend = { ...query };
  delete queryToSend["page"];
  delete queryToSend["search"];
  return queryString.stringify({
    ...queryToSend,
    tab: params.tab ? params.tab.toUpperCase() : "",
    kind: viewLabel,
    ...additionalListParams,
  });
};
