import { createAction } from "@reduxjs/toolkit";
import { createReduxReducer } from "utilities";
import { resetStore } from "./auth";

// TYPES //

export type AvailableViewKeys =
  | "callCenterRoutes"
  | "callCenterRouteOrders"
  | "callCenterOrders"
  | "warehousePackagesInSummary"
  | "warehousePackagesOutSummary"
  | "logisticsRouteTradingDocuments"
  | "logisticsRoutes"
  | "orders"
  | "products"
  | "routes"
  | "upholsteryOrders"
  | "offers"
  | "complaints"
  | "productSets"
  | "customers"
  | "attributes"
  | "orderGroups"
  | "permissionGroups"
  | "manufacturerOrders"
  | "priceLists"
  | "productionOrders"
  | "productionPlans"
  | "productionOrdersWarehouseList"
  | "upholsteriesEmployees"
  | "drivers"
  | "driverRoutes"
  | "emailTemplates"
  | "statuses"
  | "labels"
  | "fiscalPrinters"
  | "tradingDocuments"
  | "businessEntities"
  | "vatRates"
  | "exchangeRates"
  | "signatureSchemas"
  | "manufacturingRoutes"
  | "manufacturingOrderGroups"
  | "salesInvoices"
  | "correctionInvoices"
  | "receipts"
  | "purchaseInvoices"
  | "myInvoices"
  | "orderGroupOrders"
  | "accountancyAccountsList"
  | "productionPlansList";

interface UiState {
  highlights: { [key in AvailableViewKeys]: number | null };
  viewTypes: { [key in AvailableViewKeys]: "list" | "board" };
  isNavbarOpened: boolean;
  isAppLoading: boolean;
  areCategoriesOnRouteOpen: boolean;
  isRouteListOnCreatorOpen: boolean;
  communicationStatus: "busy" | "idle";
  versionLock: boolean;
  lastAssignedUsers: number[];
  shelfHeight: number;
}

// CONSTANTS & UTILS //

export const initialState: UiState = {
  communicationStatus: "busy",
  versionLock: false,
  isAppLoading: true,
  highlights: {
    orders: null,
    products: null,
    routes: null,
    upholsteryOrders: null,
    offers: null,
    complaints: null,
    customers: null,
    attributes: null,
    orderGroups: null,
    orderGroupOrders: null,
    permissionGroups: null,
    manufacturerOrders: null,
    driverRoutes: null,
    upholsteriesEmployees: null,
    productionOrders: null,
    productionPlans: null,
    productionOrdersWarehouseList: null,
    callCenterRoutes: null,
    callCenterRouteOrders: null,
    callCenterOrders: null,
    logisticsRouteTradingDocuments: null,
    logisticsRoutes: null,
    warehousePackagesInSummary: null,
    warehousePackagesOutSummary: null,
    drivers: null,
    emailTemplates: null,
    statuses: null,
    labels: null,
    fiscalPrinters: null,
    tradingDocuments: null,
    salesInvoices: null,
    purchaseInvoices: null,
    correctionInvoices: null,
    receipts: null,
    businessEntities: null,
    vatRates: null,
    exchangeRates: null,
    signatureSchemas: null,
    manufacturingRoutes: null,
    myInvoices: null,
    manufacturingOrderGroups: null,
    productSets: null,
    priceLists: null,
    accountancyAccountsList: null,
    productionPlansList: null,
  },
  isNavbarOpened: true,
  areCategoriesOnRouteOpen: false,
  isRouteListOnCreatorOpen: false,
  shelfHeight: 400,
  viewTypes: {
    orders: "list",
    products: "list",
    routes: "list",
    driverRoutes: "list",
    upholsteryOrders: "list",
    offers: "list",
    complaints: "list",
    customers: "list",
    callCenterRoutes: "list",
    callCenterRouteOrders: "list",
    callCenterOrders: "list",
    logisticsRouteTradingDocuments: "list",
    logisticsRoutes: "list",
    warehousePackagesInSummary: "list",
    warehousePackagesOutSummary: "list",
    attributes: "list",
    orderGroups: "list",
    permissionGroups: "list",
    orderGroupOrders: "list",
    manufacturerOrders: "list",
    upholsteriesEmployees: "list",
    productionOrders: "list",
    productionPlans: "list",
    productionOrdersWarehouseList: "list",
    drivers: "list",
    emailTemplates: "list",
    statuses: "list",
    labels: "list",
    fiscalPrinters: "list",
    tradingDocuments: "list",
    salesInvoices: "list",
    purchaseInvoices: "list",
    correctionInvoices: "list",
    receipts: "list",
    businessEntities: "list",
    vatRates: "list",
    exchangeRates: "list",
    signatureSchemas: "list",
    manufacturingRoutes: "list",
    manufacturingOrderGroups: "list",
    productSets: "list",
    myInvoices: "list",
    priceLists: "list",
    accountancyAccountsList: "list",
    productionPlansList: "list",
  },
  lastAssignedUsers: [],
};

// ACTIONS //

const highlight = createAction<{ name: AvailableViewKeys; id: number }>("ui/highlight");
const toggleNavbar = createAction("ui/toggleNavbar");
const toggleCategoriesOnRoutes = createAction("ui/toggleCategoriesOnRoutes");
const toggleRouteListOnCreator = createAction("ui/toggleRouteListOnCreator");
const toggleViewType = createAction<{ type: AvailableViewKeys; value: "list" | "board" }>(
  "ui/toggleViewType",
);
const setCommunicationStatus = createAction<"busy" | "idle">("ui/setCommunicationStatus");
const enableVersionLock = createAction("ui/enableVersionLock");
const disableVersionLock = createAction("ui/disableVersionLock");
const rememberAssignedUser = createAction<number>("ui/rememberAssignedUser");
const setAppLoading = createAction<boolean>("ui/appLoading");
const setShelfHeight = createAction<number>("ui/shelfHeight");

export const actions = {
  highlight,
  toggleNavbar,
  toggleRouteListOnCreator,
  toggleCategoriesOnRoutes,
  toggleViewType,
  enableVersionLock,
  disableVersionLock,
  rememberAssignedUser,
  setAppLoading,
  setShelfHeight,
};

// REDUCER //

export const reducer = createReduxReducer(initialState, {
  [highlight.type]: (state, action: ReturnType<typeof highlight>) => {
    state.highlights[action.payload.name] = action.payload.id;
  },
  [toggleNavbar.type]: (state, _action: ReturnType<typeof toggleNavbar>) => {
    state.isNavbarOpened = !state.isNavbarOpened;
  },
  [setAppLoading.type]: (state, action: ReturnType<typeof setAppLoading>) => {
    state.isAppLoading = action.payload;
  },
  [setShelfHeight.type]: (state, action: ReturnType<typeof setShelfHeight>) => {
    state.shelfHeight = action.payload;
  },
  [toggleCategoriesOnRoutes.type]: (
    state,
    _action: ReturnType<typeof toggleCategoriesOnRoutes>,
  ) => {
    state.areCategoriesOnRouteOpen = !state.areCategoriesOnRouteOpen;
  },
  [toggleRouteListOnCreator.type]: (
    state,
    _action: ReturnType<typeof toggleRouteListOnCreator>,
  ) => {
    state.isRouteListOnCreatorOpen = !state.isRouteListOnCreatorOpen;
  },

  [toggleViewType.type]: (state, action: ReturnType<typeof toggleViewType>) => {
    state.viewTypes[action.payload.type] = action.payload.value;
  },
  [setCommunicationStatus.type]: (state, action: ReturnType<typeof setCommunicationStatus>) => {
    state.communicationStatus = action.payload;
  },
  [enableVersionLock.type]: (state, action: ReturnType<typeof enableVersionLock>) => {
    state.versionLock = true;
  },
  [disableVersionLock.type]: (state, action: ReturnType<typeof disableVersionLock>) => {
    state.versionLock = false;
  },
  [rememberAssignedUser.type]: (state, action: ReturnType<typeof rememberAssignedUser>) => {
    if (state.lastAssignedUsers.includes(action.payload) === false) {
      state.lastAssignedUsers.unshift(action.payload);
      state.lastAssignedUsers.splice(3, state.lastAssignedUsers.length - 3);
    }
  },
  [resetStore.type]: () => initialState,
});
