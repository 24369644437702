import calculateIcon from "assets/images/calculatePink.svg";
import accountancyIcon from "assets/images/support.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";

export const support: ModuleNavigation = {
  labelBackground: "#b65eff",
  label: "Wsparcie",
  iconBackground: "#431d87",
  icon: calculateIcon,
  menuIcon: accountancyIcon,
  name: "SUPPORT",
  url: "/support",
  navigationSections: [{ navigationList: [] }],
};
