import cx from "classnames";
import magnifierIcon from "assets/images/search.svg";
import closeIcon from "assets/images/closeLightGrey.svg";
import { useQuery, useSelector } from "hooks";
import styles from "./FilterSearch.module.css";
import { FilterSearchType } from "../../types";
import { useCombobox, useMultipleSelection } from "downshift";
import { Partials } from "api/other/models";
import { useState } from "react";
import { highlightTextFragment } from "utilities/highlightTextFragment";
import { Seller } from "api/sellers/models";

export const FilterSearch = <T extends string>({
  name,
  searchBy,
  multiple,
}: FilterSearchType<T>) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const options = useSelector(state => state.partials[searchBy]);
  const filteredOptions =
    searchBy === "businessEntities"
      ? ((options as unknown) as Seller[]).filter(option => option.kind === "EXTERNAL")
      : options;

  const [inputValue, setInputValue] = useState("");

  const selectedItems = (() => {
    const selectedIds = query[name]?.split(",");
    if (!selectedIds?.length) return [];
    return normalizeData(filteredOptions).reduce<NormalizedData[]>((acc, el) => {
      if (selectedIds?.includes(String(el.id))) {
        acc.push(el);
      }
      return acc;
    }, []);
  })();

  const items = normalizeData(filteredOptions).filter(
    option => !inputValue || option.name.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection({
    selectedItems,
  });

  const removeSelection = (id: string) => {
    const newFilters = query[name]
      ?.split(",")
      ?.filter(appliedValue => {
        return appliedValue !== id;
      })
      .join(",");

    updateQuery({ [name]: newFilters, page: 1 });
  };

  const select = (id: string) => {
    const currentValues = query[name] || [];

    if (multiple) {
      if (currentValues.length === 0) {
        updateQuery({ [name]: [id].join(""), page: 1 });
      } else {
        updateQuery({ [name]: [currentValues, id].join(","), page: 1 });
      }
      return;
    }
    updateQuery({ [name]: id, page: 1 });
  };

  const {
    isOpen,
    openMenu,
    getComboboxProps,
    getMenuProps,
    getInputProps,
    getItemProps,
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.name : "";
    },
    defaultHighlightedIndex: 0,
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: 0,
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue, type, selectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            const { id } = selectedItem;
            const applitedIds = query[name]?.split(",");

            if (applitedIds?.includes(String(id))) {
              removeSelection(String(id));
            } else {
              select(String(id));
            }

            setInputValue("");
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || "");

          break;
        default:
          break;
      }
    },
  });

  return (
    <div {...getComboboxProps()}>
      <div className={styles.wrapper}>
        {selectedItems.map((selectedItemForRender, index) => {
          return (
            <div
              className={styles.selectedItem}
              key={`selected-item-${index}`}
              {...getSelectedItemProps({
                selectedItem: selectedItemForRender,
                index,
              })}
              onClick={e => {
                e.stopPropagation();
                removeSelection(String(selectedItemForRender.id));
              }}
            >
              {selectedItemForRender.name}

              <img src={closeIcon} alt="" />
            </div>
          );
        })}

        <div className={styles.inputWrapper}>
          <input
            {...getInputProps(
              getDropdownProps({
                preventKeyAction: isOpen,
              }),
            )}
            placeholder="Szukaj"
            className={styles.input}
          />
          <button
            aria-label="toggle menu"
            type="button"
            onClick={() => {
              if (inputValue) {
                setInputValue("");
                return;
              }
              openMenu();
            }}
          >
            {inputValue ? (
              <img src={closeIcon} alt="icon" />
            ) : (
              <img src={magnifierIcon} alt="icon" />
            )}
          </button>
          <ul
            className={`${styles.list} ${!(isOpen && items.length) && styles.hidden}`}
            {...getMenuProps()}
          >
            {isOpen &&
              items.map((item, index) => (
                <li
                  className={cx(
                    styles.listItem,
                    query[name]?.includes(String(item.id)) && styles.selected,
                  )}
                  key={`${item.id}${index}`}
                  {...getItemProps({ item, index })}
                >
                  <span>{highlightTextFragment(inputValue, item.name, item.id)}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

type PartialOptions =
  | Partials["salesAccounts"]
  | Partials["customers"]
  | Partials["users"]
  | Partials["businessEntities"];

const assertIsUser = (attr: PartialOptions[number]): attr is Partials["users"][number] =>
  Boolean((attr as Partials["users"][number]).firstName);

const assertIsBusinessEntity = (
  attr: PartialOptions[number],
): attr is Partials["businessEntities"][number] =>
  Boolean((attr as Partials["businessEntities"][number]).companyName);

interface NormalizedData {
  id: number | string;
  name: string;
}

const normalizeData = (data: PartialOptions): NormalizedData[] => {
  return data.map(obj => {
    if (assertIsBusinessEntity(obj)) {
      return { id: obj.id, name: obj.companyName };
    }
    if (assertIsUser(obj)) {
      return { id: obj.id, name: `${obj.firstName} ${obj.lastName}` };
    }
    return { id: obj.id, name: obj.name };
  });
};
