import { Button } from "components/common";
import checkIcon from "assets/images/checkSmall.svg";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import {
  patchPurchaseInvoiceStatus,
  patchTradingDocumentStatus,
} from "api/trading-documents/calls";
import { TradingDocument } from "api/trading-documents/models";
import { Pagination } from "api/types";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { getAnyErrorKey, queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useQuery } from "hooks";
import { QueryClient } from "react-query";

interface Props {
  route?: string | number;
  tradingDocument: TradingDocument;
}

export const ConfirmCheckbox = ({ route, tradingDocument }: Props) => {
  const { query } = useQuery();
  const { panelId, ...filters } = query;

  const handleRollback = (
    prevList: Pagination<TradingDocument>,
    prevPanel: TradingDocument,
    queryClient: QueryClient,
  ) => {
    queryClient.setQueryData<Pagination<TradingDocument>>(
      tradingDocumentsKeys.tradingDocument.list(
        route
          ? queryString.stringify({
              ...filters,
              routeId: route,
            })
          : queryString.stringify({
              ...filters,
              type: tradingDocument.type,
              invoiceType: tradingDocument.invoiceType,
            }),
      ),
      currentList => {
        assertIsDefined(currentList);
        return {
          ...currentList,
          results: prevList.results,
        };
      },
    );

    if (panelId && panelId === tradingDocument.id) {
      queryClient.setQueryData<TradingDocument>(
        tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        currentDocument => {
          assertIsDefined(currentDocument);
          return {
            ...currentDocument,
            status: prevPanel.status,
          };
        },
      );
    }
  };

  const updateStatusOptions = useMutationOptions(
    () => {
      const data = { tradingDocument: tradingDocument.id };
      if (tradingDocument.invoiceType === "PURCHASE") return patchPurchaseInvoiceStatus(data);
      return patchTradingDocumentStatus(data);
    },
    ({ queryClient, toastr }) => ({
      onMutate: () => {
        const prevList = queryClient.getQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            route
              ? queryString.stringify({
                  ...filters,
                  routeId: route,
                })
              : queryString.stringify({
                  ...filters,
                  type: tradingDocument.type,
                  invoiceType: tradingDocument.invoiceType,
                }),
          ),
        );
        const prevPanel = queryClient.getQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );

        queryClient.setQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            route
              ? queryString.stringify({
                  ...filters,
                  routeId: route,
                })
              : queryString.stringify({
                  ...filters,
                  type: tradingDocument.type,
                  invoiceType: tradingDocument.invoiceType,
                }),
          ),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(result => {
                if (result.id === tradingDocument.id) {
                  return { ...result, status: "CONFIRMED" };
                }
                return result;
              }),
            };
          },
        );

        if (panelId && panelId === tradingDocument.id) {
          queryClient.setQueryData<TradingDocument>(
            tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
            currentDocument => {
              assertIsDefined(currentDocument);
              return {
                ...currentDocument,
                status: "CONFIRMED",
              };
            },
          );
        }

        return { prevList, prevPanel };
      },
      onSuccess: payload => {
        queryClient.setQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            route
              ? queryString.stringify({
                  ...filters,
                  routeId: route,
                })
              : queryString.stringify({
                  ...filters,
                  type: tradingDocument.type,
                  invoiceType: tradingDocument.invoiceType,
                }),
          ),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(result => {
                if (result.id === tradingDocument.id) {
                  return { ...result, status: payload.status, signature: payload.signature };
                }
                return result;
              }),
            };
          },
        );

        if (panelId && panelId === tradingDocument.id) {
          queryClient.setQueryData<TradingDocument>(
            tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
            currentDocument => {
              assertIsDefined(currentDocument);
              return {
                ...currentDocument,
                status: payload.status,
                signature: payload.signature,
              };
            },
          );
        }

        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zatwierdzono dokument",
        });
      },
      onError: (error, _, context) => {
        const { prevList, prevPanel } = context as {
          prevList: Pagination<TradingDocument>;
          prevPanel: TradingDocument;
        };
        handleRollback(prevList, prevPanel, queryClient);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const updateStatusMutation = useMutation(updateStatusOptions.mutationFn, updateStatusOptions);

  return (
    <Button
      disabled={tradingDocument.status === "CONFIRMED"}
      kind="checkbox"
      onMouseDown={e => e.stopPropagation()}
      onClick={e => {
        e.stopPropagation();
        updateStatusMutation.mutate({});
      }}
      size="square-xs"
    >
      <div className="btnBase btnBaseSmall">
        <img alt="Zaznacz" src={checkIcon} />
      </div>
    </Button>
  );
};
