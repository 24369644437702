import cx from "classnames";
import { usePointsForRoute } from "hooks/apiPrimitives";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Creator.module.css";
import { useRouteSearch } from "./hooks/useRouteSearch";
import { passpointClickOutsideIgnoreClass } from "./leftPanel/pointsList/passpoint/Passpoint";
import { EmptyMap } from "./map/EmptyMap";
import { useDragPolygonController } from "./map/DragPolygon";

export type MapMode = "map" | "polygon";

function useMode() {
  const [state, setState] = useState<MapMode>("map");
  const toggle = useCallback(() => setState(s => (s === "map" ? "polygon" : "map")), [setState]);
  const initialMount = useRef(true);
  const onChangeCallback = useRef<() => void>(() => {});

  useEffect(() => {
    if (initialMount.current === false) {
      onChangeCallback.current();
    }
  }, [state]);

  useEffect(() => {
    initialMount.current = false;
  }, []);

  return [state, toggle] as const;
}

export const CreatorEmpty = () => {
  const search = useRouteSearch();
  const polygonController = useDragPolygonController();
  const [mode] = useMode();

  const { data: routePoints } = usePointsForRoute(search, {
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });

  if (!routePoints) {
    return null;
  }
  return (
    <div className="d-flex">
      <div className={cx("position-relative overflow-hidden", styles.wrapper)}>
        <div className={passpointClickOutsideIgnoreClass}>
          <EmptyMap
            polygonController={polygonController}
            mapMode={mode}
            routePoints={routePoints.results}
          />
        </div>
      </div>
    </div>
  );
};
