import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import bookIcon from "assets/images/book.svg";
import { Reservations } from "routes/wms/Reservations";

export const reservations: ModuleLink = {
  url: "reservations",
  label: "Rezerwacje",
  icon: bookIcon,
  subSection: <></>,
  routing: Reservations,
};
