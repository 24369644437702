import { PurchaseInvoiceToReview } from "api/trading-documents/models";
import { UUID } from "api/types";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { ItemsSummarySection } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/ItemsSummarySection";
import { CustomerInfoSection } from "./components/customerInfoSection/CustomerInfoSection";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";
import { PanelHeader } from "./components/PanelHeader";
import { TitleSection } from "./components/titleSection/TitleSection";
import styles from "./RightPanel.module.css";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { useRef } from "react";

interface Props {
  tradingDocumentId: UUID;
  invoiceToReview: PurchaseInvoiceToReview;
}

export const RightPanel = ({ tradingDocumentId, invoiceToReview }: Props) => {
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  return (
    <DrawerRightPanel className={styles.widerDrawer} key={invoiceToReview.id}>
      <PanelHeader invoiceToReview={invoiceToReview} />
      <RightPanelBody myRef={clearCommentsRef}>
        <TitleSection invoice={invoiceToReview} />
        <GeneralInfoSection invoice={invoiceToReview} />
        <ItemsSummarySection
          enableAddingPosition={true}
          isPurchaseImport={true}
          showTransactionNumber={false}
          tradingDocument={invoiceToReview}
        />
        <CustomerInfoSection invoice={invoiceToReview} />
      </RightPanelBody>
      <CommentsGenericSection
        outsideRef={clearCommentsRef}
        paramName="tradingDocument"
        endpointUrl="/finances/comments/items"
        commentedObject={invoiceToReview}
      />
    </DrawerRightPanel>
  );
};
