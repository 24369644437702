import { EmployeeWorkingDay } from "api/new-production-plans/models";
import cx from "classnames";
import localStyles from "./Slots.module.css";
import addSlotIcon from "assets/images/slot.svg";
import { Button } from "components/common";

interface Props {
  employeeWorkingDay: EmployeeWorkingDay;
}

export const Slots = ({ employeeWorkingDay }: Props) => {
  const categorySummarySlots = employeeWorkingDay.categorySummary.reduce((count, summary) => {
    return count + summary.slots;
  }, 0);
  const remainingDefaultSlots = Math.max(
    employeeWorkingDay.slots.default - categorySummarySlots,
    0,
  );

  const getRemainingExtraSlots = () => {
    if (remainingDefaultSlots <= 0) {
      return (
        employeeWorkingDay.slots.extra - (categorySummarySlots - employeeWorkingDay.slots.default)
      );
    }

    return employeeWorkingDay.slots.extra;
  };
  const remainingExtraSlots = getRemainingExtraSlots();

  const getCategorySummarySlots = () => {
    return employeeWorkingDay.categorySummary.map((summary, index) => {
      const slots = [];
      for (let i = 0; i < summary.slots; i++) {
        if ((summary.code === null || summary.color === null) && summary.name === null) {
          slots.push(
            <div
              key={i}
              className={cx(localStyles.slot, {
                "ml-3": i === employeeWorkingDay.slots.default,
              })}
              style={{ background: "#000", color: "#fff" }}
            >
              BK
            </div>,
          );
        } else if (
          (summary.code === null || summary.color === null || summary.code.length === 0) &&
          summary.name !== null
        ) {
          if (summary.color && summary.color.length > 0) {
            slots.push(
              <div
                key={i}
                className={cx(localStyles.slot, {
                  "ml-3": i === employeeWorkingDay.slots.default,
                })}
                style={{ background: `${summary.color}`, color: "#fff" }}
              >
                {summary.name.slice(0, 2).toUpperCase()}
              </div>,
            );
          } else {
            slots.push(
              <div
                key={i}
                className={cx(localStyles.slot, {
                  "ml-3": i === employeeWorkingDay.slots.default,
                })}
                style={{ background: "#000", color: "#fff" }}
              >
                {summary.name.slice(0, 2).toUpperCase()}
              </div>,
            );
          }
        } else {
          slots.push(
            <div
              key={i}
              className={cx(localStyles.slot, {
                "ml-3": i === employeeWorkingDay.slots.default,
              })}
              style={{ background: `${summary.color}`, color: "#fff" }}
            >
              {summary.code}
            </div>,
          );
        }
      }

      return slots;
    });
  };

  const getDefaultSlots = () => {
    const defaultSlots = [];
    for (let i = 0; i < remainingDefaultSlots; i++) {
      defaultSlots.push(
        <div key={i} className={cx(localStyles.slot, localStyles.slotPlaceholder)} />,
      );
    }
    return defaultSlots;
  };

  const getExtraSlots = () => {
    const extraSlots = [];
    for (let i = 0; i < remainingExtraSlots; i++) {
      if (i === 0 && remainingExtraSlots === employeeWorkingDay.slots.extra) {
        extraSlots.push(
          <div key={i} className={cx(localStyles.slot, localStyles.slotPlaceholder, "ml-3")} />,
        );
      } else {
        extraSlots.push(
          <div key={i} className={cx(localStyles.slot, localStyles.slotPlaceholder)} />,
        );
      }
    }
    return extraSlots;
  };

  return (
    <div className={cx(localStyles.slots)}>
      {getCategorySummarySlots()}
      {getDefaultSlots()}
      {getExtraSlots()}
      <Button kind="secondary" className={localStyles.addSlotBtn}>
        <img alt="Dodaj pusty slot" src={addSlotIcon} />
      </Button>
    </div>
  );
};
