import { TradingDocument } from "api/trading-documents/models";

import { RightPanelSection } from "components/utils/drawer";

interface Props {
  tradingDocument: TradingDocument;
  updateTradingDocumentOptions?: any;
}

export const SellerInfoSection = ({ tradingDocument }: Props) => {
 
  return (
    <RightPanelSection title="Sprzedawca">
      <div className="pb-3">
        {tradingDocument.sellerCompanyName && (
          <div className="body-18 pb-1">{tradingDocument.sellerCompanyName}</div>
        )}
        {tradingDocument.sellerStreet && (
          <div className="body-14-500 pb-1">{tradingDocument.sellerStreet}</div>
        )}
        {(tradingDocument.sellerPostCode || tradingDocument.sellerCity) && (
          <div className="body-14-500 pb-1">
            {tradingDocument.sellerPostCode}&nbsp;{tradingDocument.sellerCity}
          </div>
        )}
        {tradingDocument.sellerBankAccountNumber && (
          <div className="body-14-500 pb-1">{tradingDocument.sellerBankAccountNumber}</div>
        )}
        {tradingDocument.sellerTaxId && (
          <div className="body-14-500 pb-1">NIP:&nbsp;{tradingDocument.sellerTaxId}</div>
        )}
      </div>
    </RightPanelSection>
  );
};
