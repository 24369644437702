import { WarehousePackagesInOrOutSummary } from "api/trading-documents/models";
import styles from "../../WarehouseFinancialState.module.css";
import cx from "classnames";
import { LinkToPage } from "components/utils/linkToPage";

interface Props {
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  packageOut: WarehousePackagesInOrOutSummary;
  togglePanel: (id: string) => void;
}

export const PackageOutRow = ({
  index,
  isActive,
  isHighlighted,
  packageOut,
  togglePanel,
}: Props) => {
  const sumAmount = packageOut.outCount ? Number(packageOut.amount) * packageOut.outCount : 0;

  return (
    <div
      className={cx(styles.tableRow, styles.tableRowWarehouseState, {
        active: isActive(String(packageOut.package)),
        highlighted: isHighlighted(String(packageOut.package)),
      })}
      onClick={() => togglePanel(String(packageOut.package))}
    >
      <div className="fw-700">{index + 1}.</div>
      <div className={cx("body-14-600", styles.overflow)}>{packageOut.name}</div>
      <div className="fw-700">
        {packageOut.internalId && packageOut.internalId.length > 0 ? packageOut.internalId : "---"}
      </div>
      {packageOut.lastWhEntryOut ? (
        <div>
          <LinkToPage
            content={<div className="fw-700">{packageOut.lastWhEntryOut.signature}</div>}
            stopPropagation
            url={`/warehouse/documents?panelId=${packageOut.lastWhEntryOut.id}`}
          />
        </div>
      ) : (
        <div>---</div>
      )}
      <div className="d-flex align-items-center justify-content-end">{packageOut.amount} PLN</div>
      <div className="d-flex align-items-center justify-content-end">
        {packageOut.outCount ? packageOut.outCount : 0} szt.
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {sumAmount.toFixed(2)} PLN
      </div>
    </div>
  );
};
