import styles from "../RightPanel.module.css";
import cx from "classnames";
import restartIcon from "assets/images/restartAlt.svg";
import { Button } from "components/common";
import { TradingDocument, viesStatusDict } from "api/trading-documents/models";
import { dateFns, getAnyErrorKey } from "utilities";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { updateViesStatus, updateWhiteListStatus } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { Spinner } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  tradingDocument: TradingDocument;
}

export const ViesSection = ({ tradingDocument }: Props) => {
  const updateViesStatusOptions = useMutationOptions(
    () => updateViesStatus({ id: tradingDocument.id }),
    ({ toastr, queryClient }) => ({
      onSuccess: payload => {
        queryClient.setQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
          currentDocument => {
            assertIsDefined(currentDocument);
            return {
              ...currentDocument,
              viesCheckedAt: payload.viesCheckedAt,
              viesValidStatus: payload.viesValidStatus,
            };
          },
        );
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const updateViesStatusMutation = useMutation(
    updateViesStatusOptions.mutationFn,
    updateViesStatusOptions,
  );

  const updateWhiteListStatusOptions = useMutationOptions(
    () => updateWhiteListStatus({ id: tradingDocument.id }),
    ({ toastr, queryClient }) => ({
      onSuccess: payload => {
        queryClient.setQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
          currentDocument => {
            assertIsDefined(currentDocument);
            return {
              ...currentDocument,
              whiteListCheckedAt: payload.whiteListCheckedAt,
              whiteListValidStatus: payload.whiteListStatus,
            };
          },
        );
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const updateWhiteListStatusMutation = useMutation(
    updateWhiteListStatusOptions.mutationFn,
    updateWhiteListStatusOptions,
  );

  return (
    <RightPanelSection>
      <div className="d-flex gap-5 py-3 borderTop borderBottom">
        <div>
          <div className="d-flex align-items-center gap-2 pb-2">
            <div className={cx(styles.viesTitle, "pl-0")}>VIES</div>
            <Button
              className="d-flex justify-content-center"
              kind="create"
              onClick={() => updateViesStatusMutation.mutate({})}
              style={{ height: "26px", width: "26px" }}
            >
              <div className="btnBase btnBaseSmall">
                <img alt="Odśwież" src={restartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
            <Spinner
              className={styles.tinySpinner}
              on={updateViesStatusMutation.isLoading}
              size="small"
              color="blue"
            />
          </div>
          <div className="d-flex align-items-center body-10 gap-2">
            <span className="text-color-grey fw-500">ostatnio sprawdzony:</span>
            <span>
              {tradingDocument.viesCheckedAt
                ? dateFns.format(new Date(tradingDocument.viesCheckedAt), "d.MM.yyyy")
                : "--"}
            </span>
          </div>
          <div className="d-flex align-items-center body-10 gap-2">
            <span className="text-color-grey fw-500">wówczas: </span>
            <span>
              {tradingDocument.viesValidStatus
                ? viesStatusDict[tradingDocument.viesValidStatus]
                : "--"}
            </span>
          </div>
        </div>
        {tradingDocument.countryCode === "PL" && (
          <div>
            <div className="d-flex align-items-center gap-2 pb-2">
              <div className={cx(styles.viesTitle, "pl-0")}>Biała lista</div>
              <Button
                className="d-flex justify-content-center"
                kind="create"
                onClick={() => updateWhiteListStatusMutation.mutate({})}
                style={{ height: "26px", width: "26px" }}
              >
                <div className="btnBase btnBaseSmall">
                  <img alt="Odśwież" src={restartIcon} style={{ height: "16px", width: "16px" }} />
                </div>
              </Button>
              <Spinner
                className={styles.tinySpinner}
                on={updateWhiteListStatusMutation.isLoading}
                size="small"
                color="blue"
              />
            </div>
            <div className="d-flex align-items-center body-10 gap-2">
              <span className="text-color-grey fw-500">ostatnio sprawdzony:</span>
              <span>
                {tradingDocument.whiteListCheckedAt
                  ? dateFns.format(new Date(tradingDocument.whiteListCheckedAt), "d.MM.yyyy")
                  : "--"}
              </span>
            </div>
            <div className="d-flex align-items-center body-10 gap-2">
              <span className="text-color-grey fw-500">wówczas: </span>
              <span>
                {tradingDocument.whiteListValidStatus
                  ? viesStatusDict[tradingDocument.whiteListValidStatus]
                  : "--"}
              </span>
            </div>
          </div>
        )}
      </div>
    </RightPanelSection>
  );
};
