import { useTradingDocument } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { PageHeader } from "components/common";
import { useInfiniteLogs } from "hooks/useInfinityLogs";
import { InvoiceHistory } from "pages/tradingDocuments/shared/components/invoiceHistory/InvoiceHistory";
import { useLocation, useParams } from "react-router";

type LocationState = { correctionInvoice: Pick<TradingDocument, "signature"> } | undefined;

export const CorrectionInvoiceHistory = () => {
  const params = useParams<{ correctionInvoiceId: UUID }>();
  const location = useLocation<LocationState>();

  const locationCorrectionInvoice = location.state?.correctionInvoice;
  const { data: fetchedInvoice } = useTradingDocument(
    { id: params.correctionInvoiceId },
    { enabled: !Boolean(locationCorrectionInvoice) },
  );
  const correctionInvoice = locationCorrectionInvoice ?? fetchedInvoice;

  const { logs, fetchMore, inProgress, hasMore } = useInfiniteLogs(
    params.correctionInvoiceId,
    "tradingDocument",
  );

  const subtitle = correctionInvoice?.signature ?? "";

  return (
    <div className="position-relative">
      <PageHeader
        searchBar={false}
        subtitle={subtitle}
        title="Historia korekty"
        viewLabel="CORRECTION_INVOICE_HISTORY"
      />
      <InvoiceHistory fetchMore={fetchMore} hasMore={hasMore} inProgress={inProgress} logs={logs} />
    </div>
  );
};
