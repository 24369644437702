import { PopupMenu } from "components/common/popupMenu";
import popUpstyles from "pages/tradingDocuments/shared/TradingDocumentsLists.module.css";
import history from "assets/images/history.svg";
import fileCopy from "assets/images/fileCopy.svg";
import editIcon from "assets/images/edit.svg";
import { Button } from "components/common";
import moreOptionsIcon from "assets/images/threeDots.svg";
import cx from "classnames";
import { Link } from "react-router-dom";
import { DeleteTradingDocumentBtn } from "../../deleteTradingDocumentBtn/DeleteTradingDocumentBtn";
import { TradingDocument } from "api/trading-documents/models";
import { getInvoiceLabel } from "api/trading-documents/calls";
import drawIcon from "assets/images/draw.svg";
import { useSelector } from "hooks";
import { DOMAIN } from "ENDPOINTS";

interface Props {
  close: () => void;
  invoice: TradingDocument;
  panelId: string;
  correctionMutation?: any;
}
export const MoreInfoButton = ({ close, invoice, panelId, correctionMutation }: Props) => {
  const type = getInvoiceLabel(invoice.invoiceType, invoice.type);
  const me = useSelector(state => state.auth.user!);
  return (
    <PopupMenu
      customStyles={popUpstyles}
      customButton={
        <Button
          data-for="external-options"
          data-event="click"
          data-event-off="mouseleave"
          data-tip="2"
          kind="transparent-black"
          size="square-s"
        >
          <div className="btnBase btnBaseSmall">
            <img
              alt="Więcej opcji"
              src={moreOptionsIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
      }
      dataFor="external-options"
    >
      <div className={popUpstyles.optionList}>
        <div className={cx(" px-3 py-2", popUpstyles.optionRow)}>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="d-flex align-items-center gap-2">
              <img src={editIcon} alt="Edytuj" />
              <div className="body-14-600">Edytuj</div>
            </div>
          </div>
        </div>

        <div className={cx("px-3 py-2 w-100", popUpstyles.optionRow)}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`${DOMAIN}finances/trading-documents/sales-duplicate-pdfs?tradingDocumentsIds=${invoice.id}&user=${me.id}`}
          >
            <div className="d-flex align-items-center gap-2">
              <img src={fileCopy} alt="Duplikat" />
              <div className="body-14-600">Duplikat faktury</div>
            </div>
          </a>
        </div>

        <hr style={{ margin: "5px 0" }} />
        {invoice.invoiceType === "PURCHASE" && (
          <div className={cx("px-3 py-2 w-100", popUpstyles.optionRow)}>
            <Link
              title="Połącz fakturę z PZ"
              to={`/finances/purchases/connect-invoice/${invoice.id}`}
              className="d-flex align-items-center justify-content-between gap-2"
            >
              <div className="d-flex align-items-center gap-2">
                <img
                  alt="Połącz fakturę z PZ"
                  src={drawIcon}
                  style={{ height: "16px", width: "16px" }}
                />
                <div className="body-14-600">Połącz fakturę z PZ</div>
              </div>
            </Link>
          </div>
        )}
        <div className={cx("px-3 py-2 w-100", popUpstyles.optionRow)}>
          <Link
            title="Historia faktury zakupowej"
            to={{
              pathname: `/finances/${type}/${panelId}/history`,
              state: { invoice },
            }}
            className="d-flex align-items-center justify-content-between gap-2"
          >
            <div className="d-flex align-items-center gap-2">
              <img src={history} alt="Historia" />
              <div className="body-14-600">Pokaz historię faktury</div>
            </div>
          </Link>
        </div>

        <hr style={{ margin: "5px 0" }} />
        <DeleteTradingDocumentBtn
          close={close}
          tradingDocumentId={invoice.id}
          tradingDocumentSignature={invoice.signature}
          tradingDocumentStatus={invoice.status}
        />
      </div>
    </PopupMenu>
  );
};
