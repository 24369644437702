import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useEffect, useRef, useState } from "react";
import { PanelHeader } from "pages/callCenter/shared/PanelHeader";
import { GeneralInfoSection } from "pages/callCenter/shared/GeneralInfoSection";
import { useCallCenterRouteOrder } from "api/call-center/routes/hooks";
import { MessagesWithCustomer } from "../../shared/messagesWithCustomer/MessagesWithCustomer";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: routeOrder, error, isLoading } = useCallCenterRouteOrder(Number(panelId), {
    enabled: Boolean(panelId),
  });

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (!routeOrder) return null;

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  return (
    <DrawerRightPanel key={routeOrder.id}>
      <PanelHeader
        routeOrder={routeOrder}
        panelId={panelId}
        close={close}
        isScrolledOver={isScrolledOver}
      />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody myRef={clearCommentsRef} setIsScrolledOver={setIsScrolledOver}>
          <GeneralInfoSection routeOrder={routeOrder} />
          <hr />
          <MessagesWithCustomer />
          <hr />
        </RightPanelBody>
        <CommentsGenericSection
          outsideRef={clearCommentsRef}
          commentedObject={{ ...routeOrder, id: String(routeOrder.id) }}
          paramName="order"
          endpointUrl="/orders/comments"
        />
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
