import warehouseYellowIcon from "assets/images/warehouseYellow.svg";
import warehouseIcon from "assets/images/warehouse2.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { unloadings } from "./routes/unloadings";
import { loadings } from "./routes/loadings";
import { warehouse as warehouseNavigation } from "./routes/warehouse";
import { warehousemen } from "./routes/warehousemen";
import { indexes } from "./routes/indexes";
import { packages } from "./routes/packages";
import { inventoryChecks } from "./routes/inventoryChecks";
import { reservations } from "./routes/reservations";

export const warehouse: ModuleNavigation = {
  labelBackground: "#e4b700",
  label: "WMS",
  iconBackground: "#6d5805",
  icon: warehouseYellowIcon,
  menuIcon: warehouseIcon,
  name: "WAREHOUSE",
  url: "/wms",
  navigationSections: [
    {
      navigationList: [
        dashboard,
        unloadings,
        loadings,
        warehouseNavigation,
        warehousemen,
        indexes,
        packages,
        inventoryChecks,
        reservations,
      ],
    },
  ],
};
