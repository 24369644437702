import styles from "../ModuleList.module.css";
import cx from "classnames";

interface Props {
  children?: React.ReactNode;
  className?: string;
  gridStyle?: string;
  labels?: string[];
  overwrites?: { label?: { style?: React.CSSProperties } };
}

export const ModuleListHeader = ({
  children,
  className,
  gridStyle = "",
  labels,
  overwrites = {},
}: Props) => {
  return (
    <div className={cx(className, styles.tableHeader)} style={{ gridTemplateColumns: gridStyle }}>
      {children ? (
        <>{children}</>
      ) : (
        <>
          {labels?.map(label => (
            <div key={label} style={overwrites.label?.style ?? undefined}>
              {label}
            </div>
          ))}
        </>
      )}
    </div>
  );
};
