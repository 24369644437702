import calculateIcon from "assets/images/calculatePink.svg";
import customersIcon from "assets/images/customers.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";

export const crm: ModuleNavigation = {
  labelBackground: "#b65eff",
  label: "Kontrahenci",
  iconBackground: "#431d87",
  icon: calculateIcon,
  menuIcon: customersIcon,
  name: "CRM",
  url: "/crm",
  navigationSections: [{ navigationList: [] }],
};
