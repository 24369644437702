import { Button } from "components/common";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import barcodeDarkIcon from "assets/images/barcodeDark.svg";
import { MultipleCheckedItemsToolbar } from "components/common/multipleCheckedItemsToolbar/MultipleCheckedItemsToolbar";
import { HighlightedRow } from "api/other/models";
import { DOMAIN } from "ENDPOINTS";
import { usePrintReceipts } from "../hooks/usePrintReceipts";

interface Props {
  actualPage: string | number;
  close: () => void;
  quantity: number;
  highlightedRows?: HighlightedRow[];
  setHighlightedRows: React.Dispatch<React.SetStateAction<HighlightedRow[]>>;
}
export const ActionToolbar = ({
  actualPage,
  close,
  quantity,
  highlightedRows,
  setHighlightedRows,
}: Props) => {
  const printReceiptsMutation = usePrintReceipts();

  return (
    <MultipleCheckedItemsToolbar
      actionButtons={
        <>
          <Button size="square-s" kind="transparent-black">
            <div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`${DOMAIN}utils/collection-production-pdf/routes-production-pdf?routes=${encodeURIComponent(
                  highlightedRows ? highlightedRows.map(row => row.id).join(",") : "",
                )}`}
              >
                <div className="btnBase btnBaseSmall">
                  <img alt="Podgląd PDF" src={downloadPdfIcon} />
                </div>
              </a>
            </div>
            <div className="defaultHintbox">Podgląd PDF dla zaznaczonych tras</div>
          </Button>
          <Button
            kind="transparent-black"
            onClick={() => {
              if (highlightedRows) {
                printReceiptsMutation.mutate({ routes: parseRows(highlightedRows).array });
              }
            }}
            size="square-s"
          >
            <div className="btnBase btnBaseSmall">
              <img alt="" src={barcodeDarkIcon} />
            </div>
            <div className="defaultHintbox">Drukuj paragony dla zaznaczonych tras</div>
          </Button>
        </>
      }
      close={close}
      quantity={quantity}
    />
  );
};

const parseRows = (rows: HighlightedRow[]): { string: any; array: number[] } => {
  let stringArray = rows.map(tradingDocument => Number(tradingDocument.id));
  return {
    string: stringArray.join(","),
    array: stringArray,
  };
};
