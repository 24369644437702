import styles from "./HistoryLogItem.module.css";
import cx from "classnames";
import { dateFns } from "utilities";
import ReactMarkdown from "react-markdown";
import { UserAvatar } from "components/utils/userAvatar";
import { Log } from "api/logbook/models";

interface LogProps {
  log: Log;
  displayCreatedBy?: boolean;
  showIcon?: boolean;
  icon?: { color: string; img: string };
}

export const HistoryLogItem = ({
  log,
  displayCreatedBy = true,
  showIcon = false,
  icon,
}: LogProps) => {
  const created = new Date(log.created);
  return (
    <div className={cx("mb-2", styles.item)}>
      <div className={cx(" d-flex flex-column align-items-center mr-3", styles.itemTiming)}>
        <div className={styles.itemDate}>{dateFns.format(created, "d MMM yyyy")}</div>
        <div className={styles.itemTime}>{dateFns.format(created, "HH:mm")}</div>
      </div>
      {showIcon && log.category && (
        <div
          className={cx(styles.iconContainer, { [icon?.color || ""]: !icon?.color.includes("#") })}
          style={{ background: icon?.color.includes("#") ? icon.color : "" }}
        >
          <img src={icon?.img || ""} alt="" />
        </div>
      )}
      <div className={styles.card}>
        <div className={styles.event}>
          <ReactMarkdown source={log.description} />
        </div>
        {log.user && displayCreatedBy && (
          <div className={cx("d-flex align-items-center", styles.person)}>
            <UserAvatar avatarSrc={log.user.avatar || ""} id={log.user.id} className="mr-2" />
            <div className={styles.name}>
              {log.user.firstName} {log.user.lastName}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
