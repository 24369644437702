import { Button, PageHeader } from "components/common";
import downloadFileIcon from "assets/images/downloadFile.svg";
import uploadFileIcon from "assets/images/uploadFile.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import areaChartIcon from "assets/images/areaChart.svg";
import { getTabs } from "pages/tradingDocuments/shared/utils/getTabs";
import { correctionInvoices } from "components/common/moduleNavigation/moduleConfig/accountancy/routes/correctionInvoices";

interface Props {
  additionalListParams: {
    [x: string]: string;
  };
  refetchList: () => void;
}

export const ListHeader = ({ additionalListParams, refetchList }: Props) => {
  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz plik"
                  src={downloadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Importuj plik"
                  src={uploadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <span className="line-divider" />
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyłącz widoczność"
                  src={visibilityOffIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img alt="Wykres" src={areaChartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
        }
        additionalListParams={additionalListParams}
        createButton={{ label: "Utwórz fakturę", onClick: () => {} }}
        searchInput={{
          label: "Szukaj wśród korekt",
          tags: [
            {
              name: "issueDateFrom",
              label: "data wystawienia (od)",
              value: "2023-08-01",
            },
            {
              name: "issueDateTo",
              label: "data wystawienia (do)",
              value: "2023-08-04",
            },
          ],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `finances/${correctionInvoices.url}`,
          urlSpan: "list",
        }}
        viewLabel="CORRECTION_INVOICES"
      />
    </div>
  );
};
