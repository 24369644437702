import { RouteTrackingDayPosition } from "api/call-center/routes/models";
import { DayType } from "../RouteTrackingModal";

interface Props {
  dayType: DayType;
  position: RouteTrackingDayPosition;
}

export const NewDeliveryTime = ({ dayType, position }: Props) => {
  if (dayType === "FUTURE") return <div></div>;

  return (
    <>
      {position.newDeliveryTime ? (
        <div>
          {position.newDeliveryTime.min === position.calculatedDeliveryTime.min &&
          position.newDeliveryTime.max === position.calculatedDeliveryTime.max ? (
            "bez zmian"
          ) : (
            <>
              {position.newDeliveryTime.min} - {position.newDeliveryTime.max}
            </>
          )}
        </div>
      ) : (
        <div>---</div>
      )}
    </>
  );
};
