import localStyles from "./ReceptionsSection.module.css";
import cx from "classnames";
import { PanelHeader } from "./components/PanelHeader";
import { InvoiceAdmission } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";
import { useAssignedWhEntries } from "api/trading-documents/hooks";
import { queryString } from "utilities";
import { CommonError, Spinner } from "components/utils";
import { WhEntry } from "./components/WhEntry";
import { SearchBar } from "./components/searchBar/SearchBar";

interface Props {
  invoiceAdmission: InvoiceAdmission;
}

export const ReceptionsSection = ({ invoiceAdmission }: Props) => {
  const search = queryString.stringify({
    invoiceAdmissionLink: invoiceAdmission.id,
    pageSize: 999,
  });
  const areReviewersEmpty = invoiceAdmission.reviewers.length === 0;
  const { data: whEntries, error, isLoading } = useAssignedWhEntries(search);

  if (error) {
    <div className={cx(localStyles.toolPanel, "col-12 col-xl-5 col-xxl-4 px-0")}>
      <PanelHeader invoiceAdmission={invoiceAdmission} />
      <div className={localStyles.toolPanelBody}>
        <CommonError status={error._httpStatus_} />
      </div>
    </div>;
  }

  if (isLoading) {
    return (
      <div className={cx(localStyles.toolPanel, "col-12 col-xl-5 col-xxl-4 px-0")}>
        <PanelHeader invoiceAdmission={invoiceAdmission} />
        <div className={localStyles.toolPanelBody}>
          <Spinner
            color="blue"
            position="absolute"
            size="big"
            text="trwa wczytywanie ostatnich przyjęć"
          />
        </div>
      </div>
    );
  }

  return (
    <div className={cx(localStyles.toolPanel, "col-12 col-xl-5 col-xxl-4 px-0")}>
      <PanelHeader invoiceAdmission={invoiceAdmission} />
      <div className={localStyles.toolPanelBody}>
        <RightPanelSection title="Ostatnie przyjęcia" titleClassName={localStyles.receptionsTitle}>
          {whEntries.map(whEntry => (
            <WhEntry key={whEntry.id} whEntry={whEntry} />
          ))}
        </RightPanelSection>
      </div>
      <SearchBar
        areReviewersEmpty={areReviewersEmpty}
        invoiceAdmissionLink={invoiceAdmission.id}
        assignedSearch={search}
      />
    </div>
  );
};
