import { useQueryUtils } from "hooks";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { RouteEditedHandler } from "pages/routes/shared/RouteEditedHandler";
import { useQueryClient } from "react-query";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import {
  getCallCenterRoute,
  getCallCenterRouteOrder,
  getCallCenterRouteOrders,
  getCallCenterRoutes,
  getCallCenterRouteTracking,
  getDashboardDepartedRoutes,
  getDashboardIncompleteRoutes,
  getDashboardLatestSmsMessages,
  patchRouteListItem,
  patchRouteTracking,
  patchSingleRouteOrderListItem,
} from "./calls";
import { callCenterRoutesKeys } from "./keys";
import { CallCenterRouteOrderListItem, RouteTracking } from "./models";

export const useDashboardDepartedRoutes = createPaginatedApiQuery(getDashboardDepartedRoutes);
export const useDashboardIncompleteRoutes = createPaginatedApiQuery(getDashboardIncompleteRoutes);
export const useDashboardLatestSmsMessages = createPaginatedApiQuery(getDashboardLatestSmsMessages);

export const useCallCenterRoutes = createPaginatedApiQuery(getCallCenterRoutes);
export const useCallCenterRoute = createApiQuery(getCallCenterRoute);

export const useCallCenterRouteOrders = createPaginatedApiQuery(getCallCenterRouteOrders);
export const useCallCenterRouteOrder = createApiQuery(getCallCenterRouteOrder);

export const useCallCenterRouteTracking = createApiQuery(getCallCenterRouteTracking);

// +++ TODO: restore prevPanel when RightPanel is implemented

export const useRoutePatchMutation = () => {
  const { handleMutate, handlePaginatedListUpdate, rollback, rollbackList } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: PartialOf<CallCenterRouteOrderListItem> }) =>
      patchSingleRouteOrderListItem(parsePatchData(toUpdate), id),
    {
      onMutate: ({ id, toUpdate }) => {
        RouteEditedHandler(id);
        const prevPanel = handleMutate(callCenterRoutesKeys.routeOrder.details(id), toUpdate);

        const prevList = handlePaginatedListUpdate(
          callCenterRoutesKeys.routeOrder.list(),
          id,
          toUpdate,
        );
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevList, prevPanel } = onMutateReturn as { prevList: any; prevPanel: any };
        rollback(callCenterRoutesKeys.routeOrder.details(id), prevPanel, error);
        rollbackList(callCenterRoutesKeys.routeOrder.list(), prevList, id, error);
      },
    },
  );
};

export const useRouteListItemPatchMutation = () => {
  const { handlePaginatedListUpdate, rollbackList } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: PartialOf<CallCenterRouteOrderListItem> }) =>
      patchRouteListItem(parsePatchData(toUpdate), id),
    {
      onMutate: async ({ id, toUpdate }) => {
        RouteEditedHandler(id);
        const prevList = handlePaginatedListUpdate(callCenterRoutesKeys.route.list(), id, toUpdate);

        return { prevList };
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevList } = onMutateReturn as { prevList: any };
        rollbackList(callCenterRoutesKeys.route.list(), prevList, id, error);
      },
    },
  );
};

export const useRouteTrackingMutation = () => {
  const queryClient = useQueryClient();
  const { handleMutate, rollback } = useQueryUtils();

  return useMutation(
    ({
      id,
      toUpdate,
    }: {
      id: number;
      toUpdate: PartialOf<Pick<RouteTracking, "departureTime" | "realDepartureTime">>;
    }) => patchRouteTracking(parsePatchData(toUpdate), id),
    {
      onMutate: ({ id, toUpdate }) => {
        RouteEditedHandler(id);
        const prevPanel = handleMutate(
          callCenterRoutesKeys.routeTracking.details(String(id)),
          toUpdate,
        );
        return { prevPanel };
      },
      onSuccess: (paylod, variables) => {
        queryClient.invalidateQueries(
          callCenterRoutesKeys.routeTracking.details(String(variables.id)),
        );
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevPanel } = onMutateReturn as { prevList: any; prevPanel: any };
        rollback(callCenterRoutesKeys.routeTracking.details(String(id)), prevPanel, error);
      },
    },
  );
};
