import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader } from "components/utils/drawer";
import { useQuery, useSelector, useStateModal } from "hooks";
import { Button } from "components/common";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import downloadXmlIcon from "assets/images/downloadXml.svg";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { handleDownloadOrderXml } from "pages/tradingDocuments/shared/utils/handleDownloadOrderXml";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import cx from "classnames";
import { DOMAIN } from "ENDPOINTS";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { postPreviewCorrection } from "api/trading-documents/calls";
import { getAnyErrorKey } from "utilities";
import { CreateCorrectionManuallyModal } from "./createCorrectionManuallyModal/CreateCorrectionManuallyModal";
import { PreviewCorrectionDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import darkPlusIcon from "assets/images/darkPlus.svg";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  showLoader: boolean;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PanelHeader = ({ close, isScrolledOver, showLoader, setShowLoader }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const me = useSelector(store => store.auth.user!);
  const { data: salesInvoice } = useTradingDocument({ id: panelId });

  const createCorrectionManuallyModal = useStateModal<{
    preview: PreviewCorrectionDocument;
    tradingDocumentId: UUID;
  } | null>();

  const previewCorrectionDocumentOptions = useMutationOptions(
    postPreviewCorrection,
    ({ toastr }) => ({
      onMutate: () => {
        setShowLoader(true);
      },
      onSuccess: payload => {
        setShowLoader(false);
        if (salesInvoice) {
          createCorrectionManuallyModal.open({
            preview: payload,
            tradingDocumentId: salesInvoice.id,
          });
        } else {
          toastr.open({
            type: "warning",
            title: "Wymagane działanie",
            text: "Brak informacji o fakturze",
          });
        }
      },
      onError: error => {
        setShowLoader(false);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const previewCorrectionDocumentMutation = useMutation(
    previewCorrectionDocumentOptions.mutationFn,
    previewCorrectionDocumentOptions,
  );

  if (!salesInvoice) return null;

  return (
    <>
      <RightPanelHeader
        className={cx({
          slidingPanelHeaderShadow: isScrolledOver,
          panelHeaderDuringSlide: isScrolledOver,
        })}
      >
        {isScrolledOver ? (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent d-flex align-items-center gap-2">
              <ConfirmCheckbox tradingDocument={salesInvoice} />
              <div>Faktura VAT&nbsp;{salesInvoice.signature}</div>
            </div>
          </div>
        ) : (
          <ConfirmTradingDocument tradingDocument={salesInvoice} />
        )}

        <div className="d-flex align-items-center justify-content-end gap-1">
          {salesInvoice.status === "CONFIRMED" && (
            <Button
              className="mr-1"
              disabled={previewCorrectionDocumentMutation.isLoading}
              kind="create"
              onClick={() => {
                previewCorrectionDocumentMutation.mutate({
                  tradingDocument: salesInvoice.id,
                });
              }}
              size="small"
            >
              <div className="btnBase btnBaseSmall">
                <img alt="Gotowe" src={darkPlusIcon} style={{ height: "16px", width: "16px" }} />
                <div className="fs-10">Utwórz korektę</div>
              </div>
            </Button>
          )}

          <Button
            kind="transparent-black"
            onClick={() => {
              handleDownloadOrderXml(salesInvoice, downloadFeedbackToastr);
            }}
            size="square-s"
            title="Pobierz XML z danymi faktury sprzedażowej"
          >
            <div className="btnBase btnBaseSmall">
              <img alt="Pobierz XML" src={downloadXmlIcon} />
            </div>
          </Button>
          <Button
            size="square-s"
            title="Podgląd PDF z danymi faktury sprzedażowej"
            kind="transparent-black"
          >
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`${DOMAIN}finances/trading-documents/sales-pdfs?tradingDocumentsIds=${salesInvoice.id}&user=${me.id}`}
            >
              <div className="btnBase btnBaseSmall">
                <img alt="Podgląd PDF" src={downloadPdfIcon} />
              </div>
            </a>
          </Button>
          <MoreInfoButton
            correctionMutation={previewCorrectionDocumentMutation}
            close={close}
            invoice={salesInvoice}
            panelId={panelId}
          />

          <span className="divider line-divider" />
          <Button kind="transparent-black" onClick={close} size="square-s">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Zamknij panel"
                src={hidePanelIcon}
                style={{ height: "16px", width: "16px" }}
              />
            </div>
          </Button>
        </div>
      </RightPanelHeader>
      <CreateCorrectionManuallyModal
        close={createCorrectionManuallyModal.close}
        isOpen={createCorrectionManuallyModal.isOpen}
        state={createCorrectionManuallyModal.state}
      />
    </>
  );
};
