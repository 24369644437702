import { patchPurchaseInvoicePosition } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { AddPurchaseInvoicePosition, PurchaseInvoiceToReview } from "api/trading-documents/models";
import { Pagination, UUID } from "api/types";
import { AsyncInput } from "components/utils";
import { useQuery } from "hooks";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import React, { useState } from "react";
import { QueryClient } from "react-query";
import { getAnyErrorKey, queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../RightPanel.module.css";

interface Props {
  inputPlaceholder: string;
  inputType: "number" | "string";
  setIsDisplayPositionEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  target: React.ReactNode;
  targetKey: keyof AddPurchaseInvoicePosition;
  tradingDocumentItemId: UUID;
  value: number | string;
}

export const EditPurchaseInvoicePosition = ({
  inputPlaceholder,
  inputType,
  setIsDisplayPositionEdited,
  target,
  targetKey,
  tradingDocumentItemId,
  value,
}: Props) => {
  const [showInput, setShowInput] = useState(false);
  const { query } = useQuery({ exclude: ["panelId"] });
  const pageSize = { pageSize: 1 };
  const search = queryString.stringify({ ...query, ...pageSize });

  const handleRollback = (
    prevList: Pagination<PurchaseInvoiceToReview>,
    queryClient: QueryClient,
  ) => {
    queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
      tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
      currentList => {
        assertIsDefined(currentList);
        return prevList;
      },
    );
  };

  const patchPositionOptions = useMutationOptions(
    patchPurchaseInvoicePosition,
    ({ queryClient, toastr }) => ({
      onMutate: toUpdate => {
        const prevList = queryClient.getQueryData<Pagination<PurchaseInvoiceToReview>>(
          tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
        );

        queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
          tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(result => {
                return {
                  ...result,
                  items: result.items.map(item => {
                    return {
                      ...item,
                      tradingDocumentItems: item.tradingDocumentItems.map(tradingDocumentItem => {
                        if (tradingDocumentItem.id === tradingDocumentItemId) {
                          return {
                            ...tradingDocumentItem,
                            ...toUpdate,
                          };
                        }
                        return tradingDocumentItem;
                      }),
                    };
                  }),
                };
              }),
            };
          },
        );

        return { prevList };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(tradingDocumentsKeys.purchaseInvoicesToReview.list(search));
        setShowInput(false);
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Edytowano pozycję",
        });
      },
      onError: (error, _, context) => {
        const { prevList } = context as { prevList: Pagination<PurchaseInvoiceToReview> };
        handleRollback(prevList, queryClient);
        setShowInput(false);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const patchPositionMutation = useMutation(patchPositionOptions.mutationFn, patchPositionOptions);

  return (
    <div className="fw-700">
      {!showInput ? (
        <div
          className={styles.editableItem}
          onClick={e => {
            e.stopPropagation();
            setShowInput(true);
            if (inputPlaceholder === "#" && setIsDisplayPositionEdited) {
              setIsDisplayPositionEdited(true);
            }
          }}
        >
          {target}
        </div>
      ) : (
        <div className="d-flex align-items-center gap-1">
          <AsyncInput
            disabled={patchPositionMutation.isLoading}
            inProgress={patchPositionMutation.isLoading}
            onChange={value => {
              patchPositionMutation.mutate({
                id: tradingDocumentItemId,
                [targetKey]: value,
              });
            }}
            overwrites={{
              container: {
                className: styles.editPurchaseInvoicePositionInput,
              },
              input: {
                className: styles.editPurchaseInvoicePositionInput,
              },
            }}
            placeholder={inputPlaceholder}
            type={inputType}
            value={value}
          />
        </div>
      )}
    </div>
  );
};
