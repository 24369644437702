import ruleGreyIcon from "assets/images/ruleGrey.svg";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { CorrectionInvoices } from "routes/CorrectionInvoices";
import { FilterList } from "../../../components/filtersSection/filterList/FilterList";
import { countryCodes, currenciesToPick } from "CONSTANTS";
import { paymentMethodsToPick, paymentProviderToPick } from "api/trading-documents/models";
import { FilterType } from "components/common/filters/types";

export const correctionInvoices: ModuleLink = {
  url: "corrections",
  label: "Korekty",
  icon: ruleGreyIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.DateRange,
          label: "data sprzedaży",
          name: ["soldAfter", "soldBefore"],
          showMonths: true,
        },
        {
          type: FilterType.DateRange,
          label: "data wystawienia",
          name: ["fromInvoiceIssueDate", "toInvoiceIssueDate"],
          showMonths: true,
        },
        {
          type: FilterType.Select,
          label: "państwo",
          name: "countryCode",
          multiple: true,
          kind: "label",
          options: countryCodes.map(({ icon, value }) => ({
            label: value,
            value,
            icon,
          })),
        },
        {
          type: FilterType.Search,
          label: "konto sprzedażowe",
          name: "salesAccount",
          searchBy: "salesAccounts",
        },
        {
          type: FilterType.Select,
          label: "status faktury",
          name: "status",
          options: [
            { label: "Zatwierdzone", value: "CONFIRMED" },
            { label: "Niezatwierdzone", value: "NEW" },
          ],
        },
        {
          type: FilterType.Select,
          label: "status płatności",
          name: "isPaid",
          options: [
            { label: "Opłacono", value: "true" },
            { label: "Nie opłacono", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "forma płatności",
          multiple: true,
          name: "paymentProvider",
          options: paymentProviderToPick,
        },
        {
          type: FilterType.Select,
          label: "waluta",
          name: "currency",
          kind: "label",
          options: currenciesToPick,
          multiple: true,
        },
        {
          type: FilterType.Select,
          label: "sposób płatności",
          name: "paymentType",
          options: paymentMethodsToPick,
        },
        {
          type: FilterType.SelectUser,
          label: "kto się zajmuje?",
          multiple: true,
          name: "isAssigned",
          searchBy: "standardUsers",
        },
        {
          type: FilterType.Search,
          label: "kontrahent",
          name: "customers",
          multiple: true,
          searchBy: "customers",
        },
        {
          type: FilterType.Date,
          label: "data przelewu",
          name: "transferDate",
        },
        {
          type: FilterType.Select,
          label: "zaległe faktury",
          name: "isPaymentOverdue",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Text,
          label: "nr przelewu",
          placeholder: "Szukaj nr przelewu",
          name: "transferNumber",
        },
      ]}
    />
  ),
  routing: CorrectionInvoices,
};
