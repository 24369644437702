import { useTradingDocument } from "api/trading-documents/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { AmountSection } from "pages/tradingDocuments/shared/components/rightPanel/amountSection/AmountSection";
import { CorrectionSection } from "pages/tradingDocuments/shared/components/rightPanel/correctionSection/CorrectionSection";
import { CorrespondingDocumentSection } from "pages/tradingDocuments/shared/components/rightPanel/correspondingDocumentsSection/CorrespondingDocumentSection";
import { GeneralInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/GeneralInfoSection";
import { ItemsSummarySection } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/ItemsSummarySection";
import { NotificationSection } from "pages/tradingDocuments/shared/components/rightPanel/notificationSection/NotificationSection";
import { PaymentSection } from "pages/tradingDocuments/shared/components/rightPanel/paymentSection/PaymentSection";
import { RecipientInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/recipientInfoSection/RecipientInfoSection";
import { TitleSection } from "pages/tradingDocuments/shared/components/rightPanel/titleSection/TitleSection";
import { PanelHeader } from "./components/PanelHeader";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { useRef, useState } from "react";
import { RemindersSection } from "../../shared/components/rightPanel/remindersSection/RemindersSection";
import { useEffect } from "react";
import { CurrencyConvertSection } from "./components/currencyConvertSection/CurrencyConvertSection";
import { ViesSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/ViesSection";
import { CorrespondingReceiptSection } from "./components/correspondingReceiptSection/CorrespondingReceiptSection";
import { AssignAccountancyAccount } from "pages/tradingDocuments/shared/components/rightPanel/assignAccountancyAccount/AssignAccountancyAccount";

interface Props {
  close: () => void;
  showLoader: boolean;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RightPanel = ({ close, showLoader, setShowLoader }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const { data: salesInvoice, error, isLoading } = useTradingDocument(
    { id: panelId },
    { enabled: Boolean(panelId) },
  );

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!salesInvoice) return null;

  return (
    <DrawerRightPanel key={salesInvoice.id}>
      <PanelHeader
        close={close}
        isScrolledOver={isScrolledOver}
        showLoader={showLoader}
        setShowLoader={setShowLoader}
      />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody myRef={clearCommentsRef} setIsScrolledOver={setIsScrolledOver}>
          <TitleSection tradingDocument={salesInvoice} />
          {salesInvoice.correspondingReceipt && (
            <CorrespondingReceiptSection salesInvoice={salesInvoice} />
          )}
          <GeneralInfoSection tradingDocument={salesInvoice} />
          <AmountSection tradingDocument={salesInvoice} />
          <ViesSection tradingDocument={salesInvoice} />
          {salesInvoice.items.length > 0 && <ItemsSummarySection tradingDocument={salesInvoice} />}
          {salesInvoice.currency !== "PLN" && (
            <CurrencyConvertSection salesInvoice={salesInvoice} />
          )}
          <PaymentSection tradingDocument={salesInvoice} />
          <NotificationSection tradingDocument={salesInvoice} />
          {salesInvoice.corrections.length > 0 && (
            <CorrectionSection tradingDocument={salesInvoice} />
          )}
          {salesInvoice.correspondingDocuments.length > 0 && (
            <CorrespondingDocumentSection tradingDocument={salesInvoice} />
          )}
          <RemindersSection tradingDocument={salesInvoice} />
          <AssignAccountancyAccount tradingDocument={salesInvoice} />
          <RecipientInfoSection tradingDocument={salesInvoice} />
        </RightPanelBody>
        <CommentsGenericSection
          outsideRef={clearCommentsRef}
          paramName="tradingDocument"
          endpointUrl="/finances/comments/items"
          commentedObject={salesInvoice}
        />
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
