export const convertMinutesToHoursAndMinutes = (minutes: string | number): string => {
  const min = Number(minutes);
  const hours = Math.floor(min / 60);
  const remainingMinutes = min % 60;

  let result = "";

  if (hours > 0) {
    result += `${hours}h `;
  }

  if (remainingMinutes > 0) {
    result += `${remainingMinutes}min`;
  }

  return result;
};
