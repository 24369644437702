import { Button } from "components/common";
import { moduleConfig } from "components/common/moduleNavigation/moduleConfig";
import { PopoverMenu } from "components/utils/Popover";
import modulesMenuIcon from "assets/images/colorApps.svg";
import styles from "../PageHeader.module.css";
import cx from "classnames";
import { useRedux, useSelector } from "hooks";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import { useState } from "react";
import { useMutation } from "hooks/useMutation";
import { arrangeProfileModule } from "api/profiles/calls";
import { getAnyErrorKey } from "utilities";
import { UserModule } from "ducks/auth";
import { useHistory } from "react-router";

export const ModuleMenuDropdown = () => {
  const [dispatch, { auth }] = useRedux();
  const me = useSelector(store => store.auth.user!);

  const changeOrder = useMutation(arrangeProfileModule, ({ toastr }) => ({
    onMutate: variables => {
      dispatch(auth.saveUser({ ...me, modules: variables.modules }));
      return variables.modules;
    },
    onSuccess: payload => {
      dispatch(auth.saveUser({ ...me, modules: payload.data }));
    },
    onError: (error, variables, context) => {
      dispatch(auth.saveUser({ ...me, modules: context as UserModule[] }));

      toastr.open({
        type: "warning",
        title: "Oj, coś nie tak...",
        text: getAnyErrorKey(error),
      });
    },
  }));

  function onChange(sourceId: string, sourceIndex: number, targetIndex: number, targetId?: string) {
    const module = me.modules[sourceIndex];
    if (!module) return;
    const nextState = swap(me.modules, sourceIndex, targetIndex);
    changeOrder.mutate({ moduleId: module.id, position: targetIndex, modules: nextState });
  }

  const rowHeight = 98;
  return (
    <PopoverMenu
      target={btnProps => (
        <div role="button" {...btnProps}>
          <Button kind="transparent-black" size="square-m">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Lista modułów"
                src={modulesMenuIcon}
                style={{ height: "21px", width: "21px" }}
              />
            </div>
          </Button>
        </div>
      )}
    >
      {() => (
        <div className={styles.appLinkList}>
          <div className={styles.appLinkSection}>
            <GridContextProvider onChange={onChange}>
              <GridDropZone
                id="items"
                boxesPerRow={3}
                rowHeight={rowHeight}
                style={{ height: rowHeight * 4 }}
              >
                {me.modules.map(module => (
                  <ModuleItem key={module.id} module={module} />
                ))}
              </GridDropZone>
            </GridContextProvider>
          </div>
        </div>
      )}
    </PopoverMenu>
  );
};

const ModuleItem = ({ module }: { module: UserModule }) => {
  const [position, setPosition] = useState(0);
  const history = useHistory();

  const correspondingModule = Object.values(moduleConfig).find(
    config => config.name === module.name,
  );

  return (
    <GridItem
      onMouseDownCapture={e => {
        setPosition(e.screenX + e.screenY);
      }}
      onMouseUp={e => {
        const currentPosition = e.screenX + e.screenY;
        if (position === currentPosition && correspondingModule) {
          history.push(correspondingModule.url);
        }
      }}
      className={cx(styles.appLink, { [styles.disabledAppLink]: !module.hasAccess })}
    >
      <img
        className={styles.selector}
        alt="Ikona modułu"
        src={correspondingModule?.menuIcon || ""}
      />
      <span>{module.verboseName}</span>
    </GridItem>
  );
};
