
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";

interface UserShortType {
  avatar?: string | null;
  firstName: string;
  lastName: string;
}

interface Props {
  user: UserShortType;
}

export const AvatarWithName = ({ user }: Props) => {
  if (!user) return null;
  return (
    <div className="d-flex align-items-center gap-2">
      <AvatarOrInitials
        avatarSrc={user.avatar}
        firstName={user.firstName}
        lastName={user.lastName}
        className="avatar avatarSmall m-0 "
      />
      <div className="body14">
        {user.firstName} {user.lastName}
      </div>
    </div>
  );
};
