import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import routeIcon from "assets/images/route.svg";
import { Routes } from "routes/logistics/Routes";

export const routes: ModuleLink = {
  url: "routes",
  label: "Trasy",
  icon: routeIcon,
  subSection: <></>,
  routing: Routes,
};
