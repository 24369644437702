import React from "react";

export const handleRightPanelScroll = (
  setIsScrolledOver: React.Dispatch<React.SetStateAction<boolean>>,
  scrollRef: React.RefObject<HTMLDivElement> | undefined,
) => {
  const scrollPosition = scrollRef?.current?.scrollTop;

  if (scrollPosition && scrollPosition > 40) {
    setIsScrolledOver(true);
  } else {
    setIsScrolledOver(false);
  }
};
