import styles from "./InfoLabel.module.css";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  title: string;
  titleStyle?: string;
}

export const InfoLabel = ({ children, className, title, titleStyle }: Props) => {
  return (
    <div className={cx("d-flex align-items-center gap-2 pb-2", className)}>
      <div className={cx(styles.title, titleStyle)}>{title}:</div>
      {children}
    </div>
  );
};
