import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import cut from "assets/images/moduleNavigationIcons/cut.svg";
import { Dashboard } from "routes/manufacturingNew/Dashboard";

export const tailoringOrders: ModuleLink = {
  url: "tailoring-orders",
  label: "Zlecenia krawieckie",
  icon: cut,
  subSection: <></>,
  routing: Dashboard,
};
