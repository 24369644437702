import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  tradingDocument: TradingDocument;
}

export const RecipientInfoSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Nabywca">
      <div className="pb-3">
        {tradingDocument.recipientCompanyName && (
          <div className="body-18 pb-1">{tradingDocument.recipientCompanyName}</div>
        )}
        {tradingDocument.recipientStreet && (
          <div className="body-14-500 pb-1">{tradingDocument.recipientStreet}</div>
        )}
        {(tradingDocument.recipientPostCode || tradingDocument.recipientCity) && (
          <div className="body-14-500 pb-1">
            {tradingDocument.recipientPostCode}&nbsp;{tradingDocument.recipientCity}
          </div>
        )}
        {tradingDocument.recipientBankAccountNumber && (
          <div className="body-14-500 pb-1">{tradingDocument.recipientBankAccountNumber}</div>
        )}
        {tradingDocument.recipientTaxId && (
          <div className="body-14-500 pb-1">NIP:&nbsp;{tradingDocument.recipientTaxId}</div>
        )}
      </div>
    </RightPanelSection>
  );
};
