import styles from "./Attributes.module.css";

export const Attributes = ({
  attributes,
}: {
  attributes: { name: string; value: string; id: number | string }[];
}) => {
  return (
    <div className={styles.attributesContainer}>
      {attributes.map(attr => (
        <div className={styles.attribute} key={attr.id}>
          <span>{attr.name}: </span>
          <strong>{attr.value}</strong>
        </div>
      ))}
    </div>
  );
};
