import styles from "pages/tradingDocuments/shared/components/rightPanel/remindersSection/RemindersSection.module.css";

type WhenStatus = "BEFORE" | "AFTER" | "AT_DEADLINE_DAY";
type Status = "OPENED" | "SENT" | "DECLINED" | "CLICKED_LINK";

export interface ReminderType {
  action: "STANDARD" | "CORRECTION" | "DUPLICATE" | "CORRECTION_DUPLICATE";
  date: string;
  daysDelta: number;
  emailFrom: string;
  recipient: string;
  status: "OPENED" | "SENT" | "DECLINED" | "CLICKED_LINK";
  when: WhenStatus;
}

export const whenStatusDictionary: Record<WhenStatus, string> = {
  BEFORE: "przed terminem",
  AFTER: "po terminie",
  AT_DEADLINE_DAY: "termin dziś",
};
export const statusDictionary: Record<Status, string> = {
  OPENED: "otwarto",
  SENT: "wysłano",
  DECLINED: "błąd wysyłki",
  CLICKED_LINK: "kliknięto w link",
};
export const statusColorDictionary: Record<Status, string> = {
  OPENED: styles.textBlue,
  SENT: styles.textGreen,
  DECLINED: styles.textRed,
  CLICKED_LINK: styles.textLightBlue,
};
