import { Log } from "api/logbook/models";
import { Button } from "components/common";
import { HistoryLogItem } from "components/common/historyLogItem";
import { Spinner } from "components/utils";
import styles from "./InvoiceHistory.module.css";

interface Props {
  fetchMore: () => void;
  hasMore: boolean;
  inProgress: boolean;
  logs: Log[];
}

export const InvoiceHistory = ({ fetchMore, hasMore, inProgress, logs }: Props) => {
  return (
    <div className={styles.listWrapper}>
      <div className={styles.listGroup}>
        {logs.map(log => (
          <HistoryLogItem key={log.id} log={log} />
        ))}
      </div>
      <div className="d-flex mt-4 mb-3">
        {!inProgress && hasMore && (
          <Button kind="secondary-grey" onClick={fetchMore}>
            Wczytaj więcej
          </Button>
        )}
      </div>
      <div className="justify-content-center d-flex mt-4 mb-3">
        {inProgress && <Spinner color="blue" size="big" text="trwa wczytywanie" />}
      </div>
    </div>
  );
};
