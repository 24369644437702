import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import factory from "assets/images/moduleNavigationIcons/factory.svg";
import { Dashboard } from "routes/manufacturingNew/Dashboard";

export const manufacturers: ModuleLink = {
  url: "manufacturers",
  label: "Producenci",
  icon: factory,
  subSection: <></>,
  routing: Dashboard,
};
