import { useMemo } from "react";
import * as React from "react";
import DatePickerComponent, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import cx from "classnames";
import { Form } from "components/common/form/Form";
import { dateFns } from "utilities";
import styles from "./DatePicker.module.css";
import closeIcon from "assets/images/11.svg";
import calendarIcon from "assets/images/4.svg";
import cuid from "cuid";
import { Spinner } from "../spinner";
import pl from "date-fns/locale/pl"; // the locale you want
registerLocale("pl", pl); // register it with the name you want

interface Props
  extends Pick<
    ReactDatePickerProps,
    "selectsStart" | "startDate" | "selectsEnd" | "maxDate" | "endDate" | "minDate"
  > {
  value: string | Date | null;
  onChange: (arg: Date | null) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  className?: string | undefined;
  disabled?: boolean;
  placeholder?: string;
  inProgress?: boolean;
  label?: string | undefined;
  portalId?: string | undefined;
  customDateFormat?: string | undefined;
  showMonthYearPicker?: boolean;
  meta?: {
    touched?: boolean | undefined;
    error?: string | string[] | undefined | null;
  };
  look?: "common" | "editable";
  overwrites?: {
    popup?: { className?: string };
    container?: { className?: string };
    label?: { className?: string };
  };
  removeDate?: boolean;
  popperPlacement?:
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "top-start"
    | "top-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-start"
    | "left-end";
}

const spinnerStyle: React.CSSProperties = { position: "absolute", right: -18, top: -17 };
export const DatePicker = ({
  label,
  onChange,
  placeholder = "Wybierz datę",
  value,
  meta,
  inProgress = false,
  className = "",
  disabled,
  onBlur,
  onFocus,
  customDateFormat,
  showMonthYearPicker = false,
  overwrites = {},
  look = "editable",
  portalId = "root-portal",
  removeDate = true,
  popperPlacement,
  ...rest
}: Props) => {
  const touched = meta?.touched ?? true;
  const customId = useMemo(() => cuid(), []);
  const selectedDate = value ? new Date(value) : null;
  return (
    <>
      {label ? (
        <Form.SectionLabel
          className={cx(
            "mb-2",
            look === "common" ? styles.commonLabel : "",
            overwrites.label?.className,
          )}
        >
          {label}
        </Form.SectionLabel>
      ) : null}
      <div className={cx(styles.container, overwrites.container?.className || "")}>
        <DatePickerComponent
          popperPlacement={popperPlacement}
          portalId={portalId}
          showPopperArrow={false}
          locale="pl"
          autoComplete="off"
          popperClassName={cx(styles.popper, overwrites.popup?.className || "")}
          onKeyDown={e => e.preventDefault()}
          disabled={disabled}
          className={cx(className, styles.dateInput, look === "common" ? styles.commonInput : "")}
          id={customId}
          dateFormat={customDateFormat || "dd/MM/yyyy"}
          selected={selectedDate}
          wrapperClassName={styles.dateInputWrapper}
          placeholderText={placeholder}
          showWeekNumbers
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showMonthYearPicker={showMonthYearPicker}
          value={
            selectedDate
              ? dateFns.format(selectedDate, customDateFormat || "dd/MM/yyyy")
              : undefined
          }
          {...rest}
        />
        {value && removeDate && (
          <img
            src={closeIcon}
            alt="wyczyść"
            className={cx(styles.closeIcon, { [styles.disabled]: disabled })}
            onClick={() => onChange(null)}
          />
        )}

        {(!value || !removeDate) && (
          <label htmlFor={customId}>
            <img
              src={calendarIcon}
              alt="kalendarz"
              className={cx(styles.calendarIcon, { [styles.disabled]: disabled })}
            />
          </label>
        )}
        <Spinner
          on={inProgress}
          color={{ dark: "blue", light: "white" }["dark"] as "blue" | "white"}
          style={spinnerStyle}
        />
      </div>
      {touched && meta?.error ? (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {meta?.error}
        </div>
      ) : null}
    </>
  );
};
