import styles from "../PaymentSection.module.css";
import openFolderIcon from "assets/images/folderOpen.svg";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { Button } from "components/common";
import cx from "classnames";
import { TradingDocument } from "api/trading-documents/models";
import { useToggle } from "hooks";
import { AddPaymentModal } from "../addPaymentModal/AddPaymentModal";

interface Props {
  tradingDocument: TradingDocument;
}

export const EmptyPaymentSection = ({ tradingDocument }: Props) => {
  const addPaymentModal = useToggle();

  return (
    <div className="pb-3">
      <div className={styles.sectionEmpty}>
        <img alt="Pusty folder" className={styles.icon} src={openFolderIcon} />
        <h5 className={cx(styles.sectionEmptyTitle, "pt-2")}>
          Dokument jeszcze nie zawiera żadnych płatności
        </h5>
        <div className="py-2">
          <Button kind="create" onClick={addPaymentModal.open}>
            <div className="btnBase btnBaseSmall">
              <img alt="Dodaj" src={darkPlusIcon} />
              Dodaj pierwszą płatność
            </div>
          </Button>
        </div>
      </div>
      {addPaymentModal.isOpen && (
        <AddPaymentModal close={addPaymentModal.close} tradingDocument={tradingDocument} />
      )}
    </div>
  );
};
