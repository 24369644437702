import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import personIcon from "assets/images/person4.svg";
import { Unloadings } from "routes/wms/Unloadings";

export const warehousemen: ModuleLink = {
  url: "warehousemen",
  label: "Magazynierzy",
  icon: personIcon,
  subSection: <></>,
  routing: Unloadings,
};
