import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import customersIcon from "assets/images/copyrightWhite.svg";
import packageIcon from "assets/images/package.svg";
import styles from "../RightPanel.module.css";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { useToggle } from "hooks";
import { EditRecipientNamesModal } from "../../editRecipientNamesModal/EditRecipientNamesModal";
import { openEditRecipientNamesModal } from "./utils/openEditRecipientNamesModal";

interface Props {
  editRecipientModalPosition: {
    top: number;
    left: number;
  };
  salesInvoice: TradingDocument;
  setEditRecipientModalPosition: React.Dispatch<
    React.SetStateAction<{ top: number; left: number }>
  >;
  updateTradingDocumentOptions: any;
}

export const CustomerSection = ({
  editRecipientModalPosition,
  salesInvoice,
  setEditRecipientModalPosition,
  updateTradingDocumentOptions,
}: Props) => {
  const editRecipientNamesModal = useToggle();

  if (salesInvoice.kind === "DROPSHIPPING") {
    return (
      <InfoLabel title="nabywca">
        <div className="d-flex align-items-center gap-2">
          <div
            className={cx(styles.filterChip, styles.filterDropshipping)}
            data-tip
            data-for={`dropshipper-${salesInvoice.id}`}
            onClick={e =>
              openEditRecipientNamesModal(
                e,
                setEditRecipientModalPosition,
                editRecipientNamesModal.toggle,
              )
            }
          >
            <img alt="Ikona nabywcy" src={packageIcon} />
            {salesInvoice.recipientCompanyName.length > 0 ? (
              <div>{salesInvoice.recipientCompanyName}</div>
            ) : salesInvoice.recipientFirstName.length > 0 ||
              salesInvoice.recipientLastName.length > 0 ? (
              <div>
                {salesInvoice.recipientFirstName} {salesInvoice.recipientLastName}
              </div>
            ) : (
              "---"
            )}
          </div>
          <div className="body-14-600 d-flex align-items-center">
            <div className="text-grey-35">NIP: &nbsp;</div>
            {salesInvoice.recipientTaxId && salesInvoice.recipientTaxId.length > 0 ? (
              <div>{salesInvoice.recipientTaxId}</div>
            ) : (
              <div>--</div>
            )}
          </div>
        </div>
        <ReactTooltip
          className={styles.customTooltip}
          id={`dropshipper-${salesInvoice.id}`}
          place="top"
          effect="solid"
          arrowColor="transparent"
          offset={{ top: -10 }}
        >
          Dropshipping
        </ReactTooltip>
        {editRecipientNamesModal.isOpen && (
          <EditRecipientNamesModal
            close={editRecipientNamesModal.close}
            modalPosition={editRecipientModalPosition}
            tradingDocument={salesInvoice}
            updateTradingDocumentOptions={updateTradingDocumentOptions}
          />
        )}
      </InfoLabel>
    );
  }
  if (salesInvoice.kind === "WHOLESALE") {
    return (
      <InfoLabel title="nabywca">
        <div className="d-flex align-items-center gap-2">
          <div
            className={cx(styles.filterChip, styles.filterCustomer)}
            data-tip
            data-for={`customer-${salesInvoice.id}`}
            onClick={e =>
              openEditRecipientNamesModal(
                e,
                setEditRecipientModalPosition,
                editRecipientNamesModal.toggle,
              )
            }
          >
            <img alt="Ikona nabywcy" src={customersIcon} />
            {salesInvoice.recipientCompanyName.length > 0 ? (
              <div>{salesInvoice.recipientCompanyName}</div>
            ) : salesInvoice.recipientFirstName.length > 0 ||
              salesInvoice.recipientLastName.length > 0 ? (
              <div>
                {salesInvoice.recipientFirstName} {salesInvoice.recipientLastName}
              </div>
            ) : (
              "---"
            )}
          </div>
          <div className="body-14-600 d-flex align-items-center">
            <div className="text-grey-35">NIP: &nbsp;</div>
            {salesInvoice.recipientTaxId && salesInvoice.recipientTaxId.length > 0 ? (
              <div>{salesInvoice.recipientTaxId}</div>
            ) : (
              <div>--</div>
            )}
          </div>
        </div>
        <ReactTooltip
          className={styles.customTooltip}
          id={`customer-${salesInvoice.id}`}
          place="top"
          effect="solid"
          arrowColor="transparent"
          offset={{ top: -10 }}
        >
          Kontrahent
        </ReactTooltip>
        {editRecipientNamesModal.isOpen && (
          <EditRecipientNamesModal
            close={editRecipientNamesModal.close}
            modalPosition={editRecipientModalPosition}
            tradingDocument={salesInvoice}
            updateTradingDocumentOptions={updateTradingDocumentOptions}
          />
        )}
      </InfoLabel>
    );
  }

  return null;
};
