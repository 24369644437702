import { useTradingDocument } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { PageHeader } from "components/common";
import { useInfiniteLogs } from "hooks/useInfinityLogs";
import { InvoiceHistory } from "pages/tradingDocuments/shared/components/invoiceHistory/InvoiceHistory";
import { useLocation, useParams } from "react-router";

type LocationState = { purchaseInvoice: Pick<TradingDocument, "signature"> } | undefined;

export const ImportPurchaseInvoiceHistory = () => {
  const params = useParams<{ purchaseInvoiceId: UUID }>();
  const location = useLocation<LocationState>();

  const locationPurchaseInvoice = location.state?.purchaseInvoice;
  const { data: fetchedInvoice } = useTradingDocument(
    { id: params.purchaseInvoiceId },
    { enabled: !Boolean(locationPurchaseInvoice) },
  );
  const purchaseInvoice = locationPurchaseInvoice ?? fetchedInvoice;

  const { logs, fetchMore, inProgress, hasMore } = useInfiniteLogs(
    params.purchaseInvoiceId,
    "tradingDocument",
  );

  const subtitle = purchaseInvoice?.signature ?? "";

  return (
    <div className="position-relative">
      <PageHeader
        searchBar={false}
        subtitle={subtitle}
        title="Historia faktury zakupowej"
        viewLabel="PURCHASE_INVOICE_HISTORY"
      />
      <InvoiceHistory fetchMore={fetchMore} hasMore={hasMore} inProgress={inProgress} logs={logs} />
    </div>
  );
};
