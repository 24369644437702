import { DeadlineDayOptions } from "api/milo-settings/models";
import { ToastSubscription } from "components/common";
import { Options } from "components/common/confirmModal/ConfirmModalController";

export type RoutesTab = "new" | "route-ready" | "departed" | "finished" | "settled" | "all";

export interface RouteSimplified {
  id: number;
  signature: string;
}

export const dayOptions: Record<DeadlineDayOptions, string> = {
  TODAY: "Dzisiaj",
  TOMORROW: "Jutro",
  AFTER_TOMORROW: "Pojutrze",
  NEAREST_MONDAY: "Następny poniedziałek",
  NEAREST_FRIDAY: "Następny piątek",
};

export type ConfirmResendingModal = {
  open: ({ text, callback, confirmText, cancelText }: Options) => void;
  close: any;
};

export type SmsModal = {
  isOpen: boolean;
  open: (stateToSet: string) => void;
  close: () => void;
  state: string | null;
};

export type Toastr = {
  open: ({ text, title, type }: Omit<ToastSubscription, "id">) => void;
};
