import { useDashboardLatestSmsMessages } from "api/call-center/routes/hooks";
import { useFilters } from "hooks/useFilters";
import { queryString } from "utilities";
import localStyles from "../../Dashboard.module.css";
import cx from "classnames";
import { CommonError } from "components/utils";
import { Loader } from "components/utils/loader";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { NoResults } from "components/utils/noResults";
import { LatestSmsMessage } from "./components/LatestSmsMessage";

export type LatestSmsMessagesFilters = {
  page: string;
  search: string;
};

const initialFilters: LatestSmsMessagesFilters = {
  page: "1",
  search: "",
};

export const LatestSmsMessagesSection = () => {
  const { setFilter, filters } = useFilters<LatestSmsMessagesFilters>(initialFilters);
  const search = queryString.stringify(filters);

  const {
    data: latestSmsMessages,
    error,
    isFetching,
    isLoading,
    pagination,
  } = useDashboardLatestSmsMessages(search);

  const noResults = !latestSmsMessages.length && !isLoading && !error;

  if (error) {
    return (
      <div className={localStyles.card}>
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={localStyles.card}>
        <Loader module="callCenter" />
      </div>
    );
  }

  return (
    <div className={localStyles.card}>
      {isFetching && <Loader module="callCenter" />}
      <div>
        <div className="d-flex align-items-center justify-content-between gap-1 px-3 pt-3 mb-2">
          <h3 className={localStyles.emptyListTitle}>Ostatnie wiadomości SMS</h3>
          <div className="d-flex align-items-center gap-1">
            {latestSmsMessages.length > 0 ? (
              <InvoicePagination
                hasPadding={false}
                mode="manual"
                onChange={page => {
                  setFilter("page", String(page));
                }}
                page={Number(filters.page)}
                pagination={pagination}
                position="relative"
              />
            ) : (
              <div style={{ height: "42px" }} />
            )}
          </div>
        </div>
        <div className={cx(localStyles.tableHeader, localStyles.tableHeaderBokLatestSmsMessages)}>
          <div>klient</div>
          <div>wiadomość</div>
          <div>status</div>
          <div>trasa</div>
          <div className="d-flex justify-content-end">punkt</div>
        </div>
      </div>
      {!noResults &&
        latestSmsMessages.map(latestSmsMessage => (
          <LatestSmsMessage latestSmsMessage={latestSmsMessage} key={latestSmsMessage.id} />
        ))}
      {noResults && (
        <NoResults
          on={noResults}
          overwrites={{
            background: {
              className: localStyles.noResultsOverwrite,
            },
          }}
          subHeader="wiadomości SMS"
        />
      )}
    </div>
  );
};
