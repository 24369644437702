import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import routeIcon from "assets/images/route.svg";
import { CallCenterRoutes } from "routes/CallCenterRoutes";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { FilterType } from "components/common/filters/types";
import { RoutePriority, routeStatusesOptions } from "api/routes/models";
import { createDepartureDateFilterOptions } from "utilities/createDepartureDateFilterOptions";
import { ColorLabel } from "components/common/colorLabel";
import { TrackingStatus } from "api/call-center/routes/models";

export const trackingStatusOptions: {
  label: string;
  value: TrackingStatus;
  content: JSX.Element;
}[] = [
  {
    label: "at risk",
    value: "AT_RISK",
    content: <ColorLabel className="mr-1" color={"#e4b600"} height={10} width={10} />,
  },
  {
    label: "off track",
    value: "OFF_TRACK",

    content: <ColorLabel className="mr-1" color={"#e26b77"} height={10} width={10} />,
  },
  {
    label: "on track",
    value: "ON_TRACK",
    content: <ColorLabel className="mr-1" color={"#6de5b9"} height={10} width={10} />,
  },
];

export const routePriorityOptions: {
  label: string;
  value: RoutePriority;
  content: JSX.Element;
}[] = [
  {
    label: "A",
    value: "HIGH",
    content: <ColorLabel className="mr-1" color={"#e26b77"} height={10} width={10} />,
  },
  {
    label: "B",
    value: "MEDIUM",
    content: <ColorLabel className="mr-1" color={"#ee9e4b"} height={10} width={10} />,
  },
  {
    label: "C",
    value: "LOW",
    content: <ColorLabel className="mr-1" color={"#e4b700"} height={10} width={10} />,
  },
];

export const routes: ModuleLink = {
  url: "routes",
  label: "Trasy",
  icon: routeIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.Select,
          label: "sortuj",
          name: "ordering",
          options: [
            { label: "Ostatnio edytowana", value: "-editedAt" },
            { label: "Ostatnio utworzona", value: "-created" },
          ],
        },
        {
          type: FilterType.SelectUser,
          label: "kierowca",
          multiple: true,
          name: "drivers",
          searchBy: "drivers",
        },
        {
          type: FilterType.SelectUser,
          kind: "car",
          label: "samochód",
          multiple: true,
          name: "cars",
          searchBy: "users",
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "wyjazd",
          multiple: true,
          name: "departureDate",
          options: createDepartureDateFilterOptions(),
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "status śledzenia",
          multiple: true,
          name: "trackingStatuses",
          options: trackingStatusOptions,
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "status trasy",
          multiple: true,
          name: "statuses",
          options: routeStatusesOptions,
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "priorytet",
          multiple: true,
          name: "priorities",
          options: routePriorityOptions,
        },
        {
          type: FilterType.SelectUser,
          label: "odpowiedzialny",
          multiple: true,
          name: "responsible",
          searchBy: "users",
        },
      ]}
    />
  ),
  routing: CallCenterRoutes,
};
