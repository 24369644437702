import calculateIcon from "assets/images/calculatePink.svg";
import inventoryIcon from "assets/images/products.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";

export const inventory: ModuleNavigation = {
  labelBackground: "#b65eff",
  label: "Produkty",
  iconBackground: "#431d87",
  icon: calculateIcon,
  menuIcon: inventoryIcon,
  name: "INVENTORY",
  url: "/inventory",
  navigationSections: [{ navigationList: [] }],
};
