import styles from "../RemindersSection.module.css";
import folderIcon from "assets/images/folder_open.svg";

export const EmptyRemindersSection = () => {
  return (
    <div className="pb-3">
      <div className={styles.sectionEmpty}>
        <img className={styles.icon} src={folderIcon} alt="Pusty folder" />
        <h5 className="pt-2">Jeszcze nie wysłano żadnego przypomnienia</h5>
      </div>
    </div>
  );
};
