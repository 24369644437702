import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import {
  getAssignedWhEntries,
  getCurrentlyPrintedReceipts,
  getInvoiceAdmission,
  getInvoiceAdmissionLinkLines,
  getLastPrintedReceipts,
  getMyInvoicesToReview,
  getPurchaseInvoicesToReview,
  getSummaryPerInPackage,
  getSummaryPerOutPackage,
  getTradingDocument,
  getTradingDocuments,
  getWarehousePackagesInOrOutSummary,
  getWarehouseTotalValueSummary,
  getWhEntriesAggregates,
  patchTradingDocument,
} from "./calls";
import { tradingDocumentsKeys } from "./keys";

export const useTradingDocuments = createPaginatedApiQuery(getTradingDocuments);
export const useTradingDocument = createApiQuery(getTradingDocument);

export const usePurchaseInvoicesToReview = createPaginatedApiQuery(getPurchaseInvoicesToReview);
export const useMyPurchaseInvoicesToReview = createPaginatedApiQuery(getMyInvoicesToReview);

export const useInvoiceAdmission = createApiQuery(getInvoiceAdmission);
export const useInvoiceAdmissionLinkLines = createPaginatedApiQuery(getInvoiceAdmissionLinkLines);

export const useWhEntryAggregates = createPaginatedApiQuery(getWhEntriesAggregates);
export const useAssignedWhEntries = createPaginatedApiQuery(getAssignedWhEntries);

export const useCurrentlyPrintedReceipts = createPaginatedApiQuery(getCurrentlyPrintedReceipts);
export const useLastPrintedReceipts = createPaginatedApiQuery(getLastPrintedReceipts);

export const useWarehouseTotalValueSummary = createApiQuery(getWarehouseTotalValueSummary);
export const usePackagesInOrOutSummary = createPaginatedApiQuery(
  getWarehousePackagesInOrOutSummary,
);
export const useSummaryPerInPackage = createApiQuery(getSummaryPerInPackage);
export const useSummaryPerOutPackage = createApiQuery(getSummaryPerOutPackage);

export const useUpdateTradingDocumentOptionsForPurchaseImport = () => {
  return useMutation(patchTradingDocument, ({ queryUtils }) => ({
    onMutate: toUpdate => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        tradingDocumentsKeys.tradingDocument.list(),
        toUpdate.id,
        toUpdate,
      );

      const purchaseImportList = queryUtils.handlePaginatedListUpdate(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(),
        toUpdate.id,
        toUpdate,
      );

      return { prevList, purchaseImportList };
    },
    onError: (error, { id }, context) => {
      const { prevList, purchaseImportList } = context as {
        prevList: any;
        purchaseImportList: any;
      };
      queryUtils.rollbackList(tradingDocumentsKeys.tradingDocument.list(), prevList, id);

      queryUtils.rollbackList(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(),
        purchaseImportList,
        id,
        error,
      );
    },
  }));
};
