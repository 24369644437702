import { Button, Header as CommonHeader, Toolbar } from "components/common";
import listEmptyIcon from "assets/images/el2.png";
import { Cell, CommonError, Label, List, Pagination, Row, Spinner } from "components/utils";
import { Link, useHistory } from "react-router-dom";
import plusIcon from "assets/images/20c.svg";
import arrowIcon from "assets/images/526p.svg";
import { useBankAccounts } from "api/bank-accounts/hooks";
import { NoContent } from "components/utils/noContent";
import styles from "./BankAccountList.module.css";
import cx from "classnames";
import { differenceInHours } from "date-fns";
import { CURRENCY } from "CONSTANTS";
import { useQuery, useSelector } from "hooks";
import { bankAccounts } from "components/common/moduleNavigation/moduleConfig/accountancy/routes/bankAccounts";

export const BankAccountsList = () => {
  const { search } = useQuery();
  const { data, pagination, isLoading, error } = useBankAccounts(search);
  const history = useHistory();

  if (error) {
    return (
      <div className="h-100">
        <Header />
        <CommonError status={error._httpStatus_} />
      </div>
    );
  }

  if (!data || isLoading) {
    return (
      <div className="h-100">
        <Header />
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div>
        <Header />
        <NoContent header="Lista nie zawiera elementów" img={listEmptyIcon} />;
      </div>
    );
  }

  return (
    <div className="position-relative">
      <Header />

      <List variant="compact">
        {data.map(bankAccount => {
          const isNew = isBankAccountNew(bankAccount.created);
          return (
            <Row key={bankAccount.id} className={cx(styles.bankAccount, { "bg-white": isNew })}>
              <Cell className={cx(styles.imageCell, "mr-3")}>
                <img src={bankAccount.logo} alt="logo" />
              </Cell>
              <Cell className={styles.nameCell}>
                <div>
                  <div className={styles.product}>
                    <div className={cx(styles.iban, "mb-1")}>
                      <div className="mr-2">{bankAccount.iban}</div>{" "}
                      {bankAccount.isActive && (
                        <Label color="primary" className="mr-2">
                          Aktywne
                        </Label>
                      )}{" "}
                      {isNew && <Label color="blue">Nowe</Label>}
                    </div>
                    <div
                      className={cx(styles.details, {
                        "text-color-black": isNew,
                      })}
                    >
                      <div className="mb-1">{bankAccount.name}</div>
                      <div>{bankAccount.currency}</div>
                    </div>
                  </div>
                </div>
              </Cell>
              <Cell>
                <Button
                  size="small"
                  kind="secondary-stroke"
                  onClick={() =>
                    history.push(
                      "/bank-accounts/transactions/list/in?bankAccount=" + bankAccount.id,
                    )
                  }
                >
                  lista transakcji <img src={arrowIcon} alt="arrow" className="ml-1" />
                </Button>
              </Cell>
            </Row>
          );
        })}
        <Pagination pagination={pagination} />
      </List>
    </div>
  );
};

const Header = () => {
  const banks = useSelector(state => state.partials.banks);

  const currencyList = CURRENCY.map(({ id, name }) => ({ label: name, value: id }));
  const bankList = banks.map(({ id, name }) => ({ label: name, value: String(id) }));
  return (
    <div className={styles.headerWrapper}>
      <CommonHeader
        title={({ styles: s }) => (
          <div className="d-flex align-items-center">
            <h1 className={s.title}>Konta bankowe</h1>
            <div className="d-flex align-items-baseline ml-3">
              <Button
                kind="secondary-stroke"
                size="small"
                as={Link}
                to="/bank-accounts/create"
                className="mr-2"
              >
                <img src={plusIcon} alt="" className="mr-1" />
                Dodaj konto bankowe
              </Button>
            </div>
          </div>
        )}
        routeRoot={`finances/${bankAccounts.url}`}
      />
      <Toolbar
        searchInput
        filters={[
          {
            name: "ordering",
            label: "sortuj",
            type: "string",
            options: [
              { label: "Od najstarszych", value: "id" },
              { label: "Od najnowszych", value: "" },
            ],
            default: "",
          },
          {
            name: "bank",
            label: "bank",
            type: "string",
            options: [{ label: "Wszystkie", value: "" }, ...bankList],
            default: "",
          },
          {
            name: "currency",
            label: "waluta",
            keepTextFormatting: true,
            type: "string",
            options: [{ label: "Wszystkie", value: "" }, ...currencyList],
            default: "",
          },
        ]}
      />
    </div>
  );
};

function isBankAccountNew(created: string) {
  return differenceInHours(new Date(), new Date(created)) < 6;
}
