import styles from "../CommentsGenericSection.module.css";
import { Comment } from "./Comment";
import cx from "classnames";
import history from "assets/images/history.svg";
import { User } from "api/users/models";
import React from "react";
import { CommentType } from "api/comments/models";

interface Props {
  comments: CommentType[];
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  userLoggedIn: User;
  getUrlWithParams: string;
  isLoading: boolean;
  endpointUrl: string;
}
export const CommentsList = ({
  comments,
  pageSize,
  setPageSize,
  userLoggedIn,
  getUrlWithParams,
  isLoading,
  endpointUrl,
}: Props) => {
  const commentsList = comments.filter(
    comment => comment.user.id === userLoggedIn.id || comment.isOnlyVisibleForTeam,
  );

  return (
    <div className={styles.commentsList}>
      {commentsList.map((comment: CommentType) => (
        <Comment
          {...comment}
          userLoggedIn={userLoggedIn}
          pageSize={pageSize}
          getUrlWithParams={getUrlWithParams}
          isLoading={isLoading}
          key={comment.id}
          endpointUrl={endpointUrl}
        />
      ))}
      <div className="d-flex justify-content-between gap-2 px-3 py-2">
        <button
          className={cx(
            styles.btnSizeSmall,
            styles.nonfocusable,
            styles.btnBgTransparentTextPurple,
            "btn uppercase",
          )}
        >
          <div
            onClick={() => setPageSize(pageSize + 3)}
            className={cx(styles.btnBase, styles.btnBaseSmall, styles.uppercase)}
          >
            <img src={history} alt="Pokaż wcześniejsze komentarze" />
            Pokaż wcześniejsze
          </div>
        </button>
      </div>
    </div>
  );
};
