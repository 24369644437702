import * as React from "react";
import { useField, FieldMetaProps } from "formik";
import styles from "./FormInput.module.css";
import cx from "classnames";
import { useRefFn } from "hooks/useRefFn";
import cuid from "cuid";

interface Props {
  label?: string;
  name: string;
  type?: string;
  className?: string;
  autoFocus?: boolean;
  minimum?: number;
  onBlur?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  isValidated?: boolean;
  overwrites?: {
    wrapper?: {
      className?: string;
      childrenAfter?:
        | React.ReactNode
        | ((props: { id: string; meta: FieldMetaProps<any> }) => React.ReactNode);
    };
    input?: { className?: string };
    floatingLabel?: { className?: string };
  };
}

export const FormInput: React.FC<Props> = ({
  label,
  children,
  type,
  minimum,
  className = "",
  autoFocus = false,
  onChange,
  onBlur,
  autoComplete,
  isValidated = true,
  overwrites = {},
  ...props
}) => {
  const [field, meta] = useField(props);
  const id = useRefFn(() => cuid()).current;

  return (
    <div className={cx(styles.inputWrapper, className, overwrites.wrapper?.className ?? "")}>
      <label className={styles.inputBox}>
        <input
          id={id}
          min={minimum}
          className={cx(styles.input, overwrites.input?.className ?? "")}
          autoFocus={autoFocus}
          {...field}
          onChange={e => {
            field.onChange(e);
            onChange?.(e);
          }}
          onBlur={e => {
            field.onBlur(e);
            onBlur?.(e);
          }}
          type={type}
          autoComplete={autoComplete}
        />
        <label className={cx(styles.labelFloating, overwrites.floatingLabel?.className ?? "")}>
          {label}
        </label>
      </label>
      {renderNode(overwrites.wrapper?.childrenAfter, { id, meta })}
      {isValidated && meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

const renderNode = (element: React.ReactNode, props: { id: string; meta: FieldMetaProps<any> }) => {
  if (!element) return null;
  if (typeof element === "function") return element(props);
  return element;
};
