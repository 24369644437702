export interface FilterSelectType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Select;
  options: { label: string; value: string; icon?: string; content?: React.ReactNode }[];
  kind?: "checkbox" | "label";
  multiple?: boolean;
}

export interface FilterSearchType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Search;
  searchBy: "salesAccounts" | "customers" | "users" | "businessEntities";
  multiple?: boolean;
}

export interface FilterSelectUserType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.SelectUser;
  kind?: "user" | "car";
  searchBy: "standardUsers" | "drivers" | "users";
  multiple?: boolean;
}

export interface FilterDateRangeType<T = undefined> extends Omit<FilterDefinition<T>, "name"> {
  type: FilterType.DateRange;
  name: [string, string];
  showMonths?: boolean;
  additionalKeys?: [string, string];
}

export interface FilterTextType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Text;
  placeholder?: string;
}
export interface FilterDateType<T = undefined> extends FilterDefinition<T> {
  type: FilterType.Date;
}

interface FilterDefinition<T> {
  name: T extends undefined ? string : T;
  label: string;
}

export enum FilterType {
  Select = "select",
  SelectUser = "selectUser",
  Search = "search",
  DateRange = "dateRange",
  Date = "date",
  Text = "text",
}

export type AvailableFilters<T> =
  | FilterSelectType<T>
  | FilterSelectUserType<T>
  | FilterDateRangeType<T>
  | FilterDateType<T>
  | FilterSearchType<T>
  | FilterTextType<T>;
