import { Modal } from "components/utils";
import { Button } from "components/common";
import closeIcon from "assets/images/x.svg";
import arrowLeft from "assets/images/keyboardArrowLeft.svg";
import arrowRight from "assets/images/keyboardArrowRight.svg";
import arrowOutward from "assets/images/arrowOutward.svg";
import styles from "./SharedStyles.module.css";
import messageStyles from "./messagesWithCustomer/MessagesWithCustomer.module.css";
import { AvatarWithName } from "components/common/avatarWithName";
import { useMutation } from "hooks/useMutation";
import { useEffect } from "react";
import { patchMessage } from "api/messages/calls";
import { useQueryClient } from "react-query";
import { callCenterRoutesKeys } from "api/call-center/routes/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Pagination } from "api/types";
import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import cx from "classnames";
import { useOrderMessages } from "hooks/apiPrimitives";

interface ModalProps {
  isOpen: boolean;
  close: () => void;
  search: string;
  orderId: number;
}

export const UnreadSmsModal = ({ isOpen, close, search, orderId }: ModalProps) => {
  const { data } = useOrderMessages(String(orderId));

  const queryClient = useQueryClient();
  const patchMessageMutation = useMutation(patchMessage);
  const listKey = callCenterRoutesKeys.routeOrder.list(search);

  useEffect(() => {
    const unreadMessagesHandler = setTimeout(() => {
      unreadMessagesIdArray.forEach(messageId => {
        patchMessageMutation.mutate({ id: String(messageId), data: { isRead: true } });
      });
      queryClient.setQueryData<Pagination<CallCenterRouteOrderListItem>>(listKey, currentList => {
        assertIsDefined(currentList);
        return {
          ...currentList,
          results: currentList.results.map(result => {
            if (result.id === data?.order.id) {
              result.hasUnreadMessages = false;
            }
            return result;
          }),
        };
      });
    }, 5000);

    return () => {
      clearTimeout(unreadMessagesHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) return null;
  const unreadMessagesArray = data.messages.filter(
    message => message.isRead === false && message.wayOfCommunication === "INCOMING",
  );
  const unreadMessagesIdArray = unreadMessagesArray.map(message => message.id);

  return (
    <Modal
      overrides={{ container: { className: styles.modalContainer } }}
      isOpen={isOpen}
      close={close}
      showCloseBtn={false}
    >
      <>
        <div className="d-flex align-items-center justify-content-between p-3">
          <h2>Nieodczytane wiadomości od:</h2>
          <Button onClick={close} kind="transparent" size="square-xs">
            <div className="btnBase btnBase16 btnBaseSmall">
              <img src={closeIcon} alt="" />
            </div>
          </Button>
        </div>
        <div className="px-3 pb-2">
          <div className="d-flex align-items-center gap-2 pb-2">
            <div className="d-flex align-items-center gap-2">
              <AvatarWithName
                user={{
                  firstName: data.client.firstName,
                  lastName: data.client.lastName,
                  avatar: data.client.avatar,
                }}
              />
            </div>
            <div className="body-14-600">{data.order.signature}</div>
            <div className=" fs-12 text-black-6">BRAK DATY (2 dni temu)</div>
          </div>
          <div className="pb-2">
            <div className="d-flex flex-column w-100 align-items-start">
              {unreadMessagesArray.map(message => (
                <div className={cx(messageStyles.cloud, messageStyles.cloudClient)}>
                  {message.body}
                </div>
              ))}
            </div>

            <div className="pt-3">
              <Button size="btnSizeSmall" className="btn" kind="btnBgBlack5">
                <div className="uppercase btnBase btnBase16 btnBaseSmall">
                  Pokaż całą konwersację
                  <img src={arrowOutward} alt="" />
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between p-3 borderTop">
          <Button size="medium" kind="transparent-black" onClick={close}>
            <div className="btnBase btnBaseMedium">ZAMKNIJ</div>
          </Button>

          <div className=" d-flex align-items-center gap-3">
            <Button size="btnSizeSmall" kind="btnBgBlack5">
              <div className="uppercase btnBase btnBase16 btnBaseSmall">
                <img src={arrowLeft} alt="" />
                Poprzednie
              </div>
            </Button>

            <Button size="btnSizeSmall" kind="btnBgBlack5">
              <div className="uppercase btnBase btnBase16 btnBaseSmall">
                Następne
                <img src={arrowRight} alt="" />
              </div>
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};
