import calculateIcon from "assets/images/calculatePink.svg";
import accountancyIcon from "assets/images/rules.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";

export const rules: ModuleNavigation = {
  labelBackground: "#b65eff",
  label: "Reguły",
  iconBackground: "#431d87",
  icon: calculateIcon,
  menuIcon: accountancyIcon,
  name: "RULES",
  url: "/rules",
  navigationSections: [{ navigationList: [] }],
};
