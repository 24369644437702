import {
  getWhEntriesAggregatesForSearchBar,
  postWhEntryAggregate,
} from "api/trading-documents/calls";
import { WhEntryAggregate } from "api/trading-documents/models";
import { Autocomplete, AutocompleteAsyncHandler } from "components/utils";
import addIcon from "assets/images/add.svg";
import { memo } from "react";
import localStyles from "./SearchBar.module.css";
import styles from "../../../../ConnectInvoice.module.css";
import cx from "classnames";
import { Button } from "components/common";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { dateFns, getAnyErrorKey, noop } from "utilities";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { PingLabel } from "components/common/pingLabel";
import React from "react";

interface Props {
  areReviewersEmpty: boolean;
  assignedSearch: string;
  invoiceAdmissionLink: UUID;
}

export const SearchBar = memo(
  ({ areReviewersEmpty, assignedSearch, invoiceAdmissionLink }: Props) => {
    const assignWhEntryOptions = useMutation(postWhEntryAggregate, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(
          tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(assignedSearch),
        );
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Przypisano przyjęcie",
        });
      },
      onError: error => {
        toastr.open({ type: "warning", title: "Wymagane działanie", text: getAnyErrorKey(error) });
      },
    }));

    return (
      <div
        className={cx("p-3", {
          [localStyles.searchBarPositionWithoutReviewers]: areReviewersEmpty,
        })}
      >
        <AutocompleteAsyncHandler
          debounce={1000}
          fetchFrom={getWhEntriesAggregatesForSearchBar}
          transformQuery={query => ({
            ...query,
            pageSize: "999",
            excludeInvoiceAdmissionLink: invoiceAdmissionLink,
          })}
          transform={(whEntry: WhEntryAggregate) => ({
            id: whEntry.id,
            name: (
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <div className="body-16">{whEntry.signature}</div>
                    <div className="fs-10 text-black-6 overflow">
                      {whEntry.manufacturers.map(manufacturer => (
                        <React.Fragment key={manufacturer.id}>
                          {manufacturer.manufacturer.name}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  <span className="separator" />
                  <div className={styles.date}>
                    {dateFns.format(new Date(whEntry.whEntryCreatedAt), "dd.MM.yyyy")}
                  </div>
                  <PingLabel
                    kind={
                      whEntry.status === "EMPTY"
                        ? "OFF_TRACK"
                        : whEntry.status === "UNUSED"
                        ? "ON_TRACK"
                        : "AT_RISK"
                    }
                  />
                </div>
                <div className="d-flex align-items-center gap-2">
                  <Button
                    className={localStyles.searchBtnHeight}
                    kind="create"
                    onClick={e => {
                      e.stopPropagation();
                      assignWhEntryOptions.mutate({
                        invoiceAdmissionLink: invoiceAdmissionLink,
                        whEntryAggregate: whEntry.id,
                      });
                    }}
                  >
                    <div className="btnBase btnBaseSmall">
                      <img alt="Plus" src={addIcon} style={{ height: "16px", width: "16px" }} />
                      Dodaj
                    </div>
                  </Button>
                </div>
              </div>
            ),
          })}
        >
          <Autocomplete
            isConnectingInvoice={true}
            multiple={false}
            onChange={noop}
            orientation="top"
            overrides={{
              searchBar: { className: localStyles.search },
              list: { className: cx(localStyles.searchList, localStyles.searchListOpentToTop) },
              item: { className: cx(localStyles.searchResult, "px-3 py-2") },
            }}
            placeholder="Szukaj przyjęć..."
            selectedItems={[]}
          />
        </AutocompleteAsyncHandler>
      </div>
    );
  },
);
