import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { areConfirmedHoursInCollectionRange } from "pages/routes/creator/leftPanel/pointsList/utils/areConfirmedHoursInCollectionRange";
import likeIcon from "assets/images/greenThumbUp.svg";
import styles from "../../shared/CallCenter.module.css";
import cx from "classnames";
import { useState } from "react";

interface Props {
  index: number;
  order: CallCenterRouteOrderListItem;
}

export const ExpectedDelivery = ({ index, order }: Props) => {
  const [showHintbox, setShowHintbox] = useState(false);

  const isConfirmedWithinRange = areConfirmedHoursInCollectionRange(
    order.delivery.maximumCollectionHour,
    order.delivery.minimumCollectionHour,
    order.maximumCollectionHourAtConfirmation,
    order.minimumCollectionHourAtConfirmation,
  );

  if (isConfirmedWithinRange === false) {
    return (
      <div
        className="position-relative d-flex align-items-center gap-2"
        onPointerOver={() => setShowHintbox(true)}
        onPointerLeave={() => setShowHintbox(false)}
      >
        <div className={cx(styles.negativeCollectionHours, "text-red-4 fw-700")}>
          {order.delivery.minimumCollectionHour}&nbsp;-&nbsp;
          {order.delivery.maximumCollectionHour}&nbsp;
        </div>
        {showHintbox && <div className={styles.hintbox}>Potwierdzono</div>}
        <div className="position-relative d-flex align-items-center gap-1">
          <div className="fw-700">
            {order.minimumCollectionHourAtConfirmation} -{" "}
            {order.maximumCollectionHourAtConfirmation}{" "}
          </div>
          <div>
            <img
              alt="potwierdzono"
              src={likeIcon}
              style={{ paddingBottom: "2px", width: "12px" }}
            />
          </div>
        </div>
      </div>
    );
  }

  if (isConfirmedWithinRange === true) {
    return (
      <div
        className="position-relative d-flex align-items-center gap-2"
        onPointerOver={() => setShowHintbox(true)}
        onPointerLeave={() => setShowHintbox(false)}
      >
        <div className={cx(styles.negativeCollectionHours, "fw-700")}>
          {order.delivery.minimumCollectionHour}&nbsp;-&nbsp;
          {order.delivery.maximumCollectionHour}&nbsp;
        </div>
        {showHintbox && <div className={styles.hintbox}>Potwierdzono</div>}
        <div className="position-relative d-flex align-items-center gap-1">
          <div>
            <img
              alt="potwierdzono"
              src={likeIcon}
              style={{ paddingBottom: "2px", width: "12px" }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="position-relative d-flex align-items-center gap-1"
        onPointerOver={() => setShowHintbox(true)}
        onPointerLeave={() => setShowHintbox(false)}
      >
        {order.delivery.minimumCollectionHour && order.delivery.maximumCollectionHour ? (
          <div className={styles.negativeCollectionHours} data-for={String(order.id)}>
            {order.delivery.minimumCollectionHour}&nbsp;-&nbsp;
            {order.delivery.maximumCollectionHour}&nbsp;
          </div>
        ) : (
          "--"
        )}
        {showHintbox && (
          <div
            className={cx(styles.hintbox, {
              [styles.firstItemHintboxPosition]: index === 0,
            })}
          >
            Nie potwierdzono
          </div>
        )}
      </div>
    </>
  );
};
