import { yup } from "utilities";

export const validationSchema = yup.object().shape({
  positions: yup.array().of(
    yup.object().shape({
      amountWithoutTax: yup.number().when("alreadyInvoiced", {
        is: false,
        then: yup
          .number()
          .min(0, "Wartość netto nie może być ujemna")
          .required("Wartość netto jest wymagana"),
        otherwise: yup.number(),
      }),
      quantity: yup.number().when("alreadyInvoiced", {
        is: false,
        then: yup
          .number()
          .min(0, "Liczba nie może być ujemna")
          .required("Liczba jest wymagana"),
        otherwise: yup.number(),
      }),
    }),
  ),
});
