import styles from "./Login.module.css";
import { Field, FormikHelpers } from "formik";
import { getUserInfo, obtainToken } from "api/users/calls";
import { useHistory, useRouteMatch } from "react-router";
import { useRedux } from "hooks";
import cx from "classnames";
import { Button } from "components/common";
import { Link } from "react-router-dom";
import mainImg from "assets/images/route.jpg";
import manufacturingImg from "assets/images/manufacturing.jpg";
import logoImg from "assets/images/logo.svg";
import kivyImg from "assets/images/kivy.png";
import { ErrorMessage, FormWizard } from "components/utils";
import arrowImg from "assets/images/50.svg";
import { getAnyErrorKey } from "utilities";
import { FLAVOR } from "CONSTANTS";
import { moduleConfig } from "components/common/moduleNavigation/moduleConfig";

interface Values {
  phone: string;
  password: string;
}

/**
 * Validators for each form page
 */
const validators = {
  0: (values: Values) => {
    const errors: Partial<{ [K in keyof Values]: string }> = {};
    if (!values.phone) {
      errors.phone = "Wpisz numer telefonu";
    }
    if (values.phone.match(/[a-z]/i)) {
      errors.phone = "Numer telefonu nie powinien zawierać liter";
    }
    return errors;
  },
  1: (values: Values) => {
    const errors: Partial<{ [K in keyof Values]: string }> = {};
    if (!values.password) {
      errors.password = "Wpisz hasło";
    }
    return errors;
  },
};

export const Login = () => {
  const history = useHistory();
  const matchLogin = useRouteMatch({
    path: "/login",
    strict: true,
  });

  const matchMain = useRouteMatch({
    path: "/",
    strict: true,
  });

  const [dispatch, { auth }] = useRedux();
  const initialValues = {
    phone: "",
    password: "",
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, setErrors }: FormikHelpers<Values>,
  ) => {
    const data = {
      phone: values.phone.replace(/ /g, ""), // remove spaces
      password: values.password.trim(),
    };
    const [payload, error] = await obtainToken(data);
    if (payload) {
      localStorage.setItem("token", payload.access);
      localStorage.setItem("refreshToken", payload.refresh);
      const [user, error] = await getUserInfo();

      if (user) {
        // Redirect to start module if this module has any links, if not redirect to orders list
        const moduleToRedirect = Object.values(moduleConfig).find(
          config =>
            config.name === user.startView &&
            config.navigationSections.some(section => section.navigationList.length > 0),
        );

        dispatch(auth.login(user));
        if (matchLogin?.isExact || matchMain?.isExact) {
          if (moduleToRedirect && FLAVOR === "main") {
            history.replace(moduleToRedirect.url);
          } else if (FLAVOR === "main") {
            history.replace("/orders");
          } else if (FLAVOR === "b2b") {
            history.replace("/client/orders");
          } else if (FLAVOR === "manufacturing") {
            history.replace("/");
          }
        }
      } else if (error) {
        setErrors({ password: getAnyErrorKey(error) });
        setSubmitting(false);
      }
    } else if (error) {
      setErrors({ password: getAnyErrorKey(error) });
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={cx("d-flex justify-content-between p-4", styles.nav)}>
        <div className="app-logo">
          <img src={logoImg} alt="Milo" />
        </div>
      </div>
      <div className={styles.imageBox}>
        {(FLAVOR === "main" || FLAVOR === "b2b") && <img alt="Logowanie - main" src={mainImg} />}
        {FLAVOR === "manufacturing" && <img alt="Logowanie - produkcja" src={manufacturingImg} />}
      </div>
      <div className={cx("d-flex align-items-center justify-content-center", styles.formBox)}>
        <div className={styles.container}>
          <h1>Witaj w Milo!</h1>
          <h5>Miło Cię widzieć</h5>
          <br />
          <FormWizard initialValues={initialValues} onSubmit={handleSubmit} pages={2}>
            {({ values, isLastPage, previous, isSubmitting, errors }) => (
              <>
                <FormWizard.Page page={0} validate={validators[0]}>
                  <h4 className={cx(styles.header, "mt-5 mb-3")}>Wpisz swój numer telefonu</h4>
                  <Field
                    id="phone"
                    name="phone"
                    autoFocus
                    placeholder="791260529"
                    type="text"
                    className={styles.input}
                  />
                  <ErrorMessage name="phone" />
                </FormWizard.Page>
                <FormWizard.Page page={1} validate={validators[1]}>
                  <div className="mt-5">
                    <div className="mb-2">{values.phone}</div>
                    <Button kind="secondary-stroke" size="round-s" onClick={previous}>
                      <img src={arrowImg} alt="" />
                    </Button>
                  </div>
                  <h4 className={cx(styles.header, "mt-5 mb-3")}>Podaj hasło aby kontynuować</h4>
                  <Field
                    autoFocus
                    id="password"
                    name="password"
                    placeholder="Hasło"
                    type="password"
                    className={styles.input}
                  />
                  <ErrorMessage name="password" />
                  <ErrorMessage type="plain" name="message" error={errors} />
                </FormWizard.Page>
                {!isLastPage && (
                  <div className="mt-4 d-flex align-items-center justify-content-between">
                    <Button type="submit" kind="primary" size="large">
                      <span>Kontynuuj</span>
                    </Button>
                  </div>
                )}
                {isLastPage && (
                  <div className="mt-4 d-flex align-items-center justify-content-between">
                    <Button type="submit" kind="primary" size="large" disabled={isSubmitting}>
                      <span>Zaloguj</span>
                    </Button>
                    <Link to={"/reset-password?phone=" + values.phone}>Nie pamiętam hasła</Link>
                  </div>
                )}
              </>
            )}
          </FormWizard>
        </div>
      </div>
      <div className={cx("p-4", styles.footer)}>
        <span>Crafted by</span>
        <div>
          <a href="https://kivy.pl/pl/">
            <img src={kivyImg} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};
