import { patchTradingDocument } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { TradingDocument } from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { InfoLabel } from "components/common/infoLabel";
import { DatePicker } from "components/utils/datePicker";
import { RightPanelSection } from "components/utils/drawer";
import { countryCodesDict } from "CONSTANTS";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { dateFns } from "utilities";

interface Props {
  tradingDocument: TradingDocument;
}

export const AmountSection = ({ tradingDocument }: Props) => {
  const updateTradingDocumentOptions = useMutationOptions(
    patchTradingDocument,
    ({ queryUtils }) => ({
      onMutate: toUpdate => {
        const prevDetails = queryUtils.handleMutate(
          tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
          toUpdate,
        );

        const prevList = queryUtils.handlePaginatedListUpdate(
          tradingDocumentsKeys.tradingDocument.list(),
          toUpdate.id,
          toUpdate,
        );

        return { prevDetails, prevList };
      },
      onError: (error, toUpdate, context) => {
        queryUtils.rollback(
          tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
          // @ts-ignore
          context.prevDetails,
          error,
        );
        queryUtils.rollbackList(
          tradingDocumentsKeys.tradingDocument.list(),
          // @ts-ignore
          context.prevList,
          toUpdate.id,
        );
      },
    }),
  );

  const updateVatObligationDateMutation = useMutation(
    updateTradingDocumentOptions.mutationFn,
    updateTradingDocumentOptions,
  );

  return (
    <RightPanelSection>
      <InfoLabel title="kraj">
        <img alt="Flaga kraju" src={countryCodesDict[tradingDocument.countryCode]} />
      </InfoLabel>
      <InfoLabel title="kwota">
        <FinanceAmountFormat
          decimalClassName="fw-700 fs-16"
          integerClassName="fw-700 fs-16"
          value={tradingDocument.amountSummary.totalWithTax}
        />
        <div className="text-color-grey">{tradingDocument.currency}</div>
      </InfoLabel>
      <InfoLabel title="kwota VAT">
        <FinanceAmountFormat
          decimalClassName="body-14-600"
          integerClassName="body-14-600"
          value={tradingDocument.amountSummary.totalTax}
        />
        <div className="body-14-600 text-color-grey">{tradingDocument.currency}</div>
      </InfoLabel>
      <InfoLabel title="waluta">
        <div className="body-14-500">{tradingDocument.currency}</div>
      </InfoLabel>

      <InfoLabel title="kurs wymiany">
        {tradingDocument.lastValidExchangeRate ? (
          <div className="body-14-500">{tradingDocument.lastValidExchangeRate}</div>
        ) : (
          <div className="body-14-500">--</div>
        )}
      </InfoLabel>
      <InfoLabel title="data wymiany">
        {tradingDocument.lastValidExchangeRateDate ? (
          <div className="body-14-500">{tradingDocument.lastValidExchangeRateDate}</div>
        ) : (
          <div className="body-14-500">---</div>
        )}
      </InfoLabel>

      <InfoLabel title="rejestr VAT">
        <DatePicker
          showMonthYearPicker={true}
          look="common"
          onChange={date => {
            if (!date) {
              return updateVatObligationDateMutation.mutate({
                id: tradingDocument.id,
                vatTaxObligationDate: null,
              });
            }
            return updateVatObligationDateMutation.mutate({
              id: tradingDocument.id,
              vatTaxObligationDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
            });
          }}
          customDateFormat="MM/yyyy"
          value={tradingDocument.vatTaxObligationDate ?? ""}
        />
      </InfoLabel>
      <InfoLabel title="obowiązek podatkowy">
        {tradingDocument.pitTaxObligationDate ? (
          <div className="body-14-500">
            {dateFns.format(new Date(tradingDocument.pitTaxObligationDate), "dd.MM.yyyy")}
          </div>
        ) : (
          <div className="body-14-500">---</div>
        )}
      </InfoLabel>
      <InfoLabel
        title={`${tradingDocument.sellerBankName ? tradingDocument.sellerBankName : "bank"} [${
          tradingDocument.currency ? tradingDocument.currency : "--"
        }]`}
      >
        {tradingDocument.sellerBankAccountNumber ? (
          <div>****&nbsp;{tradingDocument.sellerBankAccountNumber.slice(-4)}</div>
        ) : (
          <div className="body-14-500">---</div>
        )}
      </InfoLabel>
    </RightPanelSection>
  );
};
