import { pluralize, yup } from "utilities";

const requiredMsg = (field: string): string => {
  return `Pole ${field} jest wymagane`;
};

const maxLengthMsg = (field: string, chars: number): string => {
  return `Pole ${field} powinno zawierać maksymalnie ${chars} ${pluralize.pl(chars, {
    singular: "znak",
    plural: "znaki",
    other: "znaków",
  })}`;
};

export const validationSchema = yup.object().shape({
  paymentNumber: yup
    .string()
    .required(requiredMsg("Nr płatności"))
    .max(128, maxLengthMsg("Nr płatności", 128)),
  amount: yup.string().required(requiredMsg("Kwota")),
  bankTransactionNumber: yup
    .string()
    .required(requiredMsg("Nr przelewu"))
    .max(50, maxLengthMsg("Nr przelewu", 50)),
  provider: yup.string().max(50, maxLengthMsg("Usługa", 50)),
});
