import { SummaryPerOutPackage } from "api/trading-documents/models";
import styles from "../../../../WarehouseFinancialState.module.css";
import cx from "classnames";
import { LinkToPage } from "components/utils/linkToPage";
import { dateFns } from "utilities";

interface Props {
  summary: SummaryPerOutPackage;
}

export const SummaryPerOut = ({ summary }: Props) => {
  return (
    <div>
      {summary.whEntries.map((entry, index) => (
        <div className={cx(styles.tableRow, styles.tableRowWarehouseAction)}>
          <div>{index + 1}.</div>
          {entry.lastWhEntryOut ? (
            <div>
              <LinkToPage
                content={<div className="fw-700">{entry.lastWhEntryOutSignature}</div>}
                stopPropagation
                url={`/warehouse/documents?panelId=${entry.lastWhEntryOut}`}
              />
            </div>
          ) : (
            <div>---</div>
          )}
          <div>
            <div className="d-flex align-items-center justify-content-end textAlignRight">
              {entry.amount} PLN
            </div>
          </div>
          <div className="fw-700">{entry.quantity} szt.</div>
          <div className={styles.date}>
            {entry.lastWhEntryOutCreated
              ? dateFns.format(new Date(entry.lastWhEntryOutCreated), "dd.MM.yyyy")
              : "---"}
          </div>
        </div>
      ))}
    </div>
  );
};
