import { OrderForRoute } from "api/orders/models";
import { Route } from "api/routes/models";
import crossImg from "assets/images/11.svg";
import sendSmsIcon from "assets/images/174.svg";
import deliveryConfirmedIcon from "assets/images/175.svg";
import availabilityStatusIcon from "assets/images/176.svg";
import carImg from "assets/images/24.svg";
import pinImg from "assets/images/32g.svg";
import furnitureImg from "assets/images/33.svg";
import pin2Img from "assets/images/42.svg";
import commentImg from "assets/images/45.svg";
import timeImg from "assets/images/46.svg";
import dollarIcon from "assets/images/monetizationOn.svg";
import checkImg from "assets/images/7c.svg";
import distanceIcon from "assets/images/distance.svg";
import personIcon from "assets/images/accountCircle.svg";
import redCrossIcon from "assets/images/11r.svg";
import cx from "classnames";
import { Button } from "components/common";
import { AvailabilityDetailsModal } from "components/common/availabilityDetailsModal";
import { getLabelColor } from "components/common/listRow/utils";
import { CommonError, Label, Spinner, StatusHandler } from "components/utils";
import { useSelector, useStateModal } from "hooks";
import { useOrderForRoute } from "hooks/apiPrimitives";
import { Link } from "react-router-dom";
import { dateFns, getAnyErrorKey, pluralize } from "utilities";
import { getMarkerIcon } from "utilities/getMarkerIcon";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { useAssignItem } from "../../hooks/useAssignItem";
import { useUnassignOrder } from "../../hooks/useUnassignOrder";
import { useRouteViewState } from "../../routeCreatorState";
import styles from "./InfoWindowContent.module.css";
import { getAwaitingLabelColor } from "./utils";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { areConfirmedHoursInCollectionRange } from "../../leftPanel/pointsList/utils/areConfirmedHoursInCollectionRange";

interface Props {
  id: string;
  route: Route;
}

export const InfoWindowContent = ({ id, route }: Props) => {
  const { data: order, isLoading: inProgress, error } = useOrderForRoute(id);
  const hasSmsService = useSelector(store => store.partials.configuration.hasSmsService);
  const isPinned = route?.orders.some(el => el.id === Number(id));
  const modal = useStateModal();
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);
  const { unassignOrder } = useUnassignOrder(route);
  const { assignOrder } = useAssignItem(route);

  if (error) {
    return (
      <div className={styles["map-point-cloud"]}>
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <CommonError text={getAnyErrorKey(error)} />
        </div>
      </div>
    );
  }

  if (inProgress) {
    return (
      <div className={styles["map-point-cloud"]}>
        <div className="d-flex align-items-center justify-content-center flex-1 h-100 pt-5">
          <Spinner color="blue" size="big" />
        </div>
      </div>
    );
  }

  if (!order) return null;

  return (
    <div className={styles["map-point-cloud"]}>
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className={styles["point-image"]}>
              <img src={getMarkerIcon(order)} alt="" />
            </div>
            <div>
              {isAwaitingForWarehouseDelivery(order) &&
              order.warehouseDeliveryDetails.daysLeftToDelivery !== null ? (
                <Label
                  color={getAwaitingLabelColor(order.warehouseDeliveryDetails.daysLeftToDelivery)}
                  className="mr-3 ml-2"
                >
                  {order.warehouseDeliveryDetails.daysLeftToDelivery === 0 ? (
                    <span>dzisiaj</span>
                  ) : (
                    <span>
                      {order.warehouseDeliveryDetails.daysLeftToDelivery}{" "}
                      {pluralize.pl(order.warehouseDeliveryDetails.daysLeftToDelivery, {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      })}
                    </span>
                  )}
                </Label>
              ) : (
                <Label
                  color={getLabelColor(
                    order.type === "STANDARD" ? order.leftDays : order.numberOfDaysFromCreatedDate,
                    order.type,
                  )}
                  className="mr-3 ml-2"
                >
                  <span>
                    {order.type === "STANDARD" ? order.leftDays : order.numberOfDaysFromCreatedDate}{" "}
                    {pluralize.pl(
                      order.type === "STANDARD"
                        ? order.leftDays
                        : order.numberOfDaysFromCreatedDate,
                      {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      },
                    )}
                  </span>
                </Label>
              )}
            </div>
          </div>
          <small className={styles.createdDate}>
            zam. dn.: {dateFns.format(new Date(order.created), "dd.MM.yyyy")}
          </small>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              {isPinned ? (
                <StatusHandler>
                  {helpers => (
                    <Button
                      kind="secondary"
                      size="small"
                      className="pl-2 pr-2 pt-1 pb-1 ml-2"
                      disabled={!route || helpers.isFetching || isLoading}
                      onClick={() => {
                        actions.openLoader("Trwa odpinanie punktu");
                        unassignOrder(order.id, "order", helpers);
                      }}
                    >
                      <img className="mr-2" src={pinImg} alt="" />
                      <span>Odepnij</span>
                    </Button>
                  )}
                </StatusHandler>
              ) : (
                <StatusHandler>
                  {helpers => (
                    <Button
                      kind="secondary"
                      size="small"
                      className="pl-2 pr-2 pt-1 pb-1 ml-2"
                      disabled={!route || helpers.isFetching || isLoading}
                      onClick={() => {
                        actions.openLoader("Trwa przypinanie punktu");
                        assignOrder(
                          {
                            id: order.id,
                            point: order.delivery.point,
                            type: "order",
                            address: null,
                            warehouseDeliveryDetails: order.warehouseDeliveryDetails,
                          },
                          helpers,
                        );
                      }}
                    >
                      <img className="mr-2" src={pinImg} alt="" />
                      <span>Przypnij</span>
                    </Button>
                  )}
                </StatusHandler>
              )}
            </div>
            {hasSmsService && (
              <div className="d-flex align-items-center justify-content-end ml-2">
                <span
                  className={cx(
                    styles.smsNotification,
                    "mr-1",
                    order.hasSmsSent ? "bgPurple" : "bgGrey",
                  )}
                >
                  <img alt="" src={sendSmsIcon} />
                </span>
                <span
                  className={cx(
                    styles.smsNotification,
                    order.isDeliveryDateConfirm ? "bgBlue" : "bgGrey",
                  )}
                >
                  <img alt="" src={deliveryConfirmedIcon} />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-0 mt-2 row">
        <div className="col-6 pl-0">
          <div className={cx(styles["map-cloud_data-group"], "mb-3")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={pin2Img} alt="" />
              <span>
                {!order.customer
                  ? `${order.delivery.firstName} ${order.delivery.lastName}`
                  : order.customer.hasDropShipping
                  ? `${order.delivery.firstName} ${order.delivery.lastName} (${order.customer.name})`
                  : order.customer.name}
              </span>
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>
              {order.delivery.street} <br /> {order.delivery.city} {order.delivery.postCode}
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>tel.: {order.delivery.phone}</div>
          </div>
          {order.paymentType === "CASH_ON_DELIVERY" && (
            <div className="d-flex align-items-center gap-2 mb-3 pl-4">
              <img alt="płatność:" src={dollarIcon} style={{ height: "16px", width: "16px" }} />
              <span className="fs-14 text-color-grey">POBRANIE</span>
            </div>
          )}
        </div>
        <div className="col-6 pr-0">
          <div className={cx(styles["map-cloud_data-group"], "mb-3")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={commentImg} alt="" />
              <span>Komentarze</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>
              {order.comments.length ? (
                order.comments.map(comment => (
                  <div key={comment.id}>
                    <div className={styles.hasComment}>{comment.body}</div>
                    <div className={styles.usersComment}>
                      <strong className="mr-2">
                        {comment.user.firstName} {comment.user.lastName}
                      </strong>
                      <span className="mr-2">
                        {dateFns.format(new Date(comment.created), "d MMM yyyy")}
                      </span>
                      <span>{dateFns.format(new Date(comment.created), "H:mm")}</span>
                    </div>
                  </div>
                ))
              ) : (
                <span className={styles.noComment}>Brak komentarzy</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-0 row">
        <div className={cx(styles["map-cloud_data-group"], "my-2 col-6 pl-0")}>
          <div className={cx(styles["map-cloud_group-label"])}>
            <img src={carImg} alt="" />
            <span>Przewidywany czas dostawy</span>
          </div>
          <div
            className={cx(styles["map-cloud_group-data"], {
              [styles.correctConfirmationRange]:
                order.delivery.maximumCollectionHour &&
                order.delivery.minimumCollectionHour &&
                areConfirmedHoursInCollectionRange(
                  order.delivery.maximumCollectionHour,
                  order.delivery.minimumCollectionHour,
                  order.maximumCollectionHourAtConfirmation,
                  order.minimumCollectionHourAtConfirmation,
                ) === true,
              [styles.invalidConfirmationRange]:
                order.delivery.maximumCollectionHour &&
                order.delivery.minimumCollectionHour &&
                areConfirmedHoursInCollectionRange(
                  order.delivery.maximumCollectionHour,
                  order.delivery.minimumCollectionHour,
                  order.maximumCollectionHourAtConfirmation,
                  order.minimumCollectionHourAtConfirmation,
                ) === false,
            })}
          >
            {order.collectionHours ? (
              <>
                <span>{order.collectionHours.minimumCollectionHour || "--:--"}</span>
                <span className="mx-2">-</span>
                <span>{order.collectionHours.maximumCollectionHour || "--:--"}</span>
              </>
            ) : (
              <>
                <span className={styles.noComment}>--:--</span>
                <span className="mx-2">-</span>
                <span className={styles.noComment}>--:--</span>
              </>
            )}
          </div>
        </div>
        <div className={cx(styles["map-cloud_data-group"], "my-2 col-6 pr-0")}>
          <div className={cx(styles["map-cloud_group-label"])}>
            <img src={timeImg} alt="" />
            <span>Potwierdzony czas dostawy</span>
          </div>
          <div className={cx(styles["map-cloud_group-data"])}>
            {order.minimumCollectionHourAtConfirmation &&
            order.minimumCollectionHourAtConfirmation.length > 0 ? (
              <span>{order.minimumCollectionHourAtConfirmation}</span>
            ) : (
              <span className={styles.noComment}>--:--</span>
            )}
            <span className="mx-2">-</span>
            {order.maximumCollectionHourAtConfirmation &&
            order.maximumCollectionHourAtConfirmation.length > 0 ? (
              <span>{order.maximumCollectionHourAtConfirmation}</span>
            ) : (
              <span className={styles.noComment}>--:--</span>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap mt-2">
        <div
          className={cx(styles["map-cloud_data-group"], "map-cloud_data-group--product-list mb-3")}
        >
          {order.hasProducedItems ? (
            <div className={styles.hasProducedItems}>
              <img src={checkImg} alt="" className="mr-2" />
              <span>Wszystkie meble są przygotowane</span>
            </div>
          ) : null}
          <div className={cx(styles["map-cloud_group-label"])}>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center">
                <img src={furnitureImg} alt="" />
                <span>Dostarczyć zamówienie</span>
              </div>
              {order.hasReservations ? (
                <Label color="blue">zarezerwowano</Label>
              ) : (
                <Label color="pink">Nie zarezerwowano</Label>
              )}
            </div>
          </div>
          <div className={cx(styles["map-cloud_group-data"])}>
            {order.externalId ? (
              <div>
                <Link to={getOrderRedirectUrl(order)} className={cx(styles.signature, "mb-1 mr-1")}>
                  {order.signature}
                </Link>
                <span className="fs-12 text-color-grey">({order.externalId})</span>
              </div>
            ) : (
              <Link to={getOrderRedirectUrl(order)} className={cx(styles.signature, "mb-1")}>
                {order.signature}
              </Link>
            )}
            {order.items.map(item => (
              <div className="product d-flex align-items-center mb-2" key={item.id}>
                <div>
                  <div className="d-flex product-name mr-3">
                    {item.product ? item.product.name : "???"}
                    <div className="d-flex align-items-center ml-1">
                      <img className={styles.xIcon} src={crossImg} alt="" />
                      <span className="ml-1">{item.quantity}</span>
                    </div>
                    <Button
                      type="button"
                      kind="secondary"
                      size="round-s"
                      className="ml-1"
                      onClick={() => modal.open(item.index)}
                      title="Sprawdź dostępność"
                    >
                      <img alt="" src={availabilityStatusIcon} />
                    </Button>
                  </div>
                  {item.attributes.map(
                    ({ attribute, value }: { attribute: string; value: string }, index: number) => (
                      <div className={styles.attribute} key={index}>
                        <span>{attribute}: </span>
                        <strong>{value}</strong>
                      </div>
                    ),
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isPinned && order.pinnedToRouteDate && (
        <div className="d-flex flex-wrap m-0 row border-top pt-2 pb-2">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner on={isLoading} size="small" color="blue" />
            </div>
          ) : (
            <div className={cx(styles["map-cloud_data-group"], "d-flex align-items-center col-12")}>
              <div className={cx(styles["map-cloud_group-label"])}>
                <img src={timeImg} alt="Data" />
                <span>dodano do trasy</span>
              </div>
              <div className={cx(styles["map-cloud_group-data"])}>
                {order.pinnedToRouteDate ? (
                  <div className="d-flex align-items-center gap-2">
                    {
                      dateFns
                        .format(new Date(order.pinnedToRouteDate), "dd.MM.yyyy, HH:mm")
                        .split(",")[0]
                    }
                    <div className="text-color-grey">
                      {
                        dateFns
                          .format(new Date(order.pinnedToRouteDate), "dd.MM.yyyy, HH:mm")
                          .split(",")[1]
                      }
                    </div>
                  </div>
                ) : (
                  <span className={styles.noComment}>--:--</span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {!isPinned && (order.unpinnedFromRouteDate !== null || order.lastRouteSignature.length > 0) && (
        <div className="d-flex flex-wrap m-0 row border-top pt-2 pb-2">
          <div className={cx(styles["map-cloud_data-group"], "d-flex align-items-center  col-12")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={distanceIcon} alt="Trasa" style={{ height: "16px", width: "16px" }} />
              <span>ostatnio dodane do trasy</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"], "ml-2")}>
              {order.lastRouteSignature ? (
                <span>{order.lastRouteSignature}</span>
              ) : (
                <span className={styles.noComment}>--</span>
              )}
            </div>
          </div>
          <div className={cx(styles["map-cloud_data-group"], "d-flex align-items-center  col-12")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={timeImg} alt="Data" />
              <span>usunięto z trasy</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"], "ml-2")}>
              {order.unpinnedFromRouteDate ? (
                <div className="d-flex align-items-center gap-2">
                  {
                    dateFns
                      .format(new Date(order.unpinnedFromRouteDate), "dd.MM.yyyy, HH:mm")
                      .split(",")[0]
                  }
                  <div className="text-color-grey">
                    {
                      dateFns
                        .format(new Date(order.unpinnedFromRouteDate), "dd.MM.yyyy, HH:mm")
                        .split(",")[1]
                    }
                  </div>
                </div>
              ) : (
                <span className={styles.noComment}>--:--</span>
              )}
            </div>
          </div>
          <div className={cx(styles["map-cloud_data-group"], "d-flex align-items-center  col-12")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={personIcon} alt="Avatar" style={{ height: "16px", width: "16px" }} />
              <span>usunięte z trasy przez</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"], "ml-2")}>
              {order.unpinnedFromRouteByFirstName && order.unpinnedFromRouteByLastName ? (
                <div className="d-flex align-items-center gap-2">
                  <AvatarOrInitials
                    avatarSrc={order.unpinnedFromRouteByAvatar}
                    firstName={order.unpinnedFromRouteByFirstName}
                    lastName={order.unpinnedFromRouteByLastName}
                    className={styles.avatar}
                  />
                  <div>
                    {order.unpinnedFromRouteByFirstName} {order.unpinnedFromRouteByLastName}
                  </div>
                </div>
              ) : (
                <span className={styles.noComment}>--</span>
              )}
            </div>
          </div>
        </div>
      )}
      {order.isDeliveryDateRejected && (
        <div className="d-flex flex-wrap m-0 row border-top pt-2">
          <div className={cx(styles["map-cloud_data-group"], "col-12")}>
            <div className={cx(styles["map-cloud_group-label-red"])}>
              <img src={redCrossIcon} alt="Odrzucono" />
              <span>Odrzucono</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"], "ml-4")}>
              {order.deliveryDateRejectedByAvatar ||
              order.deliveryDateRejectedByFirstName ||
              order.deliveryDateRejectedByLastName ||
              order.deliveryDateRejectedTimestamp ? (
                <div className="d-flex align-items-center gap-2 mt-1">
                  <AvatarOrInitials
                    avatarSrc={order.deliveryDateRejectedByAvatar}
                    firstName={order.deliveryDateRejectedByFirstName}
                    lastName={order.deliveryDateRejectedByLastName}
                    className={styles.midAvatar}
                  />
                  <div className="d-flex align-items-center gap-2">
                    <div>
                      {order.deliveryDateRejectedByFirstName} {order.deliveryDateRejectedByLastName}
                    </div>
                    {order.deliveryDateRejectedTimestamp && (
                      <div className="fs-12 text-color-grey">
                        {dateFns.format(
                          new Date(order.deliveryDateRejectedTimestamp),
                          "dd.MM.yyyy, HH:mm",
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <span className={styles.noComment}>--</span>
              )}
            </div>
          </div>
        </div>
      )}
      {modal.isOpen && <AvailabilityDetailsModal stateModal={modal} />}
    </div>
  );
};

const isAwaitingForWarehouseDelivery = (order: OrderForRoute): boolean => {
  return (
    order.warehouseDeliveryDetails &&
    !order.warehouseDeliveryDetails.isInWarehouse &&
    order.warehouseDeliveryDetails.date !== null
  );
};
