import { AvailableFilters, FilterType } from "./types";
import { FilterSelect } from "./filterComponents/filterSelect/FilterSelect";
import { FilterDateRange } from "./filterComponents/filterDateRange/FilterDateRange";
import { FilterDate } from "./filterComponents/filterDate/FilterDate";
import { FilterText } from "./filterComponents/filterText/FilterText";
import { FilterSearch } from "./filterComponents/filterSearch/FilterSearch";
import { FilterSelectUser } from "./filterComponents/filterSelectUser/FilterSelectUser";

interface Props<T> {
  filter: AvailableFilters<T>;
}

export const TypeBasedFilter = <T extends string>({ filter }: Props<T>) => {
  switch (filter.type) {
    case FilterType.Search: {
      return <FilterSearch {...filter} />;
    }

    case FilterType.Select: {
      return <FilterSelect {...filter} />;
    }

    case FilterType.SelectUser: {
      return <FilterSelectUser {...filter} />;
    }

    case FilterType.DateRange: {
      return <FilterDateRange {...filter} />;
    }

    case FilterType.Date: {
      return <FilterDate {...filter} />;
    }

    case FilterType.Text: {
      return <FilterText {...filter} />;
    }

    default:
      const exhaustiveCheck: never = filter;
      throw new Error(`Unhandled color case: ${exhaustiveCheck}`);
  }
};
