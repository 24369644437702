import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { StateLabel } from "components/common/stateLabel";
import awaitingIcon from "assets/images/avgPace.svg";
import confirmedIcon from "assets/images/checkSmall.svg";

interface Props {
  order: CallCenterRouteOrderListItem;
}

export const ConfirmationStatus = ({ order }: Props) => {
  if (order.hasSmsSent && !order.isDeliveryDateConfirm) {
    return (
      <div>
        <StateLabel kind="yellow5">
          <img alt="oczekujące" src={awaitingIcon} style={{ height: "16px", width: "16px" }} />
          <span className="fw-700">Oczekuje</span>
        </StateLabel>
      </div>
    );
  }

  if (order.isDeliveryDateConfirm) {
    return (
      <div>
        <StateLabel kind="darkGreen">
          <img alt="potwierdzone" src={confirmedIcon} style={{ height: "16px", width: "16px" }} />
          <span className="fw-700">Potwierdzono</span>
        </StateLabel>
      </div>
    );
  }

  return <div className="fw-700">---</div>;
};
