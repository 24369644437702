import styles from "./StateLabel.module.css";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  kind: colorType;
  opacity?: "default" | "low" | "medium" | "high";
  size?: "small";
  style?: React.CSSProperties;
}
export type colorType =
  | "default"
  | "green"
  | "grey"
  | "bgGrey10"
  | "darkGreen"
  | "red"
  | "red4"
  | "bgRed4"
  | "orange"
  | "deepOrange3"
  | "deepOrange4"
  | "blue"
  | "lightBlue"
  | "orangeDark"
  | "cyan"
  | "limeBlack"
  | "violetWhite"
  | "transparentViolet"
  | "darkYellowBlack"
  | "darkRed"
  | "indigo"
  | "teal6"
  | "yellow5"
  | "deepPurple";

export const StateLabel = ({
  children,
  className,
  kind,
  opacity = "default",
  size = "small",
  style,
}: Props) => {
  return (
    <div
      className={cx(
        styles.stateLabel,
        className,
        stateLabelSizeConfig[size],
        stateLabelOpacityConfig[opacity],
        stateLabelKindConfig[kind],
      )}
      style={style}
    >
      {children}
    </div>
  );
};

const stateLabelKindConfig: Record<Props["kind"], string> = {
  default: styles.defaultKind,
  green: styles.green,
  grey: styles.grey,
  bgGrey10: styles["bg-grey-10"],
  darkGreen: styles.darkGreen,
  red: styles.red,
  red4: styles["red-4"],
  bgRed4: styles["bg-red-4"],
  blue: styles.blue,
  lightBlue: styles.lightBlue,
  orange: styles.orange,
  deepOrange3: styles.deepOrange3,
  deepOrange4: styles["bg-deep-orange-4"],
  orangeDark: styles.orangeDark,
  cyan: styles.cyan,
  limeBlack: styles.limeBlack,
  violetWhite: styles.violetWhite,
  transparentViolet: styles.transparentViolet,
  darkYellowBlack: styles.darkYellowBlack,
  darkRed: styles.darkRed,
  deepPurple: styles.deepPurple,
  teal6: styles["teal-6"],
  yellow5: styles["bg-yellow-5"],
  indigo: styles.indigo,
};

const stateLabelSizeConfig: Record<string, string> = {
  small: styles.small,
};

const stateLabelOpacityConfig: Record<string, string> = {
  default: styles.defaultOpactiy,
  low: styles.lowOpactiy,
  medium: styles.mediumOpactiy,
  high: styles.highOpacity,
};
