import cx from "classnames";
import styles from "../CommentsGenericSection.module.css";
interface DropdownProps {
  className?: string;
  alt: string;
  imgSrc: string;
  onClick: () => void;
  isLoading: boolean;
}

export const DropdownButton = ({
  className = "",
  alt = "",
  imgSrc = "",
  onClick,
  isLoading = false,
}: DropdownProps) => {
  return (
    <button
      disabled={isLoading}
      onClick={onClick}
      className={cx(
        styles.btnSizeSX,
        styles.btnBgDeepPurple,
        styles.nonfocusableLight,
        styles.btn,
        className,
      )}
    >
      <div className={cx(styles.btnBase, styles.btnBaseSmall, styles.dropdownButtonx)}>
        <img alt={alt} src={imgSrc} />
      </div>
    </button>
  );
};
