import { patchItemSummaryAmounts } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { TradingDocument, TradingDocumentItem } from "api/trading-documents/models";
import { UUID } from "api/types";
import { Button } from "components/common";
import { AsyncInput } from "components/utils";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { useState } from "react";
import { QueryClient } from "react-query";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../RightPanel.module.css";

interface Props {
  tradingDocumentId: UUID;
  tradingDocumentItem: TradingDocumentItem;
}

export const EditDiscountSection = ({ tradingDocumentId, tradingDocumentItem }: Props) => {
  const [showDiscountInput, setShowDiscountInput] = useState(false);

  const handleRollback = (prevPanel: TradingDocument, queryClient: QueryClient) => {
    queryClient.setQueryData<TradingDocument>(
      tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
      currentDocument => {
        assertIsDefined(currentDocument);
        return prevPanel;
      },
    );
  };

  const patchDiscountOptions = useMutationOptions(
    patchItemSummaryAmounts,
    ({ queryClient, toastr }) => ({
      onMutate: toUpdate => {
        const prevPanel = queryClient.getQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
        );

        queryClient.setQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
          currentDocument => {
            assertIsDefined(currentDocument);
            return {
              ...currentDocument,
              isManagedManually: true,
              items: currentDocument.items.map(item => {
                if (item) {
                  return {
                    ...item,
                    tradingDocumentItems: item.tradingDocumentItems.map(_tradingDocumentItem => {
                      if (_tradingDocumentItem.id === toUpdate.id) {
                        return {
                          ..._tradingDocumentItem,
                          discount: Number(toUpdate.discount),
                          discountAmount:
                            toUpdate.discount !== undefined
                              ? Number(toUpdate.discount / 100) *
                                _tradingDocumentItem.amountWithoutTax
                              : 0,
                        };
                      }
                      return _tradingDocumentItem;
                    }),
                  };
                }
                return item;
              }),
            };
          },
        );

        return { prevPanel };
      },
      onSuccess: payload => {
        queryClient.setQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
          currentDocument => {
            assertIsDefined(currentDocument);
            return {
              ...currentDocument,
              isManagedManually: payload.isManagedManually,
            };
          },
        );

        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
        );

        setShowDiscountInput(false);
        toastr.open({ type: "success", title: "Udało się!", text: "Zmodyfikowano rabat" });
      },
      onError: (error, _, context) => {
        const { prevPanel } = context as { prevPanel: TradingDocument };
        handleRollback(prevPanel, queryClient);
        setShowDiscountInput(false);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const patchDiscountMutation = useMutation(patchDiscountOptions.mutationFn, patchDiscountOptions);

  return (
    <div className="fw-700">
      {!showDiscountInput ? (
        <Button
          kind="transparent-black"
          onClick={e => {
            e.stopPropagation();
            setShowDiscountInput(true);
          }}
        >
          <div className="btnBase btnBaseSmall body-12">{tradingDocumentItem.discount}%</div>
        </Button>
      ) : (
        <div className="d-flex align-items-center gap-1">
          <AsyncInput
            disabled={false}
            onChange={value => {
              patchDiscountMutation.mutate({
                id: tradingDocumentItem.id,
                discount: Number(value),
              });
            }}
            overwrites={{
              container: { className: styles.editAmountWithoutTaxInput },
              input: { className: styles.editAmountWithoutTaxInput },
            }}
            placeholder="rabat"
            type="number"
            value={tradingDocumentItem.discount}
          />
          %
        </div>
      )}
    </div>
  );
};
