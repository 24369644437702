import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import styles from "../../shared/CallCenter.module.css";
import noUnreadMessagesIcon from "assets/images/mailAlt.svg";
import unreadMessagesIcon from "assets/images/markEmailUnreadGreen.svg";
import cx from "classnames";
import { callCenterSingleRouteOrdersListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { StateLabel } from "components/common/stateLabel";
import { Button } from "components/common";
import { dateFns } from "utilities";
import { isFromLastXHours } from "../utils/isFromLastFourHours";
import { CustomSwitch } from "components/utils/customSwitch";
import { useRoutePatchMutation } from "api/call-center/routes/hooks";
import { CallStatus } from "./CallStatus";
import { ExpectedDelivery } from "./ExpectedDelivery";
import { ResponsibleForRoute } from "./ResponsibleForRoute";
import { useSelector } from "hooks";
import { ConfirmationStatus } from "./ConfirmationStatus";
import { RejectionStatus } from "./RejectionStatus";
import { useState } from "react";
import { useToggle } from "hooks";
import { UnreadSmsModal } from "pages/callCenter/shared/UnreadSmsModal";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  order: CallCenterRouteOrderListItem;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
  callInProgress: number | null;
  setCallInProgress: any;
  togglePanel: (id: string) => void;
  search: string;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  order,
  selectMutlipleItems,
  callInProgress,
  setCallInProgress,
  togglePanel,
  search,
}: Props) => {
  const usePatchMutation = useRoutePatchMutation();
  const usePatchRouteOrderMutation = useRoutePatchMutation();
  const me = useSelector(state => state.auth.user!);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showSignatureHintbox, setShowSignatureHintbox] = useState(false);
  const unreadSmsModal = useToggle(false);

  return (
    <>
      <ModuleRow
        className={cx(styles.fixedSingleRouteRow, {
          active: isActive(String(order.id)),
          highlighted:
            isHighlighted(String(order.id)) || highlightedRows.some(row => row.id === order.id),
          [styles.editedRow]:
            order.pinnedToRouteDate && isFromLastXHours(order.pinnedToRouteDate, 3),
        })}
        gridStyle={callCenterSingleRouteOrdersListConfig.grid}
        onMouseDown={event =>
          selectMutlipleItems(event, order.id, index, () => {
            togglePanel(String(order.id));
          })
        }
      >
        <div className="fw-700">{order.routePosition}.</div>
        <div
          onMouseDown={evt => {
            evt.stopPropagation();
          }}
          className="btnBase16"
        >
          {order.hasUnreadMessages ? (
            <Button
              kind="transparent-white"
              onClick={evt => {
                unreadSmsModal.open();
              }}
            >
              <img alt="nowe" src={unreadMessagesIcon} />
            </Button>
          ) : (
            <img alt="brak" src={noUnreadMessagesIcon} />
          )}
        </div>
        <div className="d-flex align-items-center gap-1">
          <CopyToClipboardComponent text={order.signature}>
            <div className="d-flex align-items-center" onClick={() => setIsAnimating(true)}>
              <div
                className={cx(styles.signature, "d-flex align-items-center nowrap")}
                onMouseDown={e => e.stopPropagation()}
                onPointerOver={() => setShowSignatureHintbox(true)}
                onPointerLeave={() => setShowSignatureHintbox(false)}
              >
                {order.signature}
                {showSignatureHintbox && (
                  <div
                    className={cx(styles.hintbox, {
                      [styles.firstItemHintboxPosition]: index === 0,
                    })}
                  >
                    Kopiuj numer zamówienia
                  </div>
                )}
              </div>
              <div
                className={cx(styles.checkIcon, { [styles.successCheckAnimation]: isAnimating })}
                onAnimationEnd={() => setIsAnimating(false)}
              />
            </div>
          </CopyToClipboardComponent>
          {order.pinnedToRouteDate && isFromLastXHours(order.pinnedToRouteDate, 3) && (
            <StateLabel kind="indigo">
              <div className="fw-700 nowrap">ost. dodane</div>
            </StateLabel>
          )}
        </div>
        <div className="d-flex align-items-center gap-3" onMouseDown={e => e.stopPropagation()}>
          <CustomSwitch
            checked={order.isCallRequired}
            disabled={usePatchMutation.isLoading}
            label={order.isCallRequired ? "Tak" : "Nie"}
            name="isCallRequired"
            onChange={isCallRequired =>
              usePatchMutation.mutate({
                id: order.id,
                toUpdate: {
                  isCallRequired,
                },
              })
            }
          />
          <CallStatus order={order} />
        </div>
        <div className={cx(styles.overflow, styles.phone, "fw-700")}>
          {order.delivery.phone.length > 0 ? <>{formatPhone(order.delivery.phone)}</> : <>--</>}
        </div>

        <div
          onMouseDown={evt => {
            evt.stopPropagation();
          }}
        >
          {order.id === callInProgress ? (
            <div style={{ width: "95.5px", height: "24px" }} className="d-flex align-items-start">
              <Button
                className={cx(styles.callInProgress, "h-100 no-border overflow-hidden")}
                kind="create"
              >
                <div className="btnBase btnBaseSmall">W trakcie</div>
              </Button>
            </div>
          ) : (
            <Button
              kind="create"
              onClick={evt => {
                const date = new Date();
                setCallInProgress(order.id);
                usePatchRouteOrderMutation.mutate({
                  id: order.id,
                  toUpdate: {
                    callStatus: "ON_A_CALL",
                    calledAt: date.toJSON(),
                    hasCalled: true,
                    responsible: me,
                  },
                });
                evt.stopPropagation();
              }}
            >
              <div className={cx("btnBase btnBaseSmall", styles.callNowBtn)}>Zadzwoń teraz</div>
            </Button>
          )}
        </div>
        <div className={styles.signature}>
          <div className={styles.overflow}>
            {order.externalId.length > 0 ? order.externalId : "--"}
          </div>
        </div>
        <div className="d-flex align-items-center gap-1">
          <div className={cx(styles.overflow, "body-14 fw-700")}>
            {order.delivery.fistName.length > 0 && order.delivery.lastName.length > 0 ? (
              <>
                {order.delivery.fistName} {order.delivery.lastName}
              </>
            ) : (
              <>--</>
            )}
          </div>
        </div>
        <div className="fw-700">
          {order.deliveryConfirmationDeadline
            ? dateFns.format(new Date(order.deliveryConfirmationDeadline), "d.MM, H:mm")
            : "--"}
        </div>
        <ExpectedDelivery index={index} order={order} />
        {order.hasSmsSent ? (
          <div>
            <StateLabel kind="green">
              <div className="fw-700">wysłano</div>
            </StateLabel>
          </div>
        ) : (
          <div>
            <StateLabel kind="grey">
              <div className="fw-700">nie wysłano</div>
            </StateLabel>
          </div>
        )}
        <ConfirmationStatus order={order} />
        <RejectionStatus order={order} />
        <ResponsibleForRoute order={order} />
      </ModuleRow>
      {unreadSmsModal.isOpen && (
        <UnreadSmsModal
          search={search}
          orderId={order.id}
          close={unreadSmsModal.close}
          isOpen={unreadSmsModal.isOpen}
        />
      )}
    </>
  );
};

const formatPhone = (num: string | number) => {
  return String(num)
    .replace(/ /g, "")
    .split("")
    .slice(-10)
    .join("");
};
