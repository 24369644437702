import * as React from "react";
import Downshift, { DownshiftState, StateChangeOptions } from "downshift";
import closeIcon from "assets/images/11.svg";
import styles from "./Select.module.css";
import cx from "classnames";

interface Props<Item> {
  items: Item[];
  itemToString?: (item: Item | null) => string;
  itemToDisplay?: (item: Item, selectedItem: Item | null) => any;
  itemToDisplaySelected?: (item: Item | null) => any;
  label?: string;
  onChange: (selected: Item | null) => any;
  defaultSelected?: string | number;
  enableClear?: boolean;
  size?: "regular" | "small";
  buttonClassName?: string;
  width?: "auto" | "full";
  selectedItem?: number | string | null;
  disabled?: boolean;
  mode?: "common" | "editable";
  placeholder?: string;
  style?: React.CSSProperties;
  overwrites?: {
    wrapper?: { className?: string };
    button?: { className?: string };
    placeholder?: { className?: string };
    dropdownList?: { className?: string };
    value?: { className?: string };
    label?: { className?: string };
  };
}

function defaultItemToString<Item extends { [key: string]: any }>(item: Item | null) {
  return item ? item.name : "";
}

function stateReducer<Item>(state: DownshiftState<Item>, changes: StateChangeOptions<Item>) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEscape: {
      return {
        ...changes,
        selectedItem: state.selectedItem,
        inputValue: state.inputValue,
      };
    }
    default:
      return changes;
  }
}

export function Select<Item extends { id?: any; name: string; [key: string]: any }>({
  itemToString = defaultItemToString,
  defaultSelected,
  enableClear = false,
  onChange,
  items,
  label,
  size = "regular",
  buttonClassName = "",
  itemToDisplay = defaultItemToString,
  itemToDisplaySelected,
  width = "auto",
  selectedItem,
  disabled,
  mode = "common",
  placeholder = "",
  style = undefined,
  overwrites = {},
}: Props<Item>) {
  return (
    <Downshift
      stateReducer={stateReducer}
      onChange={onChange}
      itemToString={itemToString}
      selectedItem={
        selectedItem !== undefined
          ? items.find(el =>
              el.id !== undefined ? el.id === selectedItem : el.name === selectedItem,
            ) || null
          : undefined
      }
      initialSelectedItem={items.find(el =>
        el.id !== undefined ? el.id === defaultSelected : el.name === defaultSelected,
      )}
    >
      {({
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
        toggleMenu,
      }) => (
        <div
          className={cx("position-relative", overwrites.wrapper?.className ?? "", {
            "w-100": width === "full",
          })}
          style={style}
          title={inputValue ?? undefined}
        >
          {Boolean(label) && (
            <label {...getLabelProps({ className: cx(styles.label, overwrites.label?.className) })}>
              {label}
            </label>
          )}

          <div className="d-flex align-items-stretch">
            <button
              {...getToggleButtonProps({
                className: cx(buttonClassName, styles.rightBtn, overwrites.button?.className, {
                  [styles.sizeRegular]: size === "regular",
                  "w-100": width === "full",
                  [styles.editable]: mode === "editable",
                }),
              })}
            >
              <span
                className={cx(overwrites.value?.className, {
                  [styles.inputValue]: !enableClear,
                  [styles.inputValueWithClearEnabled]: enableClear,
                })}
              >
                {itemToDisplaySelected
                  ? itemToDisplaySelected(selectedItem)
                  : inputValue || (
                      <span className={cx(styles.placeholder, overwrites.placeholder?.className)}>
                        {placeholder}
                      </span>
                    )}
              </span>
              {selectedItem && enableClear && (
                <div>
                  <img
                    alt="wyczyść"
                    className="cursor-pointer ml-1"
                    onClick={e => {
                      e.stopPropagation();
                      onChange(null);
                    }}
                    src={closeIcon}
                  />
                </div>
              )}
            </button>
          </div>
          <ul
            {...getMenuProps({
              className: cx(styles.dropdownList, overwrites.dropdownList?.className, {
                [styles.open]: isOpen && !disabled,
              }),
            })}
          >
            {isOpen && !disabled
              ? items.map((item, index) => (
                  <li
                    {...getItemProps({
                      key: item.id || item.value || item.name,
                      index,
                      item,
                      disabled: item.disabled,
                      className: cx(styles.dropdownListElement, {
                        [styles.selected]: selectedItem === item,
                        [styles.highlighted]: highlightedIndex === index,
                      }),
                    })}
                  >
                    {itemToDisplay ? itemToDisplay(item, selectedItem) : itemToString(item)}
                  </li>
                ))
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
}
