import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useEffect, useState } from "react";
import { useProductionPlan } from "api/new-production-plans/hooks";
import { PanelHeader } from "./components/PanelHeader";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const { data: productionPlan, error, isLoading } = useProductionPlan(panelId);

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (!productionPlan) return null;

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  return (
    <DrawerRightPanel key={productionPlan.id}>
      <PanelHeader
        productionPlan={productionPlan}
        panelId={panelId}
        close={close}
        isScrolledOver={isScrolledOver}
      />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelSection>
          <div className="fs-24">{productionPlan.signature}</div>
        </RightPanelSection>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
