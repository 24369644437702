import { forwardRef } from "react";
import * as React from "react";
import styles from "./Button.module.css";
import cx from "classnames";

export interface PrefixAndClassNameOnlyProps {
  bsPrefix?: string;
  className?: string;
}

export interface PrefixProps<As extends React.ElementType = React.ElementType> {
  as?: As;
}

export type PrefixPropsWithChildren<
  As extends React.ElementType = React.ElementType
> = React.PropsWithChildren<PrefixProps<As>>;

export type ButtonType = "button" | "reset" | "submit" | string;

export type KindType =
  | "primary"
  | "submit"
  | "submit-medium"
  | "secondary"
  | "secondary-stroke"
  | "secondary-grey"
  | "link"
  | "ready"
  | "transparent-white"
  | "transparent-black"
  | "transparent-teal-green"
  | "transparent"
  | "checkbox"
  | "confirm"
  | "create"
  | "create-transparent"
  | "create-medium-transparent"
  | "danger"
  | "btnBgRed"
  | "btnBgWhite5"
  | "btnBgBlack5"
  | "btnBgDeepPurple";

export type SizeType =
  | "large"
  | "medium"
  | "small"
  | "round-m"
  | "round-s"
  | "rounded"
  | "square-xs"
  | "square-s"
  | "square-m"
  | "btnSizeSmall"
  | "btnSizeMedium"
  | "btnSizeLarge"
  | "tinyIcon";

export interface ButtonProps extends React.HTMLAttributes<HTMLElement>, PrefixPropsWithChildren {
  size?: SizeType;
  disabled?: boolean;
  target?: any;
  type?: ButtonType;
  kind: KindType;
  width?: "auto" | "fit-content" | "full";
}

type Omit<T, U> = Pick<T, Exclude<keyof T, keyof U>>;

export type ReplaceProps<Inner extends React.ElementType, P> = Omit<
  React.ComponentPropsWithRef<Inner>,
  P
> &
  P;

export interface PrefixRefForwardingComponent<TInitial extends React.ElementType, P = unknown> {
  <As extends React.ElementType = TInitial>(
    props: React.PropsWithChildren<ReplaceProps<As, PrefixProps<As> & P>>,
    context?: any,
  ): React.ReactElement | null;
  propTypes?: any;
  contextTypes?: any;
  defaultProps?: Partial<P>;
  displayName?: string;
}

type Button = PrefixRefForwardingComponent<"button", ButtonProps>;

export const getKindStyle = (kind: ButtonProps["kind"]) => {
  if (kind === "primary") return styles["btn-primary"];
  if (kind === "secondary") return styles["btn-secondary"];
  if (kind === "secondary-stroke") return styles["btn-stroke"];
  if (kind === "secondary-grey") return cx(styles["btn-secondary"], styles["btn-grey"]);
  if (kind === "ready") return styles["btn-ready"];
  if (kind === "link") return styles["btn-as-link"];
  if (kind === "danger") return styles["btn-danger"];
  if (kind === "transparent-white") return styles["transparent-white"];
  if (kind === "transparent-black") return styles["transparent-black"];
  if (kind === "transparent-teal-green") return styles["transparent-teal-green"];
  if (kind === "transparent") return styles["transparent"];
  if (kind === "checkbox") return styles["btn-checkbox"];
  if (kind === "confirm") return styles["btn-confirm"];
  if (kind === "create") return styles["btn-create"];
  if (kind === "create-transparent") return styles["btn-create-transparent"];
  if (kind === "create-medium-transparent") return styles["btn-create-medium"];
  if (kind === "submit") return styles["btn-submit"];
  if (kind === "submit-medium") return styles["btn-submit-medium"];
  if (kind === "btnBgRed") return styles["btnBgRed"];
  if (kind === "btnBgWhite5") return styles["btnBgWhite5"];
  if (kind === "btnBgBlack5") return styles["btnBgBlack5"];
  if (kind === "btnBgDeepPurple") return styles["btnBgDeepPurple"];
  return "";
};

export const getSizeStyle = (size: ButtonProps["size"]) => {
  if (size === "medium") return styles["button-m"];
  if (size === "large") return styles["button-l"];
  if (size === "small") return styles["button-s"];
  if (size === "btnSizeLarge") return styles["btnSizeLarge"];
  if (size === "btnSizeMedium") return styles["btnSizeMedium"];
  if (size === "btnSizeSmall") return styles["btnSizeSmall"];
  if (size === "round-m") return styles["round-size-m"];
  if (size === "round-s") return styles["round-size-s"];
  if (size === "rounded") return styles["rounded-size"];
  if (size === "square-xs") return styles["square-size-xs"];
  if (size === "square-s") return styles["square-size-s"];
  if (size === "square-m") return styles["square-size-m"];
  if (size === "tinyIcon") return styles["tinyIcon"];
};

export const getWidthStyle = (width: ButtonProps["width"]) => {
  if (width === "auto") return styles["auto-width"];
  if (width === "fit-content") return styles["content-width"];
  if (width === "full") return styles["full-width"];
  if (!width) return "";
};

const ButtonInner = (
  {
    children,
    type = "button",
    kind,
    size = "medium",
    width,
    className = "",
    as,
    disabled,
    onClick,
    ...rest
  }: ButtonProps,
  ref: unknown,
) => {
  const kindStyle = getKindStyle(kind);
  const sizeStyle = getSizeStyle(size);
  const widthStyle = getWidthStyle(width);

  const Component = as || "button";

  return (
    <Component
      ref={ref}
      {...rest}
      onClick={onClick}
      type={type}
      className={cx(kindStyle, sizeStyle, widthStyle, className)}
      disabled={disabled}
    >
      {children}
    </Component>
  );
};

/**
 * @deprecated
 */
export const Button = forwardRef(ButtonInner) as Button;
