import styles from "../CommentsGenericSection.module.css";
import cx from "classnames";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import visibilityOn from "assets/images/93.svg";
import visibilityOff from "assets/images/eye_off.svg";
import more from "assets/images/more_horiz.svg";
import { useEffect, useState } from "react";
import { CommentType } from "api/comments/models";
import { User } from "api/users/models";
import { useMutation } from "hooks/useMutation";
import { deleteComment, patchComment } from "api/comments/calls";
import { useQueryUtils } from "hooks";
import bin from "assets/images/81.svg";
import edit from "assets/images/edit.svg";
import { useQueryClient } from "react-query";
import { Field, Formik } from "formik";
import { commentsKeys } from "api/comments/keys";
import { DropdownButton } from "./DropdownButton";
import { Pagination } from "api/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { dateTimeParser } from "utilities";

interface Props extends CommentType {
  userLoggedIn: User;
  pageSize: number;
  getUrlWithParams: string;
  isLoading: boolean;
  endpointUrl: string;
}

export const Comment = ({
  id,
  body,
  user,
  created,
  isOnlyVisibleForTeam,
  userLoggedIn,
  isLoading = false,
  getUrlWithParams,
  endpointUrl,
}: Props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { rollbackList, handlePaginatedListUpdate } = useQueryUtils();
  const [isCommentEdited, setIsCommentEdited] = useState(false);
  const listCommentKey = commentsKeys.comments.list();
  const listCommentKeyQueried = commentsKeys.comments.list(getUrlWithParams);
  const queryClient = useQueryClient();
  useEffect(() => {
    setIsCommentEdited(false);
    setDropdownVisible(false);
  }, [id]);

  const [initialValues, setInitialValues] = useState({ body: body });
  const patchMutation = useMutation(patchComment, {
    onMutate: toUpdate => {
      const prevList = handlePaginatedListUpdate(listCommentKey, toUpdate.data.id, toUpdate);
      return { prevList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(listCommentKey);
    },
    onError: (error, toUpdate, context) => {
      //@ts-ignore
      rollbackList(listCommentKey, context.prevList, toUpdate.id);
    },
  });

  const editMutation = useMutation(patchComment, {
    onMutate: toUpdate => {
      const prevList = handlePaginatedListUpdate(listCommentKey, toUpdate.data.id, toUpdate.data);
      setDropdownVisible(false);
      return { prevList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(listCommentKey);
    },
    onError: (error, toUpdate, context) => {
      //@ts-ignore
      rollbackList(listCommentKey, context.prevList, toUpdate.id);
    },
    onSettled: () => {
      setIsCommentEdited(false);
    },
  });

  const deleteMutation = useMutation(deleteComment, {
    onMutate: data => {
      const prevList = queryClient.getQueryData(listCommentKeyQueried);
      queryClient.setQueryData<Pagination<CommentType>>(listCommentKeyQueried, currentList => {
        assertIsDefined(currentList);
        const filteredResults = currentList.results.filter(el => el.id !== data.id);
        return { ...currentList, results: filteredResults };
      });
      return prevList;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(listCommentKey);
    },
    onError: (error, toUpdate, context) => {
      //@ts-ignore
      rollbackList(listCommentKey, context.prevList, toUpdate.id);
    },
  });
  const handleSubmit = (values: Pick<CommentType, "body">) => {
    editMutation.mutate({
      data: { id: id, ...values },
      endpointUrl: endpointUrl,
    });
  };

  useEffect(() => {
    if (body) {
      setInitialValues({ body: body });
    }
  }, [body, id]);

  const [date, time] = dateTimeParser(created);

  return (
    <div className="justify-content-between gap-2 px-3 pt-2 d-flex">
      <div className="w-100">
        <div className={cx(styles.body14, styles.font400, "pt-1")}>
          <span className={!isCommentEdited ? "d-block" : "d-none"}>{body}</span>
          <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <form className={!isCommentEdited ? "d-none" : "d-flex"} onSubmit={handleSubmit}>
                <Field as="input" name="body" autoComplete="off" />
                <button
                  disabled={isLoading}
                  type="submit"
                  className={cx(
                    styles.btnBase,
                    styles.btnBaseSmall,
                    styles.btnBgDeepPurple,
                    "mx-2 rounded-sm",
                  )}
                >
                  Edit
                </button>
              </form>
            )}
          </Formik>
        </div>

        <div className="d-flex align-items-center gap-2 pt-1">
          <AvatarOrInitials
            avatarSrc={user.avatar}
            firstName={user.firstName}
            lastName={user.lastName}
            className={cx(styles.avatar, styles.avatarSmall, "m-0 ")}
          />

          <div className={styles.body12}>
            {user.firstName} {user.lastName}
          </div>
          <div className={styles.body10}>
            {date}, <span className={styles.textgreyAB}>{time}</span>
          </div>
        </div>
      </div>

      {userLoggedIn.id === user.id && (
        <>
          <button
            disabled={isLoading}
            onClick={() => {
              patchMutation.mutate({
                data: {
                  id: id,
                  isOnlyVisibleForTeam: !isOnlyVisibleForTeam,
                },
                endpointUrl,
              });
            }}
            className={cx(
              styles.btnSizeSX,
              styles.btnBgTransparentTextBlack,
              styles.btn,
              styles.nonfocusable,
            )}
          >
            <div className={cx(styles.btnBase, styles.btnBaseSmall)}>
              <img
                src={isOnlyVisibleForTeam ? visibilityOn : visibilityOff}
                alt="Toggle comment visibility button"
              />
            </div>
          </button>

          <div
            onMouseOver={() => setDropdownVisible(true)}
            onMouseOut={() => setDropdownVisible(false)}
            className={cx(
              styles.btnSizeSX,
              styles.btnBgTransparentTextBlack,
              styles.btn,
              styles.nonfocusable,
              "position-relative",
            )}
          >
            <div className={cx(styles.btnBase, styles.btnBaseSmall)}>
              <img src={more} alt="Pokaż opcje dla komentarza" />
            </div>

            <div
              onMouseOut={() => setDropdownVisible(false)}
              className={cx(styles.dropdown, dropdownVisible ? styles.visible : "")}
            >
              <div className="d-flex p-1 justify-content-around align-items-center h-100">
                <DropdownButton
                  isLoading={isLoading}
                  alt="Edytuj komentarz"
                  imgSrc={edit}
                  className="mr-1"
                  onClick={() => setIsCommentEdited(!isCommentEdited)}
                />
                <DropdownButton
                  imgSrc={bin}
                  alt="Usuń komentarz"
                  isLoading={isLoading}
                  onClick={() => {
                    deleteMutation.mutate({
                      id: id,
                      endpointUrl: endpointUrl,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
