import { RightPanelHeader } from "components/utils/drawer";
import { Button } from "components/common";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import moreOptionsIcon from "assets/images/threeDots.svg";
import cx from "classnames";
import rejectionIcon from "assets/images/callMissed.svg";
import checkSmall from "assets/images/checkSmall.svg";
import { confirmOrderCollectionDateByMiloUser, rejectOrderByMiloUser } from "api/orders/calls";
import { useMutation } from "hooks/useMutation";
import { useQueryUtils, useSelector } from "hooks";
import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { callCenterRoutesKeys } from "api/call-center/routes/keys";
import { PartialOf } from "typeUtilities";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  panelId: string;
  routeOrder: CallCenterRouteOrderListItem;
}

export const PanelHeader = ({ close, isScrolledOver, panelId, routeOrder }: Props) => {
  const useRouteStatusMutation = (mutationCall: (id: number) => Promise<any>) => {
    const { handleMutate, handlePaginatedListUpdate, rollback, rollbackList } = useQueryUtils();
    return useMutation(
      ({ id }: { id: number; toUpdate: PartialOf<CallCenterRouteOrderListItem> }) =>
        mutationCall(id),
      {
        onMutate: ({ id, toUpdate }) => {
          const prevPanel = handleMutate(callCenterRoutesKeys.routeOrder.details(id), toUpdate);

          const prevList = handlePaginatedListUpdate(
            callCenterRoutesKeys.routeOrder.list(),
            id,
            toUpdate,
          );
          return { prevList, prevPanel };
        },
        onError: (error, { id }, onMutateReturn) => {
          const { prevList, prevPanel } = onMutateReturn as { prevList: any; prevPanel: any };
          rollback(callCenterRoutesKeys.routeOrder.details(id), prevPanel, error);
          rollbackList(callCenterRoutesKeys.routeOrder.list(), prevList, id, error);
        },
      },
    );
  };

  const confirmMutation = useRouteStatusMutation(confirmOrderCollectionDateByMiloUser);
  const rejectOrderMutation = useRouteStatusMutation(rejectOrderByMiloUser);
  const me = useSelector(state => state.auth.user!);
  if (!panelId) return null;

  return (
    <RightPanelHeader
      className={cx({
        slidingPanelHeaderShadow: isScrolledOver,
      })}
    >
      <div className="d-flex w-100 align-items-center">
        {isScrolledOver && (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent nowrap">{routeOrder.signature}</div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Button
            className="btn btnDisabledGreen"
            onClick={() => {
              confirmMutation.mutate({
                id: Number(panelId),
                toUpdate: {
                  isDeliveryDateConfirm: true,
                  deliveryDateConfirmedBy: me,
                  deliveryDateConfirmationSource: "INTERNAL",
                  deliveryDateConfirmTimestamp: new Date().toJSON(),
                },
              });
            }}
            disabled={confirmMutation.isLoading || routeOrder.isDeliveryDateConfirm}
            kind="transparent-teal-green"
            size="btnSizeSmall"
          >
            <div className="btnBase btnBase16 uppercase btnBaseSmall">
              <img alt="Potwierdź" src={checkSmall} />
              {routeOrder.isDeliveryDateConfirm ? "Potwierdzono" : "Potwierdź"}
            </div>
          </Button>

          <Button
            onClick={() =>
              rejectOrderMutation.mutate({
                id: Number(panelId),
                toUpdate: {
                  isDeliveryDateRejected: true,
                  deliveryDateRejectedByFirstName: me.firstName,
                  deliveryDateRejectedByLastName: me.lastName,
                  deliveryDateRejectedByAvatar: me.avatar,
                  deliveryDateRejectedTimestamp: new Date().toJSON(),
                },
              })
            }
            disabled={rejectOrderMutation.isLoading || routeOrder.isDeliveryDateRejected}
            className="btn btnDisabledRed"
            kind="btnBgBlack5"
            size="btnSizeSmall"
          >
            <div className="btnBase btnBase16 uppercase btnBaseSmall">
              <img alt="Odrzuć" src={rejectionIcon} />
              {routeOrder.isDeliveryDateRejected ? "Odrzucono" : "Odrzuć"}
            </div>
          </Button>

          <span className="divider line-divider" />
          <Button kind="transparent-black" size="square-s">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Więcej opcji"
                src={moreOptionsIcon}
                style={{ height: "16px", width: "16px" }}
              />
            </div>
          </Button>
          <span className="divider line-divider" />
          <Button kind="transparent-black" onClick={close} size="square-s">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Zamknij panel"
                src={hidePanelIcon}
                style={{ height: "16px", width: "16px" }}
              />
            </div>
          </Button>
        </div>
      </div>
    </RightPanelHeader>
  );
};
