import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import homeIcon from "assets/images/homeLight.svg";
import { Dashboard } from "routes/manufacturingNew/Dashboard";

export const indexes: ModuleLink = {
  url: "indexes",
  label: "Indeksy",
  icon: homeIcon,
  subSection: <></>,
  routing: Dashboard,
};
