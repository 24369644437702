import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import cx from "classnames";
import styles from "../../shared/CallCenter.module.css";
import noUnreadMessagesIcon from "assets/images/mailAlt.svg";
import unreadMessagesIcon from "assets/images/markEmailUnreadGreen.svg";
import { callCenterOrdersListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { useRoutePatchMutation } from "api/call-center/routes/hooks";
import { CustomSwitch } from "components/utils/customSwitch";
import { CallStatus } from "pages/callCenter/routeOrdersList/components/CallStatus";
import { Button } from "components/common";
import { useSelector, useToggle } from "hooks";
import { dateFns } from "utilities";
import { ExpectedDelivery } from "pages/callCenter/routeOrdersList/components/ExpectedDelivery";
import { StateLabel } from "components/common/stateLabel";
import { ConfirmationStatus } from "pages/callCenter/routeOrdersList/components/ConfirmationStatus";
import { RejectionStatus } from "pages/callCenter/routeOrdersList/components/RejectionStatus";
import { ResponsibleForRoute } from "pages/callCenter/routeOrdersList/components/ResponsibleForRoute";
import { LinkToPage } from "components/utils/linkToPage";
import { UnreadSmsModal } from "pages/callCenter/shared/UnreadSmsModal";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  order: CallCenterRouteOrderListItem;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
  callInProgress: number | null;
  setCallInProgress: any;
  togglePanel: (id: string) => void;
  search: string;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  order,
  selectMutlipleItems,
  callInProgress,
  setCallInProgress,
  togglePanel,
  search,
}: Props) => {
  const isCallRequiredMutation = useRoutePatchMutation();
  const callMutation = useRoutePatchMutation();
  const me = useSelector(store => store.auth.user!);
  const unreadSmsModal = useToggle(false);

  return (
    <>
      <ModuleRow
        className={cx(styles.fixedSingleRouteRow, {
          active: isActive(String(order.id)),
          highlighted:
            isHighlighted(String(order.id)) || highlightedRows.some(row => row.id === order.id),
          // [styles.editedRow]: order.pinnedToRouteDate && isFromLastXHours(order.pinnedToRouteDate, 3),
        })}
        gridStyle={callCenterOrdersListConfig.grid}
        onMouseDown={event =>
          selectMutlipleItems(event, order.id, index, () => togglePanel(String(order.id)))
        }
      >
        <div
          onMouseDown={evt => {
            evt.stopPropagation();
          }}
          className="btnBase16"
        >
          {order.hasUnreadMessages ? (
            <Button
              kind="transparent-white"
              onClick={evt => {
                unreadSmsModal.open();
              }}
            >
              <img alt="nowe" src={unreadMessagesIcon} style={{ paddingBottom: "1px" }} />
            </Button>
          ) : (
            <img alt="brak" src={noUnreadMessagesIcon} style={{ paddingBottom: "1px" }} />
          )}
        </div>
        <div className="d-flex align-items-center gap-1">
          <div className={styles.signature}>{order.signature}</div>
        </div>
        <div className="d-flex align-items-center gap-3" onMouseDown={e => e.stopPropagation()}>
          <CustomSwitch
            checked={order.isCallRequired}
            disabled={isCallRequiredMutation.isLoading}
            label={order.isCallRequired ? "Tak" : "Nie"}
            name="isCallRequired"
            onChange={isCallRequired =>
              isCallRequiredMutation.mutate({
                id: order.id,
                toUpdate: {
                  isCallRequired,
                },
              })
            }
          />
          <CallStatus order={order} />
        </div>
        <div className={cx(styles.overflow, styles.phone, "fw-700")}>
          {order.delivery.phone.length > 0 ? <>{order.delivery.phone}</> : <>--</>}
        </div>
        <div onMouseDown={e => e.stopPropagation()}>
          {order.id === callInProgress ? (
            <div style={{ width: "95.5px", height: "24px" }} className=" d-flex align-items-start">
              <Button
                className={cx(styles.callInProgress, "h-100 no-border overflow-hidden")}
                kind="create"
              >
                <div className="btnBase btnBaseSmall">W trakcie</div>
              </Button>
            </div>
          ) : (
            <Button
              kind="create"
              onClick={evt => {
                const date = new Date();
                setCallInProgress(order.id);
                callMutation.mutate({
                  id: order.id,
                  toUpdate: {
                    callStatus: "ON_A_CALL",
                    calledAt: date.toJSON(),
                    hasCalled: true,
                    responsible: me,
                  },
                });
                evt.stopPropagation();
              }}
            >
              <div className={cx("btnBase btnBaseSmall", styles.callNowBtn)}>Zadzwoń teraz</div>
            </Button>
          )}
        </div>
        <div className={styles.signature}>
          <div className={styles.overflow}>
            {order.externalId.length > 0 ? order.externalId : "--"}
          </div>
        </div>
        <div className="d-flex align-items-center gap-1">
          <div className={cx(styles.overflow, "body-14 fw-700")}>
            {order.delivery.fistName.length > 0 && order.delivery.lastName.length > 0 ? (
              <>
                {order.delivery.fistName} {order.delivery.lastName}
              </>
            ) : (
              <>--</>
            )}
          </div>
        </div>
        <div className="fw-700">
          {order.deliveryConfirmationDeadline
            ? dateFns.format(new Date(order.deliveryConfirmationDeadline), "d.MM, H:mm")
            : "--"}
        </div>
        <ExpectedDelivery index={index} order={order} />
        {order.hasSmsSent ? (
          <div>
            <StateLabel kind="green">
              <div className="fw-700">wysłano</div>
            </StateLabel>
          </div>
        ) : (
          <div>
            <StateLabel kind="grey">
              <div className="fw-700">nie wysłano</div>
            </StateLabel>
          </div>
        )}
        <ConfirmationStatus order={order} />
        <RejectionStatus order={order} />
        <div onMouseDown={e => e.stopPropagation()} className={styles.overflow}>
          {order.route ? (
            <LinkToPage
              content={order.route.signature}
              url={`/call-center/route/${order.route.id}/orders`}
            />
          ) : (
            <div className="text-black-6 fw-700">nie zaplanowano</div>
          )}
        </div>
        <ResponsibleForRoute order={order} />
      </ModuleRow>
      {unreadSmsModal.isOpen && order && (
        <UnreadSmsModal
          search={search}
          orderId={order.id}
          close={unreadSmsModal.close}
          isOpen={unreadSmsModal.isOpen}
        />
      )}
    </>
  );
};
