import * as React from "react";
import styles from "./Drawer.module.css";
import cx from "classnames";
import { handleRightPanelScroll } from "../handleRightPanelScroll/handleRightPanelScroll";

interface DrawerProps {
  border?: boolean;
  className?: string;
  padding?: string;
  myRef?: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
  setIsScrolledOver?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RightPanelTableRowProps {
  className?: string;
  children?: React.ReactNode;
  grid: string;
  hover?: boolean;
  style?: React.CSSProperties;
}

export const DrawerRight = ({ children }: DrawerProps) => {
  return (
    <div className={styles.drawerRight}>
      <div className={styles.drawerInner}>{children}</div>
    </div>
  );
};

export const DrawerContent = ({ children, className }: DrawerProps) => {
  return <div className={cx(styles.drawerContent, className)}>{children}</div>;
};

export const DrawerHeader = ({ children }: DrawerProps) => {
  return <div className={styles.drawerHeader}>{children}</div>;
};

export const DrawerSections = ({ children, className }: DrawerProps) => {
  return <div className={cx(styles.drawerSections, className)}>{children}</div>;
};

export const DrawerSection = ({
  children,
  title = "",
  className = "",
}: DrawerProps & { title?: React.ReactNode }) => {
  return (
    <div className={cx(styles.drawerSection, className)}>
      {title ? <div className={styles.drawerSectionTitle}>{title}</div> : null}
      {children}
    </div>
  );
};

export const DrawerBottom = ({ children }: DrawerProps) => {
  return <div className={styles.drawerBottom}>{children}</div>;
};

export const ListAndDrawerWrapper = ({ children, className = "" }: DrawerProps) => {
  return <div className={cx(className, styles.listWrapper)}>{children}</div>;
};

export const ListDrawerWrapper = ({ children, className = "" }: DrawerProps) => {
  return (
    <div className={cx(className, styles.moduleListWrapper)}>
      <div className={styles.moduleListWrapperInner}>{children}</div>
    </div>
  );
};

export const DrawerHr = () => {
  return <div className={styles.drawerHr} />;
};

export const DrawerRightPanel = ({ children, className }: DrawerProps) => {
  return <div className={cx(styles.rightPanel, styles.rightPanelOpen, className)}>{children}</div>;
};

export const RightPanelHeader = ({ children, className }: DrawerProps) => {
  return <div className={cx(styles.rightPanelHeader, "px-3 py-2", className)}>{children}</div>;
};

export const RightPanelBody = ({ children, myRef, setIsScrolledOver, className }: DrawerProps) => {
  return (
    <div
      ref={myRef}
      className={cx(styles.rightPanelBody, className)}
      onScroll={() => {
        if (setIsScrolledOver) {
          handleRightPanelScroll(setIsScrolledOver, myRef);
        }
      }}
    >
      {children}
    </div>
  );
};

export const RightPanelSection = ({
  border = false,
  children,
  title = "",
  className = "",
  titleClassName = "",
  padding = "px-3 py-2",
}: DrawerProps & { title?: React.ReactNode; titleClassName?: string }) => {
  return (
    <div
      className={cx(styles.rightPanelSection, padding, className, {
        [styles.sectionBorder]: border,
      })}
    >
      {title ? (
        <div className={cx(styles.rightPanelSectionTitle, titleClassName)}>{title}</div>
      ) : null}
      {children}
    </div>
  );
};

export const RightPanelTableRow = ({
  children,
  className,
  grid,
  hover = true,
  style = {},
}: RightPanelTableRowProps) => {
  return (
    <div
      className={cx(styles.tableHeader, styles.rightPanelTableRow, className, {
        [styles.rightPanelTableBodyRow]: hover,
        "text-color-grey": !hover,
      })}
      style={{ gridTemplateColumns: grid, ...style }}
    >
      {children}
    </div>
  );
};
