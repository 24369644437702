import { OrderType } from "api/orders/models";
import { Seller } from "api/sellers/models";
import { UUID } from "api/types";
import { User } from "api/users/models";
import { CountryCodes } from "CONSTANTS";
import changesRequestedIcon from "assets/images/ruleOrange.svg";
import acceptedIcon from "assets/images/checkTeal.svg";
import awaitingIcon from "assets/images/radioButtonPartial.svg";
import { Assign } from "utility-types";
import { colorType } from "../../components/common/stateLabel";
import { ReminderType } from "../reminders/models";
import { AccountancyAccount } from "api/accountancy-accounts/models";

export const countryFullNames: Record<CountryCodes, string> = {
  AT: "AUSTRIA",
  CZ: "CZECHY",
  DE: "NIEMCY",
  FR: "FRANCJA",
  UK: "WIELKA BRYTANIA",
  HU: "WĘGRY",
  LT: "LITWA",
  PL: "POLSKA",
  SK: "SŁOWACJA",
  UA: "UKRAINA",
};

export const countryCodes: CountryCodes[] = ["DE", "PL", "CZ", "SK", "FR", "HU", "AT"];

export type Tab = "all" | "paid" | "not-paid";

export type MyImportInvoicesTab = "all" | "opened" | "closed";

export type InvoiceType = "SALES" | "PURCHASE" | "CORRECTION" | "PREPAYMENT" | "FINAL";

export type TradingDocumentStatus = "NEW" | "CONFIRMED";

export type TradingDocumentType = "INVOICE" | "RECEIPT";

export type TradingDocumentPaymentStatus = "NOT_PAID" | "PAID" | "PARTIALLY_PAID";

export type ExpectedPaymentForm = "CASH" | "ONLINE" | "";

export type CorrectionReasonField = "POSITION" | "QUANTITY" | "AMOUNT" | "DISCOUNT" | "VAT";

export type CorrectionReasonAction = "REMOVED" | "NEW" | "INCREMENT" | "DECREMENT";

export type ViesStatus =
  | "ACTIVE"
  | "INACTIVE"
  | "UNREGISTERED"
  | "NO_DATA"
  | "INVALID"
  | "NO_TAX_ID";

export const viesStatusDict: Record<ViesStatus, string> = {
  ACTIVE: "Aktywny",
  INACTIVE: "Nieaktywny",
  UNREGISTERED: "Niezarejestrowany",
  NO_DATA: "Brak danych",
  NO_TAX_ID: "Brak NIP-u",
  INVALID: "Nieznany",
};

export const expectedPaymentFormDict: Record<ExpectedPaymentForm, string> = {
  CASH: "Gotówka",
  ONLINE: "Przelew",
  "": "nie występuje na dokumencie",
};

export const paymentMethodsToPick: { label: string; value: ExpectedPaymentForm }[] = [
  { label: "Gotówka", value: "CASH" },
  { label: "Przelew", value: "ONLINE" },
];

export const invoiceTypeToPick: { id: InvoiceType; name: string }[] = [
  { id: "CORRECTION", name: "Korekta" },
  { id: "FINAL", name: "Końcowa" },
  { id: "PREPAYMENT", name: "Zaliczkowa" },
  { id: "PURCHASE", name: "Zakupowa" },
  { id: "SALES", name: "Sprzedażowa" },
];

export const paymentProviderToPick: { label: string; value: string }[] = [
  { label: "PAYU", value: "PAYU" },
  { label: "P24", value: "P24" },
];

export const invoiceTypeDict = {
  CORRECTION: "Korekta",
  FINAL: "Końcowa",
  PREPAYMENT: "Zaliczkowa",
  PURCHASE: "Zakupowa",
  SALES: "Sprzedażowa",
};

export const invoiceStatusDict: Record<TradingDocumentStatus, string> = {
  CONFIRMED: "Zatwierdzona",
  NEW: "Nowa",
};

export const invoicePaymentStatusDict: Record<TradingDocumentPaymentStatus, string> = {
  NOT_PAID: "Nieopłacona",
  PAID: "Opłacona",
  PARTIALLY_PAID: "Częściowo opłacona",
};

export const invoicePaymentStatusToPick: { id: TradingDocumentPaymentStatus; name: string }[] = [
  { id: "NOT_PAID", name: "Nieopłacona" },
  { id: "PAID", name: "Opłacona" },
  { id: "PARTIALLY_PAID", name: "Częściowo opłacona" },
];

export const tradingDocumentStatusToPick: { id: TradingDocumentStatus; name: string }[] = [
  { id: "CONFIRMED", name: "Zatwierdzony" },
  { id: "NEW", name: "Nowy" },
];

export const tradingDocumentStatusDict = {
  CONFIRMED: "Zatwierdzony",
  NEW: "Nowy",
};

export const tradingDocumentTypeToPick: { id: TradingDocumentType; name: string }[] = [
  { id: "INVOICE", name: "Faktura" },
  { id: "RECEIPT", name: "Paragon" },
];

export const tradingDocumentTypeDict = {
  INVOICE: "Faktura",
  RECEIPT: "Paragon",
};

export const correctionReasonsMap: Record<
  CorrectionReasonField,
  Partial<Record<CorrectionReasonAction, string>>
> = {
  POSITION: {
    REMOVED: "Usunięto pozycję",
  },
  QUANTITY: {
    NEW: "Dodanie produktu",
    INCREMENT: "Zwiększenie liczby pozycji",
    DECREMENT: "Zmniejszenie liczby pozycji",
  },
  AMOUNT: {
    NEW: "Dodanie należności za fakturę",
    INCREMENT: "Zwiększenie należności za pozycję faktury",
    DECREMENT: "Zmniejszenie należności za pozycję faktury",
  },
  DISCOUNT: {
    NEW: "Przyznanie rabatu",
    INCREMENT: "Zwiększenie rabatu",
    DECREMENT: "Zmniejszenie rabatu",
  },
  VAT: {
    NEW: "Dodano stawkę VAT",
    INCREMENT: "Błędna stawka VAT, zwiększenie stawki VAT",
    DECREMENT: "Błędna stawka VAT, zmniejszenie stawki VAT",
  },
};

export interface TradingDocument {
  amountSummary: {
    totalTax: number;
    totalWithTax: number;
    totalWithTaxInWords: string;
    totalWithoutTax: number;
  };
  amountSummaryCurrencyConvert: {
    exchangeRate: number | null;
    exchangeRateDate: string | null;
    totalTax: number | null;
    totalWithTax: number | null;
    totalWithoutTax: number | null;
  };
  amountSummaryPerVatRate: {
    totalWithTax: number;
    totalWithoutTax: number;
    totalTax: number;
    vatRate: number;
  }[];
  amountSummaryPerVatRateCurrencyConvert: {
    exchangeRate: number | null;
    exchangeRateDate: string | null;
    totalTax: number | null;
    totalWithTax: number | null;
    totalWithoutTax: number | null;
    vatRate: number | null;
  }[];
  assignedTo:
    | (Pick<User, "firstName" | "id" | "isActive" | "isSuperuser" | "lastName" | "phone"> & {
        avatar: string | null;
      })
    | null;
  basePaymentDeadlineDelta: number;
  canBeFiscalized: boolean;
  corrections: {
    createdAt: string;
    id: UUID;
    invoiceDeliveryDate: string | null;
    paymentDeadline: string | null;
    reasons: {
      field: CorrectionReasonField;
      action: CorrectionReasonAction;
    }[];
    signature: string;
    status: TradingDocumentStatus;
  }[];
  correctionReason: string;
  correspondingDocuments: CorrespondingDocument[];
  correspondingReceipt: CorrespondingReceipt | null;
  counter: string;
  countryCode: CountryCodes;
  createdAt: string;
  createdBy: number;
  currency: string;
  customer: TradingDocumentCustomer | null;
  description: string;
  expectedPaymentForm: ExpectedPaymentForm;
  hasCorrectionRequired: boolean;
  id: UUID;
  invoiceDeliveryDate: string | null;
  invoiceIssueDate: string | null;
  invoicePaymentDate: string | null;
  invoiceType: InvoiceType;
  isGrnConfirmed: boolean;
  isInVatSettlement: boolean;
  isManagedManually: boolean;
  isSentToRecipient: boolean;
  items: {
    id: UUID;
    order: {
      created: string;
      id: number | string;
      signature: string;
    } | null;
    tradingDocumentItems: TradingDocumentItem[];
  }[];
  kind: TradingDocumentKind;
  lastValidExchangeRate: string | null;
  lastValidExchangeRateDate: string | null;
  latestMessageStatus: TradingDocumentNotificationStatus;
  ledgerAccount: AccountancyAccount;
  masterDocument: {
    id: UUID;
    invoiceDeliveryDate: string | null;
    invoiceIssueDate: string | null;
    invoicePaymentDate: string | null;
    signature: string;
  } | null;
  notificationsSent: TradingDocumentNotification[];
  orders: {
    id: number;
    isCanceled: boolean;
    isSettled: boolean;
    signature: string;
    type: OrderType;
  }[];
  payments: TradingDocumentPayment[];
  paymentDeadline: string | null;
  paymentDeadlineDelta: number | null;
  paymentStatus: TradingDocumentPaymentStatus;
  pitTaxObligationDate: string | null;
  printingStatus: ReceiptPrintingStatus;
  printingRequestedAt: string | null;
  printingRequestedBy: {
    avatar: string | null;
    firstName: string;
    id: number;
    lastName: string;
  } | null;
  reasons: {
    field: CorrectionReasonField;
    action: CorrectionReasonAction;
  }[];
  remindersSent: ReminderType[];
  recipient: Seller | null;
  recipientBankAccountNumber: string;
  recipientBankName: string;
  recipientCity: string;
  recipientCompanyName: string;
  recipientEmail: string;
  recipientFirstName: string;
  recipientLastName: string;
  recipientPhone: string;
  recipientPostCode: string;
  recipientStreet: string;
  recipientTaxId: string;
  seller: Seller | null;
  sellerBankAccountNumber: string;
  sellerBankName: string;
  sellerCity: string;
  sellerCompanyName: string;
  sellerEmail: string;
  sellerFirstName: string;
  sellerLastName: string;
  sellerPhone: string;
  sellerPostCode: string;
  sellerStreet: string;
  sellerTaxId: string;
  sentToEmail: string;
  sentToRecipientBy: Pick<
    User,
    "avatar" | "firstName" | "id" | "isActive" | "isSuperuser" | "lastName" | "phone"
  >;
  sentToRecipientDate: string | null;
  signatureOfAnAuthorizedPerson: string;
  signature: string;
  status: TradingDocumentStatus;
  tradingDocumentPdf: TradingDocumentPdf | null;
  type: TradingDocumentType;
  vatTaxObligationDate: string | null;
  viesCheckedAt: string | null;
  viesValidStatus: ViesStatus;
  whiteListCheckedAt: string | null;
  whiteListValidStatus: ViesStatus;
  xmlOptimaDownloadAt: string;
  xmlOptimaDownloadBy: {
    id: Pick<User, "id">;
    avatar: string;
    firstName: string;
    lastName: string;
  } | null;
}

export type TradingDocumentKind = "RETAIL" | "WHOLESALE" | "DROPSHIPPING";

export type ReceiptPrintingStatus = "NOT_STARTED" | "IN_PROGRESS" | "FINISHED" | "FAILED";

export const receiptPrintingStatusDict: Record<ReceiptPrintingStatus, string> = {
  NOT_STARTED: "gotowy do druku",
  IN_PROGRESS: "drukuje się",
  FINISHED: "wydrukowano",
  FAILED: "błąd drukowania",
};

export interface CorrespondingReceipt {
  id: UUID;
  signature: string;
  printingStatus: ReceiptPrintingStatus;
  printingRequestedAt: string | null;
}

export interface CorrespondingDocument {
  id: UUID;
  invoiceType: InvoiceType;
  isDraft: boolean;
  signature: string | null;
  type: TradingDocumentType;
}

export interface CurrentlyPrintedReceipt {
  id: UUID;
  orders: string[];
  printingRequestedAt: string | null;
  printingRequestedBy: {
    avatar: string | null;
    firstName: string;
    id: number;
    lastName: string;
  } | null;
  recipientFirstName: string;
  recipientLastName: string;
  signature: string;
}

export interface TradingDocumentCustomer {
  id: string | number;
  name: string;
  hasDropShipping: boolean;
  hasAmountToCollect: boolean;
  canAddNewIndexes: boolean;
  color: string;
  textColor: string;
  taxId: string | null;
}

export type TradingDocumentNotificationAction = "STANDARD" | "CORRECTION" | "DUPLICATE";

export type TradingDocumentNotificationStatus = "OPENED" | "SENT" | "DECLINED" | "CLICKED_LINK";

export const tradingDocumentNotificationStatusDict: Record<
  TradingDocumentNotificationStatus,
  string
> = {
  OPENED: "Odczytana",
  SENT: "Wysłana",
  DECLINED: "Błąd wysyłki",
  CLICKED_LINK: "Odczytana",
};

export const tradingDocumentNotificationActionDict: Record<
  TradingDocumentNotificationAction,
  string
> = {
  STANDARD: "wysłano fakturę",
  CORRECTION: "wysłano korektę",
  DUPLICATE: "wysłano duplikat",
};

export interface TradingDocumentNotification {
  action: TradingDocumentNotificationAction;
  date: string | null;
  recipient: string;
  sentBy: Pick<User, "avatar" | "firstName" | "id" | "lastName">;
  status: TradingDocumentNotificationStatus;
}

export const tradingDocumentPaymentKindDict: Record<TradingDocumentPaymentKind, string> = {
  ADVANCE: "Zaliczka",
  PAYMENT: "Płatność",
  REFUND: "Zwrot",
  OTHER: "Inne",
};

export const tradingDocumentPaymentKindShortcutsDict: Record<TradingDocumentPaymentKind, string> = {
  ADVANCE: "ZAL",
  PAYMENT: "PŁT",
  REFUND: "ZWR",
  OTHER: "INN",
};

export const tradingDocumentPaymentKindForPurchaseInvoice: Record<
  TradingDocumentPaymentKind,
  string
> = {
  ADVANCE: "zal.",
  PAYMENT: "płt.",
  REFUND: "zwr.",
  OTHER: "inn.",
};

export const tradingDocumentPaymentModeDict: Record<TradingDocumentPaymentMode, string> = {
  AUTO: "Auto",
  MANUAL: "Manual",
};

export const tradingDocumentPaymentModeShortcutsDict: Record<TradingDocumentPaymentMode, string> = {
  AUTO: "automat.",
  MANUAL: "manual.",
};

export const tradingDocumentPaymentTypeDict: Record<TradingDocumentPaymentType, string> = {
  CASH: "Gotówka",
  ONLINE: "Przelew",
};

export interface ViesStatusPayload {
  viesCheckedAt: string;
  viesValidStatus: ViesStatus;
}

export interface WhiteListStatusPayload {
  whiteListCheckedAt: string;
  whiteListStatus: ViesStatus;
}

export interface UpdateStatusPayload {
  counter: number;
  isDraft: boolean;
  signature: string;
  status: TradingDocumentStatus;
}

export type TradingDocumentPaymentKind = "ADVANCE" | "PAYMENT" | "REFUND" | "OTHER";

export const tradingDocumentPaymentKindToPick: {
  id: Omit<TradingDocumentPaymentKind, "OTHER">;
  name: string;
}[] = [
  { id: "ADVANCE", name: "zaliczka" },
  { id: "PAYMENT", name: "płatność" },
  { id: "REFUND", name: "zwrot" },
];

export type TradingDocumentPaymentMode = "AUTO" | "MANUAL";

export type TradingDocumentPaymentType = "CASH" | "ONLINE";

export const tradingDocumentPaymentTypeToPick: {
  id: TradingDocumentPaymentType;
  name: string;
}[] = [
  { id: "CASH", name: "gotówka" },
  { id: "ONLINE", name: "przelew" },
];

export type TradingDocumentPaymentProvider = "PAYPRO" | "P24" | "PAYU" | "BLUE_MEDIA";

export const tradingDocumentPaymentProviderToPick: {
  id: TradingDocumentPaymentProvider;
  name: string;
}[] = [
  { id: "PAYPRO", name: "PayPro" },
  { id: "P24", name: "Przelewy24" },
  { id: "PAYU", name: "PayU" },
  { id: "BLUE_MEDIA", name: "BlueMedia" },
];

export interface CreateTradingDocumentPayment {
  amount: string | null;
  bankTransactionDate: string | null;
  bankTransactionNumber: string;
  commission: number | null;
  createdAt: string;
  createdBy: Pick<User, "firstName" | "lastName" | "avatar">;
  currency: string;
  kind: "ADVANCE" | "PAYMENT" | "REFUND";
  paymentDate: string | null;
  paymentNumber: string;
  provider: string;
  tradingDocument: UUID;
  type: TradingDocumentPaymentType;
}

export interface TradingDocumentPayment {
  amount: number;
  bankTransactionDate: string | null;
  bankTransactionNumber: string;
  commission: number | null;
  createdAt: string;
  createdBy: Pick<User, "firstName" | "lastName" | "avatar">;
  currency: string;
  id: UUID;
  kind: TradingDocumentPaymentKind;
  mode: TradingDocumentPaymentMode;
  paymentDate: string | null;
  paymentNumber: string;
  provider: string;
  type: TradingDocumentPaymentType;
}

export interface TradingDocumentItem {
  addedMethod?: TradingDocumentItemAddedMethod;
  amountWithTax: number;
  amountWithoutTax: number;
  codeCnPkwiu: string;
  currency: string;
  discount: number;
  discountAmount: number;
  displayPosition: number;
  ean: string;
  id: UUID;
  name: string;
  newAmountWithTax: number;
  newAmountWithoutTax: number;
  newDiscount: number;
  newQuantity: number;
  newVatRate: number;
  note: string;
  oldAmountWithTax: number;
  oldAmountWithoutTax: number;
  oldDiscount: number;
  oldQuantity: number;
  oldVatRate: number;
  quantity: number;
  sourceIndex: number;
  sourceItem: number;
  taxAmount: number;
  unit: string;
  vatRate: number;
  verboseAttributesValues: string;
}

export type TradingDocumentItemAddedMethod = "AUTOMATICALLY" | "MANUALLY";

export type PreviewTradingDocument = Assign<
  Omit<TradingDocument, "orders">,
  { orders: OrderForCreatingInvoice[]; sellerId: UUID }
>;

export type PreviewCorrectionDocument = Assign<
  Omit<TradingDocument, "orders">,
  { orders: OrderForCreatingInvoice[]; sellerId: UUID; masterDocumentSignature?: UUID }
>;

export interface CreateCorrectionDocumentPayload {
  tradingDocument: UUID;
  positions: {
    amountWithTax: number;
    discount: number;
    discountAmount: number;
    indexId: number;
    name: string;
    orderItemId: string;
    sourceId: string;
    quantity: number;
    vatRate: number;
  }[];
}

export interface OrderForCreatingInvoice {
  orderId: string;
  orderSignature: string;
  positions: OrderPositionForCreatingInvoice[];
}

export interface OrderPositionForCreatingInvoice {
  alreadyInvoiced: boolean;
  amountWithTax: number;
  amountWithoutTax: number;
  currency: string;
  discount: number;
  discountAmount: number;
  displayPosition: number;
  indexId: number;
  masterDocument: TradingDocument | null;
  name: string;
  newAmountWithTax: number;
  newAmountWithoutTax: number;
  newDiscount: number;
  newQuantity: number;
  newVatRate: number;
  oldAmountWithTax: number;
  oldAmountWithoutTax: number;
  oldDiscount: number;
  oldQuantity: number;
  oldVatRate: number;
  orderItemId: string;
  quantity: number;
  reasons: {
    field: CorrectionReasonField;
    action: CorrectionReasonAction;
  }[];
  sourceId: string;
  taxAmountBeforeRounding: number;
  vatRate: number;
}

export interface CreateTradingDocumentForSingleOrder {
  assignedTo: Pick<User, "avatar" | "firstName" | "id" | "lastName"> | null;
  items: number[];
  order: number;
  seller: string;
  type: TradingDocumentType | string;
  invoiceType?: InvoiceType | null;
  masterDocument: TradingDocument | null;
  recipientCity: string;
  recipientCompanyName: string;
  recipientEmail: string | null;
  recipientFirstName: string;
  recipientLastName: string;
  recipientPhone: string;
  recipientPostCode: string;
  recipientStreet: string;
  recipientTaxId: string;
  countryCode: string;
}

export interface ModifyTradingDocument {
  assignedTo: (Pick<User, "firstName" | "id" | "lastName"> & { avatar: string | null }) | null;
  items: number[];
  order: number;
  seller: string;
  correctionReason: string;
  expectedPaymentForm: string;
  type: TradingDocumentType | string;
  invoiceType?: InvoiceType | null;
  invoiceDeliveryDate: string | null;
  ledgerAccount: {
    id: string;
    name: string;
  } | null;
  masterDocument: TradingDocument | null;
  paymentDeadline: string | null;
  paymentStatus: TradingDocumentPaymentStatus;
  recipientCity: string;
  recipientCompanyName: string;
  recipientEmail: string | null;
  recipientFirstName: string;
  recipientLastName: string;
  recipientPhone: string;
  recipientPostCode: string;
  recipientStreet: string;
  recipientTaxId: string;
  countryCode: string;
  vatTaxObligationDate: string | null;
}

export interface CreateInvoiceForMultipleOrders {
  customerId: number | string;
}

export interface SendNotificationToRecipient {
  tradingDocumentId: UUID;
  recipientEmail?: string;
}

export type InvoiceReviewerStatus = "AWAITING" | "ACCEPTED" | "CHANGES_REQUESTED";

export const invoiceReviewerStatusDict: Record<InvoiceReviewerStatus, string> = {
  AWAITING: awaitingIcon,
  ACCEPTED: acceptedIcon,
  CHANGES_REQUESTED: changesRequestedIcon,
};

export interface InvoiceReviewer {
  id: UUID;
  isMasterReviewer: boolean;
  status: InvoiceReviewerStatus;
  tradingDocument: UUID;
  user: Omit<User, "token" | "type" | "customer" | "profile">;
}

export type TradingDocumentPdfProcessingStatus =
  | "ACCEPTED"
  | "REJECTED"
  | "NEW"
  | "IN_PROGRESS"
  | "DONE"
  | "FAILED";
export const invoiceProcessingStatusDict: Record<TradingDocumentPdfProcessingStatus, string> = {
  NEW: "Nowa",
  IN_PROGRESS: "W toku",
  DONE: "Gotowe",
  ACCEPTED: "Zaakceptowana",
  FAILED: "Niepowodzenie",
  REJECTED: "Odrzucono",
};
export const invoiceProcessingStatusColorDict: Record<
  TradingDocumentPdfProcessingStatus,
  colorType
> = {
  NEW: "transparentViolet",
  IN_PROGRESS: "orange",
  DONE: "green",
  ACCEPTED: "cyan",
  FAILED: "darkRed",
  REJECTED: "red",
};

export interface TradingDocumentPdf {
  createdAt: string;
  createdBy: number;
  id: UUID;
  pdf: string;
  failureReason: string;
  status: TradingDocumentPdfProcessingStatus;
  tradingDocument: UUID;
}

export type PurchaseInvoiceToReview = Assign<
  TradingDocument,
  { reviewers: InvoiceReviewer[]; tradingDocumentPdf: TradingDocumentPdf }
>;

export type ReviewStatus = "OPEN" | "CLOSED";

export const reviewStatusDict: Record<ReviewStatus, string> = {
  OPEN: "Otwarta",
  CLOSED: "Zamknięta",
};

export interface MyPurchaseInvoiceToReview {
  createdAt: string;
  id: UUID;
  invoiceIssueDate: string | null;
  paymentDeadline: string | null;
  paymentStatus: TradingDocumentPaymentStatus;
  recipientCompanyName: string;
  recipientFirstName: string;
  recipientLastName: string;
  reviewers: InvoiceReviewer[];
  reviewStatus: ReviewStatus;
  sellerCompanyName: string;
  sellerFirstName: string;
  sellerLastName: string;
  signature: string;
  totalWithTax: string;
}

export type InvoiceAdmissionStatus = "NEW" | "CONFIRMED";

export interface InvoiceAdmission {
  amount: string;
  businessEntity: UUID | null;
  businessEntityName: string;
  createdAt: string;
  currency: string;
  currentAmount: string | null;
  id: UUID;
  name: string;
  reviewers: InvoiceReviewer[];
  status: InvoiceAdmissionStatus;
  tdCreatedAt: string;
  tdSignature: string;
  tradingDocument: UUID;
  whEntries: number[];
}

export interface LinkLinePackage {
  amount: number;
  createdAt: string;
  id: UUID;
  name: string;
  package: number;
  quantity: number;
}

export interface InvoiceAdmissionLinkLine {
  amount: string;
  confidence: 0 | 1 | 2;
  createdAt: string;
  id: UUID;
  invoiceAdmissionLink: UUID;
  isEdited: boolean;
  name: string;
  packages: LinkLinePackage[];
  quantity: number;
  tdItem: UUID;
}

export interface ModifyInvoiceAdmissionPackage {
  quantity: number;
  amount: number;
}

export interface WhEntryPackage {
  createdAt: string;
  id: UUID;
  leftQuantity: number;
  name: string;
  package: number;
  quantity: number;
  usedQuantity: number;
}

export type WhEntryAggregateStatus = "EMPTY" | "UNUSED" | "USED";

export interface WhEntryAggregate {
  id: UUID;
  manufacturers: {
    id: number;
    manufacturer: {
      id: number;
      name: string;
    };
  }[];
  signature: string;
  status: WhEntryAggregateStatus;
  whEntry: number;
  whEntryCreatedAt: string;
}

export interface AssignedWhEntry extends WhEntryAggregate {
  whEntryCreatedAt: string;
  invoiceAdmissionLink: UUID;
  items: WhEntryPackage[];
}

export interface AssignWhEntry {
  invoiceAdmissionLink: UUID;
  whEntryAggregate: UUID;
}

export interface AssigneWhEntryPreview extends AssignWhEntry {
  id: UUID;
}

export interface AssignPackageToWhEntryAggregate {
  whEntryAggregateItem: UUID;
  name: string;
  package: number;
  quantity: number;
  amount: string | number;
  item: UUID;
}

export interface ItemUsed {
  id: UUID;
  leftQuantity: number;
  quantity: number;
  usedQuantity: number;
}

export interface AssignPackageToLinkLinePreview {
  amount: string | number;
  createdAt: string;
  createdBy: number;
  id: UUID;
  item: UUID;
  itemUsed: ItemUsed;
  name: string;
  package: number;
  quantity: number;
  whEntryAggregateItem: UUID;
}

export interface AddCustomerInPurchaseInvoiceImport {
  seller: string;
  sellerBankAccountNumber: string;
  sellerBankName: string;
  sellerCity: string;
  sellerCompanyName: string;
  sellerEmail: string;
  sellerFirstName: string;
  sellerLastName: string;
  sellerPhone: string;
  sellerPostCode: string;
  sellerStreet: string;
  sellerTaxId: string;
}

export interface AddPurchaseInvoicePosition {
  name: string;
  vatRate: number;
  tradingDocumentOrder: UUID;
  unit: string;
  displayPosition: number;
  amountWithoutTax: number;
  quantity: number;
  discount: number;
  verboseAttributesValues?: string;
  note?: string;
  codeCnPkwiu?: string;
}

export interface WarehouseTotalValueSummary {
  whValueSummary: string | number | null;
}

export interface FinancialStateWarehouse {
  city: string;
  externalId: string;
  id: number;
  isDefault: boolean;
  name: string;
  point: string | null;
  postCode: string;
  street: string;
}

export interface WarehousePackagesInOrOutSummary {
  amount: string;
  id: UUID;
  inAt: string | null;
  inCount: number | null;
  internalId: string;
  item: {} | null;
  lastWhEntryIn: {
    id: number;
    signature: string;
  } | null;
  lastWhEntryOut: {
    id: number;
    signature: string;
  } | null;
  name: string;
  order: {} | null;
  outAt: string | null;
  outCount: number | null;
  package: number;
  warehouse: FinancialStateWarehouse | null;
}

export interface SummaryInWhEntry {
  amount: number;
  lastWhEntryIn: number | null;
  lastWhEntryInCreated: string | null;
  lastWhEntryInSignature: string | null;
  quantity: number;
}

export interface SummaryPerInPackage {
  id: number;
  internalId: string;
  name: string;
  whEntries: SummaryInWhEntry[];
}

export interface SummaryOutWhEntry {
  amount: number;
  lastWhEntryOut: number | null;
  lastWhEntryOutCreated: string | null;
  lastWhEntryOutSignature: string | null;
  quantity: number;
}

export interface SummaryPerOutPackage {
  id: number;
  internalId: string;
  name: string;
  whEntries: SummaryOutWhEntry[];
}
