import { Module, ModuleNavigation } from "../moduleTypes/moduleTypes";
import { finances } from "./accountancy";
import { account } from "./account";
import { crm } from "./crm";
import { humanResources } from "./humanResources";
import { inventory } from "./inventory";
import { logistics } from "./logistics";
import { manufacturing } from "./manufacturing";
import { reports } from "./reports";
import { rules } from "./rules";
import { sales } from "./sales";
import { support } from "./support";
import { warehouse } from "./warehouse";
import { callCenter } from "./callCenter";

export const moduleConfig: Record<Module, ModuleNavigation> = {
  account,
  crm,
  finances,
  humanResources,
  inventory,
  logistics,
  manufacturing,
  reports,
  rules,
  sales,
  support,
  callCenter,
  wms: warehouse,
};
