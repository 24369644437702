import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/common";
import sendIcon from "assets/images/send.svg";
import restartIcon from "assets/images/restartAlt.svg";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { notificationSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { EmptyNotificationSection } from "./components/EmptyNotificationSection";
import { Notification } from "./components/Notification";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { postNotificationToRecipient } from "api/trading-documents/calls";
import { getAnyErrorKey } from "utilities";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { useToggle } from "hooks";
import { SendToSpecificAddressModal } from "./components/SendToSpecificAddressModal";

interface Props {
  tradingDocument: TradingDocument;
}

export const NotificationSection = ({ tradingDocument }: Props) => {
  const emailNotificationOptions = useMutationOptions(
    postNotificationToRecipient,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Pomyślnie wysłano powiadomienie o fakturze",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const sendNotificationToRecipientMutation = useMutation(
    emailNotificationOptions.mutationFn,
    emailNotificationOptions,
  );
  const modalController = useToggle();

  return (
    <RightPanelSection title="Wysyłka">
      {tradingDocument.notificationsSent.length === 0 ? (
        <EmptyNotificationSection
          open={modalController.open}
          sendNotificationToRecipientMutation={sendNotificationToRecipientMutation}
          tradingDocument={tradingDocument}
        />
      ) : (
        <div className="pb-3">
          <RightPanelTableRow grid={notificationSectionListConfig.grid} hover={false}>
            <div>#</div>
            <div>akcja</div>
            <div>data</div>
            <div>na adres</div>
            <div>wysyłka</div>
            <div>przez kogo</div>
          </RightPanelTableRow>
          <div>
            {tradingDocument.notificationsSent.map((notification, index) => (
              <Notification key={index} notification={notification} position={index + 1} />
            ))}
          </div>
          <div className="py-2 d-flex align-items-center gap-1">
            <Button
              disabled={sendNotificationToRecipientMutation.isLoading}
              kind="create"
              onClick={() =>
                sendNotificationToRecipientMutation.mutate({
                  tradingDocumentId: tradingDocument.id,
                })
              }
            >
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Ponów wysłanie"
                  src={restartIcon}
                  style={{ height: "16px", width: "16px" }}
                />
                Wyślij ponownie
              </div>
            </Button>

            <Button onClick={modalController.open} kind="create">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyślij z adresem"
                  src={sendIcon}
                  style={{ height: "16px", width: "16px" }}
                />
                Wskaż adres i wyślij
              </div>
            </Button>
          </div>
        </div>
      )}
      {modalController.isOpen && (
        <SendToSpecificAddressModal
          close={modalController.close}
          mutation={sendNotificationToRecipientMutation}
          tradingDocumentId={tradingDocument.id}
        />
      )}
    </RightPanelSection>
  );
};
