import {
  invoicePaymentStatusDict,
  invoiceStatusDict,
  TradingDocument,
  tradingDocumentNotificationStatusDict,
} from "api/trading-documents/models";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import styles from "../../tradingDocumentsList/TradingDocumentsList.module.css";
import cx from "classnames";
import { salesInvoiceListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { StateLabel } from "components/common/stateLabel";
import { dateFns, pluralize } from "utilities";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "pages/tradingDocuments/shared/utils/isDateOlderThan24Hours";
import { dateExceedsToday } from "pages/tradingDocuments/shared/utils/dateExceedsToday";
import { PaymentDeadlineDeltaLabel } from "pages/tradingDocuments/shared/components/PaymentDeadlineDeltaLabel";
import { getStatusLabelKind } from "pages/tradingDocuments/shared/utils/getStatusLabelKind";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { UUID } from "api/types";
import { HighlightedRow } from "api/other/models";
import { countryCodesDict } from "CONSTANTS";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  togglePanel: (id: string) => void;
  salesInvoice: TradingDocument;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID,
    index: number,
    togglePanel: () => void,
  ) => void;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  togglePanel,
  salesInvoice,
  selectMutlipleItems,
}: Props) => {
  return (
    <ModuleRow
      className={cx({
        active: isActive(salesInvoice.id),
        highlighted:
          isHighlighted(salesInvoice.id) || highlightedRows.some(row => row.id === salesInvoice.id),
      })}
      gridStyle={salesInvoiceListConfig.grid}
      onMouseDown={event =>
        selectMutlipleItems(event, salesInvoice.id, index, () => togglePanel(salesInvoice.id))
      }
    >
      <div>
        <ConfirmCheckbox tradingDocument={salesInvoice} />
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className={cx(styles.client, "fw-700")}>
          {salesInvoice.recipientCompanyName ? (
            salesInvoice.recipientCompanyName
          ) : (
            <>
              {salesInvoice.recipientFirstName} {salesInvoice.recipientLastName}
            </>
          )}
        </div>
        {!isDateOlderThan48Hours(salesInvoice.createdAt) && (
          <StateLabel
            kind="green"
            opacity={
              salesInvoice.createdAt && isDateOlderThan24Hours(salesInvoice.createdAt)
                ? "high"
                : "default"
            }
          >
            <div>{invoiceStatusDict.NEW}</div>
          </StateLabel>
        )}
      </div>
      <div className={styles.signature}>{salesInvoice.signature}</div>
      <div className={cx(styles.amountToPay, "d-flex align-items-center")}>
        <FinanceAmountFormat value={salesInvoice.amountSummary.totalWithTax} />
        <div className="fs-10 text-color-grey">{salesInvoice.currency}</div>
      </div>
      <div className="d-flex align-items-center gap-1">
        <img alt="Flaga kraju" src={countryCodesDict[salesInvoice.countryCode]} />
        <div className="fw-700">{salesInvoice.countryCode}</div>
      </div>
      <div className={styles.date}>
        {salesInvoice.invoiceIssueDate
          ? dateFns.format(new Date(salesInvoice.invoiceIssueDate), "dd.MM.yyyy")
          : "---"}
      </div>
      <div className={cx(styles.date, "d-flex align-items-center")}>
        {salesInvoice.paymentDeadline ? (
          <div
            className={cx({
              "text-red-6": dateExceedsToday(salesInvoice.paymentDeadline),
            })}
          >
            {dateFns.format(new Date(salesInvoice.paymentDeadline), "dd.MM.yyyy")}
          </div>
        ) : (
          "---"
        )}
        {salesInvoice.basePaymentDeadlineDelta && (
          <div>
            &nbsp;&nbsp;({salesInvoice.basePaymentDeadlineDelta}{" "}
            {pluralize.pl(salesInvoice.basePaymentDeadlineDelta, {
              singular: "dzień",
              plural: "dni",
              other: "dni",
            })}
            )
          </div>
        )}
      </div>
      <div className={cx(styles.signature, "d-flex align-items-center")}>
        {salesInvoice.orders.slice(0, 1).map(order => {
          return order ? order.signature : "";
        })}{" "}
        &nbsp;
        {salesInvoice.orders.filter(order => order).length - 1 > 0 ? (
          <MoreDataCounter
            className={styles.moreDataCounterOverride}
            counter={salesInvoice.orders.filter(order => order).length - 1}
            style={{ width: "max-content" }}
          />
        ) : null}
      </div>
      <div>
        <StateLabel
          kind={getStatusLabelKind(salesInvoice.paymentStatus, salesInvoice.paymentDeadline)}
        >
          {salesInvoice.paymentStatus === "PAID" ? (
            <>{invoicePaymentStatusDict[salesInvoice.paymentStatus]}</>
          ) : (
            "Nieopłacona"
          )}
        </StateLabel>
      </div>
      <div className="fw-700">
        {salesInvoice.paymentDeadlineDelta !== null && salesInvoice.paymentStatus !== "PAID" && (
          <PaymentDeadlineDeltaLabel paymentDeadlineDelta={salesInvoice.paymentDeadlineDelta} />
        )}
      </div>
      <div className="fw-700">
        {salesInvoice.latestMessageStatus && salesInvoice.latestMessageStatus.length > 0 ? (
          <>{tradingDocumentNotificationStatusDict[salesInvoice.latestMessageStatus]}</>
        ) : (
          "--"
        )}
      </div>
      <div>
        {salesInvoice.assignedTo && (
          <div className="d-flex align-items-center gap-1">
            {salesInvoice.assignedTo.avatar ? (
              <div className={cx(styles.listAvatar, styles.avatarSmall)}>
                <img alt="avatar" src={salesInvoice.assignedTo.avatar} />
              </div>
            ) : (
              <InitialsAvatar
                className={cx(styles.listAvatar, styles.avatarSmall)}
                firstName={salesInvoice.assignedTo.firstName}
                lastName={salesInvoice.assignedTo.lastName}
                style={{ margin: 0 }}
              />
            )}
            <div className={styles.assignee}>
              {salesInvoice.assignedTo.firstName} {salesInvoice.assignedTo.lastName}
            </div>
          </div>
        )}
      </div>
      <div className={styles.subject}>
        {salesInvoice.sellerCompanyName ? (
          salesInvoice.sellerCompanyName
        ) : (
          <>
            {salesInvoice.sellerFirstName} {salesInvoice.sellerLastName}
          </>
        )}
      </div>
    </ModuleRow>
  );
};
