import printerIcon from "assets/images/printStatus.svg";
import lastPrintedIcon from "assets/images/printConnect.svg";
import { Button } from "components/common";
import { ToggleHookState } from "hooks";

interface Props {
  currentlyPrintedReceiptsModal: ToggleHookState;
  lastPrintedReceiptsModal: ToggleHookState;
}

export const BottomBarOptions = ({
  currentlyPrintedReceiptsModal,
  lastPrintedReceiptsModal,
}: Props) => {
  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <Button
          kind="transparent-black"
          onClick={lastPrintedReceiptsModal.open}
          size="square-s"
          title="Ostatnio wydrukowane"
        >
          <div className="btnBase btnBaseSmall">
            <img
              alt="Paragony w druku"
              src={lastPrintedIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
        <span className="line-divider lineDividerMargin" />
        <Button
          kind="transparent-black"
          onClick={currentlyPrintedReceiptsModal.open}
          size="square-s"
          title="Paragony w druku"
        >
          <div className="btnBase btnBaseSmall">
            <img
              alt="Paragony w druku"
              src={printerIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
      </div>
    </>
  );
};
