import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import supportNavIcon from "assets/images/supportAgent.svg";
import supportMenuIcon from "assets/images/bok.svg";
import { dashboard } from "./routes/dashboard";
import { routes } from "./routes/routes";
import { route } from "./routes/route";
import { orders } from "./routes/orders";

export const callCenter: ModuleNavigation = {
  labelBackground: "#55bca6",
  label: "Biuro obsługi klienta",
  iconBackground: "#1e4c40",
  icon: supportNavIcon,
  menuIcon: supportMenuIcon,
  name: "CALL_CENTER",
  url: "/call-center",
  navigationSections: [{ navigationList: [dashboard, orders, routes, route] }],
};
