import logisticsIcon from "assets/images/transport.svg";
import shippingIcon from "assets/images/localShippingLight.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { routes } from "./routes/routes";
import { route } from "./routes/route";

export const logistics: ModuleNavigation = {
  labelBackground: "#8c73ff",
  label: "Logistyka",
  iconBackground: "#352a68",
  icon: shippingIcon,
  menuIcon: logisticsIcon,
  name: "LOGISTICS",
  url: "/logistics",
  navigationSections: [{ navigationList: [dashboard, routes, route] }],
};
