import { Button } from "components/common";
import binIcon from "assets/images/81.svg";
import { Pagination, UUID } from "api/types";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { useMutation } from "hooks/useMutation";
import { deletePurchaseInvoicePosition } from "api/trading-documents/calls";
import { getAnyErrorKey, queryString } from "utilities";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { useQuery } from "hooks";
import { PurchaseInvoiceToReview } from "api/trading-documents/models";
import { QueryClient } from "react-query";
import { assertIsDefined } from "utilities/assertIsDefined";

interface Props {
  tradingDocumentItemId: UUID;
  tradingDocumentItemName: string;
}

export const DeletePurchaseInvoicePosition = ({
  tradingDocumentItemId,
  tradingDocumentItemName,
}: Props) => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const pageSize = { pageSize: 1 };
  const search = queryString.stringify({ ...query, ...pageSize });

  const handleRollback = (
    prevList: Pagination<PurchaseInvoiceToReview>,
    queryClient: QueryClient,
  ) => {
    queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
      tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
      currentList => {
        assertIsDefined(currentList);
        return prevList;
      },
    );
  };

  const deleteMutation = withDeleteConfirmation(
    useMutation(deletePurchaseInvoicePosition, ({ queryClient, toastr }) => ({
      onMutate: () => {
        const prevList = queryClient.getQueryData<Pagination<PurchaseInvoiceToReview>>(
          tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
        );

        queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
          tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(result => {
                return {
                  ...result,
                  items: result.items.map(item => {
                    return {
                      ...item,
                      tradingDocumentItems: item.tradingDocumentItems.filter(
                        tradingDocumentItem => tradingDocumentItem.id !== tradingDocumentItemId,
                      ),
                    };
                  }),
                };
              }),
            };
          },
        );

        return { prevList };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(tradingDocumentsKeys.purchaseInvoicesToReview.list(search));
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto pozycję "${tradingDocumentItemName}"`,
        });
      },
      onError: (error, _, context) => {
        const { prevList } = context as { prevList: Pagination<PurchaseInvoiceToReview> };
        handleRollback(prevList, queryClient);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
  )();

  return (
    <Button
      disabled={deleteMutation.isLoading}
      kind="transparent-black"
      onClick={e => {
        e.stopPropagation();
        deleteMutation.mutate(tradingDocumentItemId);
      }}
      size="square-s"
      style={{ height: "17px", minHeight: "17px" }}
      title="Usuń pozycję"
    >
      <div className="btnBase btnBaseSmall">
        <img alt="Usuń" src={binIcon} style={{ height: "12px", width: "12px" }} />
      </div>
    </Button>
  );
};
