import openFolderIcon from "assets/images/folderOpen.svg";
import sendIcon from "assets/images/send.svg";
import { Button } from "components/common";
import cx from "classnames";
import styles from "../NotificationSection.module.css";
import { UseMutationResult } from "react-query";
import { QueryFetchError } from "api/types";
import { SendNotificationToRecipient, TradingDocument } from "api/trading-documents/models";

interface Props {
  sendNotificationToRecipientMutation: UseMutationResult<
    void,
    QueryFetchError,
    SendNotificationToRecipient,
    unknown
  >;
  tradingDocument: TradingDocument;
  open: () => void;
}

export const EmptyNotificationSection = ({
  sendNotificationToRecipientMutation,
  tradingDocument,
  open,
}: Props) => {
  return (
    <div className="pb-3">
      <div className={styles.sectionEmpty}>
        <img alt="Pusty folder" className={styles.icon} src={openFolderIcon} />
        <h5 className={cx(styles.sectionEmptyTitle, "pt-2")}>Jeszcze nie wysłano żadnej faktury</h5>
        <div className="py-2 d-flex align-items-center gap-2">
          <Button
            disabled={sendNotificationToRecipientMutation.isLoading}
            kind="create"
            onClick={() =>
              sendNotificationToRecipientMutation.mutate({
                tradingDocumentId: tradingDocument.id,
              })
            }
          >
            <div className="btnBase btnBaseSmall">
              <img alt="Wyślij" src={sendIcon} style={{ height: "16px", width: "16px" }} />
              Wyślij teraz
            </div>
          </Button>

          <Button onClick={open} kind="create">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Wyślij z adresem"
                src={sendIcon}
                style={{ height: "16px", width: "16px" }}
              />
              Wskaż adres i wyślij
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
