import { CallCenterRouteListItem } from "api/call-center/routes/models";
import cx from "classnames";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import styles from "../../../shared/CallCenter.module.css";
import faceIcon from "assets/images/face.svg";
import React, { useState } from "react";
import { useSelector } from "hooks";
import { useRouteListItemPatchMutation } from "api/call-center/routes/hooks";

interface Props {
  route: CallCenterRouteListItem;
}

export const ResponsibleForRoute = ({ route }: Props) => {
  const [showHintbox, setShowHintbox] = useState(false);
  const assignResponsibleForRouteMutation = useRouteListItemPatchMutation();
  const me = useSelector(store => store.auth.user!);

  return (
    <div>
      {route.responsible ? (
        <div className="d-flex align-items-center gap-1">
          {route.responsible.avatar ? (
            <div className={cx(styles.listAvatar, styles.avatarSmall)}>
              <img alt="avatar" src={route.responsible.avatar} />
            </div>
          ) : (
            <InitialsAvatar
              className={cx(styles.listAvatar, styles.avatarMedium)}
              firstName={route.responsible.firstName}
              lastName={route.responsible.lastName}
              style={{ margin: 0, background: "#00cf91" }}
            />
          )}
          <div className={cx(styles.overflow, "fw-700")}>
            {route.responsible.firstName} {route.responsible.lastName}
          </div>
        </div>
      ) : (
        <div
          className="position-relative"
          onPointerOver={() => setShowHintbox(true)}
          onPointerLeave={() => setShowHintbox(false)}
        >
          <button
            className={cx(styles.avatar, styles.avatarMedium, styles.avatarPlaceholder)}
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              (e as React.MouseEvent).stopPropagation();
              assignResponsibleForRouteMutation.mutate({
                id: route.id,
                toUpdate: {
                  responsible: me,
                },
              });
            }}
          >
            <img alt="Przypisz" src={faceIcon} style={{ height: "18px", width: "18px" }} />
          </button>
          {showHintbox && <div className={styles.hintbox}>Przydziel do mnie</div>}
        </div>
      )}
    </div>
  );
};
