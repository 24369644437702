import closeIcon from "assets/images/x.svg";
import moreOptionsIcon from "assets/images/moreHorizLight.svg";
import styles from "./MultipleCheckedItemsToolbar.module.css";
import cx from "classnames";
import { Button } from "..";
import { useState } from "react";
import { useEffect } from "react";

interface Props {
  actionButtons?: React.ReactNode;
  className?: string;
  close: () => void;
  quantity: number;
  style?: React.CSSProperties;
}

export const MultipleCheckedItemsToolbar = ({
  actionButtons,
  className,
  close,
  quantity,
  style,
}: Props) => {
  const [wasMoreThanOneUnit, setWasMoreThanOneUnit] = useState(false);
  const [isSlidingOut, setIsSlidingOut] = useState(false);

  useEffect(() => {
    if (quantity > 1) {
      setWasMoreThanOneUnit(true);
    }
    return () => {};
  }, [quantity]);

  return (
    <div
      className={cx(styles.changeMany, className, {
        "d-none": wasMoreThanOneUnit !== true,
        [styles.slideInUp]: quantity > 1,
        [styles.slideOutUp]: quantity <= 1 || isSlidingOut,
      })}
      style={style}
    >
      <div className="body-14-600 text-color-white mr-2">Zaznaczono {quantity}</div>
      {actionButtons}
      <Button kind="transparent-white" size="square-s">
        <div className="btnBase btnBaseSmall">
          <img alt="Więcej opcji" src={moreOptionsIcon} style={{ height: "16px", width: "16px" }} />
        </div>
        <div className={styles.hintbox}>Więcej opcji</div>
      </Button>
      <Button
        onClick={() => {
          setIsSlidingOut(true);
          setTimeout(() => {
            close();
            setIsSlidingOut(false);
          }, 200);
        }}
        kind="transparent-white"
        size="square-s"
      >
        <div className="btnBase btnBaseSmall">
          <img alt="Zamknij" src={closeIcon} style={{ height: "16px", width: "16px" }} />
        </div>
        <div className={styles.hintbox}>Odznacz/Usuń zaznaczenie</div>
      </Button>
    </div>
  );
};
