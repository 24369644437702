import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { closestDayOfWeek, dateFormatter, dateTimeParser } from "utilities";
import { useRoutePatchMutation } from "api/call-center/routes/hooks";
import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { AvatarWithName } from "components/common/avatarWithName";
import { AssignedUser } from "components/common/assignUser/AssignUser";
import { StateLabel } from "components/common/stateLabel";
import { OrderedProducts } from "./orderedProducts/OrderedProducts";

interface Props {
  routeOrder: CallCenterRouteOrderListItem;
}

export const GeneralInfoSection = ({ routeOrder }: Props) => {
  const usePatchRouteOrderMutation = useRoutePatchMutation();

  const Address = () => {
    return (
      <div className="body-14-500">
        {routeOrder.delivery.fistName}&nbsp; {routeOrder.delivery.lastName}
        <br />
        {routeOrder.delivery.street}
        <br />
        {routeOrder.delivery.postCode}&nbsp;
        {routeOrder.delivery.city}
        <br />
        {routeOrder.delivery.phone}
      </div>
    );
  };

  return (
    <RightPanelSection>
      <div className="d-flex align-items-start justify-content-between">
        <div className="w-100">
          <div className="fs-24  gap-2 mb-2">{routeOrder.signature}</div>
          <InfoLabel title="przypisano do">
            <AssignedUser
              value={routeOrder.responsible}
              onChange={value =>
                usePatchRouteOrderMutation.mutate({
                  id: routeOrder.id,
                  toUpdate: { responsible: value },
                })
              }
            />
          </InfoLabel>
          <InfoLabel title="dropshipping">
            <div className="body-14-500">
              {routeOrder.customer ? (
                routeOrder.customer.companyName ? (
                  <StateLabel kind="deepPurple">{routeOrder.customer.companyName}</StateLabel>
                ) : routeOrder.customer.firstName ? (
                  <StateLabel kind="deepPurple">
                    {routeOrder.customer.firstName} {routeOrder.customer.lastName}
                  </StateLabel>
                ) : null
              ) : (
                "---"
              )}
            </div>
          </InfoLabel>

          <InfoLabel title="sugerowany termin odbioru">
            {routeOrder.collectionDate ? (
              <>
                <div className="fs-14 fw-800">{dateFormatter(routeOrder.collectionDate)[1]}</div>
                <div className="body-14-600">{closestDayOfWeek(routeOrder.collectionDate)}</div>
              </>
            ) : (
              <div className="body-14-500">---</div>
            )}
          </InfoLabel>

          <InfoLabel title="adres">{routeOrder.delivery ? <Address /> : "---"}</InfoLabel>
          <OrderedProducts routeOrder={routeOrder} />
          <InfoLabel title="powód niepotwierdzenia / przełożenia terminu">
            <div className="body-14-500">
              {routeOrder.deliveryDateRejectedByFirstName
                ? routeOrder.deliveryDateRejectedByFirstName
                : "---"}
            </div>
          </InfoLabel>

          <InfoLabel title="potwierdzone">
            <div className="body-14-500">
              {routeOrder.isDeliveryDateConfirm ? "potwierdzono" : "niepotwierdzone"}
            </div>
          </InfoLabel>

          <InfoLabel title="potwierdzone przez">
            {routeOrder.deliveryDateConfirmedBy ? (
              <AvatarWithName user={routeOrder.deliveryDateConfirmedBy} />
            ) : (
              <div className="body-14-500">---</div>
            )}
          </InfoLabel>

          <InfoLabel title="źródło potwierdzenia">
            <div className="body-14-500">
              {routeOrder.deliveryDateConfirmationSource
                ? routeOrder.deliveryDateConfirmationSource === "EXTERNAL"
                  ? "SMS"
                  : "wewnętrzne"
                : "---"}
            </div>
          </InfoLabel>
          <InfoLabel title="kiedy potwierdzono">
            <div className="body-14-500">
              {routeOrder.deliveryDateConfirmTimestamp
                ? dateTimeParser(routeOrder.deliveryDateConfirmTimestamp).join(",")
                : "---"}
            </div>
          </InfoLabel>

          <InfoLabel title="potwierdzenie">
            <div className="body-14-500">
              {routeOrder.isDeliveryDateConfirm ? (
                <span className="text-color-green">potwierdzono</span>
              ) : !routeOrder.isDeliveryDateConfirm && !routeOrder.isDeliveryDateRejected ? (
                <span className="text-orange-4">oczekuje</span>
              ) : (
                <span className="text-red-6">odrzucono</span>
              )}
            </div>
          </InfoLabel>

          <InfoLabel title="powód odrzucenia">
            <div className="body-14-500">
              {routeOrder.isDeliveryDateConfirm ? routeOrder.isDeliveryDateConfirm : "---"}
            </div>
          </InfoLabel>

          <InfoLabel title="kto odrzucił">
            {routeOrder.deliveryDateRejectedByFirstName ? (
              <AvatarWithName
                user={{
                  firstName: routeOrder.deliveryDateRejectedByFirstName,
                  lastName: routeOrder.deliveryDateRejectedByLastName,
                  avatar: routeOrder.deliveryDateRejectedByAvatar,
                }}
              />
            ) : (
              <div className="body-14-500">---</div>
            )}
          </InfoLabel>

          <InfoLabel title="kiedy odrzucono">
            <div className="body-14-500">
              {routeOrder.deliveryDateRejectedTimestamp
                ? dateTimeParser(routeOrder.deliveryDateRejectedTimestamp).join(",")
                : "---"}
            </div>
          </InfoLabel>
        </div>
      </div>
    </RightPanelSection>
  );
};
