import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/common";
import checkIcon from "assets/images/check_small.svg";
import whiteCheckIcon from "assets/images/checkSmallWhite.svg";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import {
  patchPurchaseInvoiceStatus,
  patchTradingDocumentStatus,
} from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { queryString } from "utilities";
import { Pagination } from "api/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useQuery } from "hooks";

interface Props {
  tradingDocument: TradingDocument;
}

export const ConfirmTradingDocument = ({ tradingDocument }: Props) => {
  const { query } = useQuery();
  const { panelId, ...filters } = query;

  const updateStatusOptions = useMutationOptions(
    () => {
      const data = { tradingDocument: tradingDocument.id };
      if (tradingDocument.invoiceType === "PURCHASE") return patchPurchaseInvoiceStatus(data);
      return patchTradingDocumentStatus(data);
    },
    ({ queryClient, toastr, queryUtils }) => ({
      onMutate: () => {
        const prevList = queryClient.getQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            queryString.stringify({
              ...filters,
              type: tradingDocument.type,
              invoiceType: tradingDocument.invoiceType,
            }),
          ),
        );
        const prevPanel = queryClient.getQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );

        queryClient.setQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            queryString.stringify({
              ...filters,
              type: tradingDocument.type,
              invoiceType: tradingDocument.invoiceType,
            }),
          ),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(result => {
                if (result.id === tradingDocument.id) {
                  return { ...result, status: "CONFIRMED" };
                }
                return result;
              }),
            };
          },
        );

        queryClient.setQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
          currentDocument => {
            assertIsDefined(currentDocument);
            return {
              ...currentDocument,
              status: "CONFIRMED",
            };
          },
        );

        return { prevList, prevPanel };
      },
      onSuccess: payload => {
        queryClient.setQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            queryString.stringify({
              ...filters,
              type: tradingDocument.type,
              invoiceType: tradingDocument.invoiceType,
            }),
          ),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(result => {
                if (result.id === tradingDocument.id) {
                  return { ...result, status: payload.status, signature: payload.signature };
                }
                return result;
              }),
            };
          },
        );

        queryClient.setQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
          currentDocument => {
            assertIsDefined(currentDocument);
            return {
              ...currentDocument,
              status: payload.status,
              signature: payload.signature,
            };
          },
        );

        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zatwierdzono dokument",
        });
      },
      onError: (error, _, context) => {
        const { prevList, prevPanel } = context as {
          prevList: Pagination<TradingDocument>;
          prevPanel: TradingDocument;
        };
        queryUtils.rollback(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
          prevPanel,
          error,
        );

        queryClient.setQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            queryString.stringify({
              ...filters,
              type: tradingDocument.type,
              invoiceType: tradingDocument.invoiceType,
            }),
          ),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: prevList.results,
            };
          },
        );
      },
    }),
  );

  const updateStatusMutation = useMutation(updateStatusOptions.mutationFn, updateStatusOptions);

  return (
    <div>
      <Button
        disabled={tradingDocument.status === "CONFIRMED"}
        kind="confirm"
        onClick={() => updateStatusMutation.mutate({})}
        size="small"
      >
        <div className="btnBase btnBaseSmall">
          {tradingDocument.status === "NEW" ? (
            <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />
          ) : (
            <img alt="Gotowe" src={whiteCheckIcon} style={{ height: "16px", width: "16px" }} />
          )}
          <div className="fs-10">
            {tradingDocument.status === "NEW" ? "Zatwierdź" : "Zatwierdzono"}
          </div>
        </div>
      </Button>
    </div>
  );
};
