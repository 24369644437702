import { Button } from "components/common";
import { Checkbox, CommonError, Modal, Pagination, Spinner } from "components/utils";
import localStyles from "./AddRoutesFiltersModal.module.css";
import cx from "classnames";
import whitePlusIcon from "assets/images/20w.svg";
import { useQuery } from "hooks";
import { useState } from "react";
import { pluralize, queryString } from "utilities";
import { useRoutes } from "api/routes/hooks";
import { RoutesList } from "./subcomponents/routesList/RoutesList";
import { FiltersBar } from "./subcomponents/filtersBar/FiltersBar";
import { ShelfFilters } from "api/new-production-plans/models";
import { useEffect } from "react";
import { CheckedElement } from "../leftToolbar/LeftToolbar";
import { AdditionalRoutesFilters } from "./subcomponents/AdditionalRoutesFilters";

interface Props {
  checkedRoutes: CheckedElement[];
  setCheckedRoutes: React.Dispatch<React.SetStateAction<CheckedElement[]>>;
  close: () => void;
  shelfFilters: ShelfFilters;
  setShelfFilters: React.Dispatch<React.SetStateAction<ShelfFilters>>;
}

export interface RoutesFilters {
  search: string;
}

export const AddRoutesFiltersModal = ({
  close,
  checkedRoutes,
  setCheckedRoutes,
  shelfFilters,
  setShelfFilters,
}: Props) => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const [routesFilters, setRoutesFilters] = useState<RoutesFilters>({ search: "" });
  const routesSearch = queryString.stringify({
    page: query.page,
    search: routesFilters.search,
    isSettled: false,
  });

  const { data: routes, error, isLoading, pagination } = useRoutes(routesSearch);

  const assignedRoutesIds = shelfFilters.routes.ids
    ? new Set(shelfFilters.routes.ids)
    : new Set([]);
  const assignedRoutes = routes.filter(route => assignedRoutesIds.has(route.id));
  const initialValues: Record<number, { id: number }> = {};
  assignedRoutes.forEach(item => {
    initialValues[item.id] = item;
  });
  const checkedRoutesLength = checkedRoutes.filter(checkedRoute => checkedRoute.checked).length;
  const [isNull, setIsNull] = useState(
    shelfFilters.routes.isNull ? shelfFilters.routes.isNull : false,
  );
  const [departureDate, setDepartureDate] = useState(
    shelfFilters.routes.departureDate ? shelfFilters.routes.departureDate : "",
  );

  useEffect(() => {
    setCheckedRoutes(prevCheckedRoutes => {
      const prevCheckedRoutesIds = prevCheckedRoutes.map(prevCheckedRoute => prevCheckedRoute.id);
      const addedRoutes = routes
        .filter(route => assignedRoutes.includes(route))
        .filter(route => !prevCheckedRoutesIds.includes(route.id));
      const newRoutes = routes
        .filter(route => !assignedRoutes.includes(route))
        .filter(route => !prevCheckedRoutesIds.includes(route.id));
      const addedCheckedRoutes = addedRoutes.map(addedRoute => {
        return { checked: true, id: addedRoute.id };
      });
      const newCheckedRoutes = newRoutes.map(newRoute => {
        return { checked: false, id: newRoute.id };
      });
      return [...prevCheckedRoutes, ...addedCheckedRoutes, ...newCheckedRoutes];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes, isLoading]);

  const areAllChecked = (): boolean => {
    const currentlyCheckedRoutes = checkedRoutes
      .filter(checkedRoute => checkedRoute.checked)
      .map(checkedRoutes => checkedRoutes.id);
    return routes.every(route => currentlyCheckedRoutes.includes(route.id));
  };

  const checkAll = (mode: "reset" | "add"): void => {
    setCheckedRoutes(prevCheckedRoutes => {
      const allRoutesChecked = prevCheckedRoutes.map(prevCheckedRoute => {
        return { ...prevCheckedRoute, checked: mode === "add" ? true : false };
      });
      return allRoutesChecked;
    });
  };

  const updateRoutesList = (): void => {
    setShelfFilters(prevShelfFilters => {
      const updatedShelfFilters = { ...prevShelfFilters };
      const routesIdsToUpdate = checkedRoutes
        .filter(checkedRoute => checkedRoute.checked)
        .map(checkedRoute => checkedRoute.id);
      updatedShelfFilters.routes = {
        ...prevShelfFilters.routes,
        ids: routesIdsToUpdate,
        isNull: isNull,
        departureDate: departureDate.length > 0 ? departureDate : undefined,
      };
      return updatedShelfFilters;
    });
  };

  const areAllRoutesChecked = areAllChecked();
  const pickAllRoutesCheckbox = (
    <Checkbox
      checked={areAllRoutesChecked}
      className="mr-2"
      label={areAllRoutesChecked ? "Odznacz wszystkie" : "Zaznacz wszystkie"}
      name=""
      onChange={() => {
        areAllRoutesChecked ? checkAll("reset") : checkAll("add");
      }}
      overwrites={{ wrapper: { style: { marginLeft: 8 } } }}
    />
  );

  if (error) {
    return (
      <Modal
        isOpen={true}
        close={close}
        overrides={{ container: { className: localStyles.modal } }}
        showCloseBtn={false}
      >
        <div className="d-flex align-items-center justify-content-center">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );
  }

  if (isLoading) {
    return (
      <Modal
        isOpen={true}
        close={close}
        overrides={{ container: { className: localStyles.modal } }}
        showCloseBtn={false}
      >
        <div className="d-flex align-items-center justify-content-center">
          <Spinner
            className="mt-5"
            color="blue"
            on={isLoading}
            size="big"
            text="trwa wczytywanie tras"
          />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      close={close}
      isOpen={true}
      overrides={{ container: { className: localStyles.modal } }}
      showCloseBtn={false}
    >
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <div className={cx(localStyles.title, "mt-4 mb-4 d-flex align-items-center")}>
            Dodaj produkty z tras do planu
          </div>
          <div className="d-flex align-items-center">
            <Button className="mr-2" kind="secondary-grey" onClick={close}>
              Anuluj
            </Button>
            <Button
              kind="primary"
              onClick={() => {
                updateRoutesList();
                close();
              }}
            >
              <div className="d-flex align-items-center">
                <img alt="" className="mr-1" src={whitePlusIcon} />
                <span>Dodaj produkty</span>
              </div>
            </Button>
          </div>
        </div>
        <FiltersBar filters={routesFilters} setFilters={setRoutesFilters} />
        <div className={localStyles.routes}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div
              className={cx(
                localStyles.checkedNumberLabel,
                "d-flex align-items-center justify-content-center",
              )}
            >
              {pickAllRoutesCheckbox}
              (zaznaczono {checkedRoutesLength}{" "}
              {pluralize.pl(checkedRoutesLength, {
                singular: "trasę",
                plural: "trasy",
                other: "tras",
              })}
              )
              <span className={localStyles.separator} />
            </div>
            <AdditionalRoutesFilters
              departureDate={departureDate}
              isNull={isNull}
              setDepartureDate={setDepartureDate}
              setIsNull={setIsNull}
            />
          </div>
          <div
            className={cx(
              localStyles.columnNames,
              "d-flex justify-content-between align-items-center",
            )}
          >
            <div style={{ width: "5%" }} />
            <div className="fs-12 text-color-grey" style={{ width: "55%" }}>
              sygnatura
            </div>
            <div
              className="d-flex justify-content-center fs-12 text-color-grey"
              style={{ width: "20%" }}
            >
              data utworzenia
            </div>
            <div
              className="d-flex justify-content-center fs-12 text-color-grey"
              style={{ width: "20%" }}
            >
              data wyjazdu
            </div>
          </div>
          <RoutesList
            checkedRoutes={checkedRoutes}
            setCheckedRoutes={setCheckedRoutes}
            routes={routes}
          />
          <Pagination pagination={pagination} sticky />
        </div>
      </div>
    </Modal>
  );
};
