import styles from "../RouteTrackingModal.module.css";
import cx from "classnames";
import {
  RouteTrackingDayPosition,
  TrackingStatus,
  trackingStatusDict,
} from "api/call-center/routes/models";
import { PingLabel } from "components/common/pingLabel";
import { dateFns } from "utilities";
import { convertSecondsToHoursMinutesAndSeconds } from "utilities/convertSecondsToHoursAndMinutes";
import { RealDeliveryDate } from "./RealDeliveryDate";
import { NewDeliveryTime } from "./NewDeliveryTime";
import { DelaySms } from "./DelaySms";
import { DayType, RouteTrackingFilters } from "../RouteTrackingModal";
import { highlightTextFragment } from "utilities/highlightTextFragment";
import { CalculatedDeliveryTimeRange } from "./CalculatedDeliveryTimeRange";

interface Props {
  dayType: DayType;
  filters: RouteTrackingFilters;
  index: number;
  position: RouteTrackingDayPosition;
  positionsLength: number;
  routeTrackingStatus: TrackingStatus;
}

export const RoutePosition = ({
  dayType,
  filters,
  index,
  position,
  positionsLength,
  routeTrackingStatus,
}: Props) => {
  const highlightedPhone = highlightTextFragment(filters.search, position.phone, position.id);
  const highlightedAddressCity = highlightTextFragment(
    filters.search,
    position.address.city,
    position.id,
  );
  const highlightedAddressPostCode = highlightTextFragment(
    filters.search,
    position.address.postCode,
    position.id,
  );
  const highlightedAddressStreet = highlightTextFragment(
    filters.search,
    position.address.street,
    position.id,
  );

  const highlightedPositionSignature = highlightTextFragment(
    filters.search,
    position.signature,
    position.id,
  );

  return (
    <div
      className={cx(styles.tableRow, styles.tableRowTracking, {
        [styles.rowVisited]: position.isDelivered,
        [styles.rowCurrent]: !position.isDelivered && position.isCurrent,
        "bg-red-1": dayType !== "FUTURE" && position.trackingStatus === "OFF_TRACK",
        "bg-yellow-1": dayType !== "FUTURE" && position.trackingStatus === "AT_RISK",
        [styles.tableRowDefaultBackground]: position.trackingStatus === "ON_TRACK",
      })}
    >
      <div>{index + 1}.</div>
      <div className={styles.overflow}>
        {highlightedAddressStreet}, {highlightedAddressPostCode} {highlightedAddressCity}
      </div>
      <div className="fw-700">{highlightedPositionSignature}</div>
      <div className={styles.overflow}>{position.phone ? highlightedPhone : "--"}</div>
      <div className="d-flex align-items-center gap-2">
        <div>
          {position.plannedDeliveryTime
            ? dateFns.format(new Date(position.plannedDeliveryTime), "HH:mm")
            : "--"}
        </div>
        {position.isCurrent && index + 1 !== positionsLength && !position.deliveredDate && (
          <PingLabel kind={routeTrackingStatus} label={trackingStatusDict[routeTrackingStatus]} />
        )}
      </div>
      <div>
        <CalculatedDeliveryTimeRange
          dayType={dayType}
          deliveredDate={position.deliveredDate}
          min={position.calculatedDeliveryTime.min}
          max={position.calculatedDeliveryTime.max}
        />
      </div>
      <div>
        {position.stopoverTime
          ? convertSecondsToHoursMinutesAndSeconds(position.stopoverTime, false)
          : "--"}
      </div>
      <RealDeliveryDate position={position} />
      {position.delay && position.delay.length > 0 && dayType !== "FUTURE" ? (
        <div>
          {position.delay === "0.0"
            ? "---"
            : convertSecondsToHoursMinutesAndSeconds(Math.floor(parseFloat(position.delay)), false)}
        </div>
      ) : (
        <div></div>
      )}
      <NewDeliveryTime dayType={dayType} position={position} />
      {position.delayOfDelay && position.delayOfDelay.length > 0 && dayType !== "FUTURE" ? (
        <div>
          {position.delayOfDelay === "0.0"
            ? "---"
            : convertSecondsToHoursMinutesAndSeconds(
                Math.floor(parseFloat(position.delayOfDelay)),
                false,
              )}
        </div>
      ) : (
        <div></div>
      )}
      <div>{(position.distance / 1000).toFixed(0)}km</div>
      <DelaySms dayType={dayType} position={position} />
      <div></div>
      {dayType !== "FUTURE" ? (
        <div
          className={cx(styles.deliveryStatusLabel, {
            "text-teal-6": position.isDelivered,
            "text-red-4": !position.isDelivered,
          })}
        >
          {position.isDelivered ? "doręczono" : "nie doręczono"}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
